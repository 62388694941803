import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { httpClient } from 'src/helpers';

import { Page, Spinner, Searcher, Sorter, FacilityFilter } from 'src/components';
import { ModalArchivedUser, ModalReactivateUser, ModalInvite, ModalResendUser } from '../../../../shared';
import { User, AsideUser, ModalNewUser, ModalEditUser } from './components';
import { isValidEmail } from './components/User';




const getSorts = (t) => [
  { label: t(['dashboard:users.roles.all']), func: () => true },
  { label: t(['dashboard:users.roles.manager']), func: (e) => e.roles.includes('MANAGER') },
  { label: t(['dashboard:users.roles.provider']), func: (e) => e.roles.includes('VIEWER') },
];

const Users = () => {
  const VERTICAL = process.env.GATSBY_VERTICAL;
  const user = useSelector((state) => state.user);
  const { t } = useTranslation([process.env.GATSBY_VERTICAL.toLowerCase(), 'dashboard']);

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(getSorts(t)[0]);
  const [showActive, setShowActive] = useState(true);
  const [showInvalidEmail, setShowInvalidEmail] = useState(false);

  const [userIdentifier, setUserIdentifier] = useState(null);
  const [modalNewUserOpen, setModalNewUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [archivedUser, setArchivedUser] = useState(null);
  const [reactivateUser, setReactivateUser] = useState(null);
  const [resendUser, setResendUser] = useState(null);

  const {
    data: sites,
    error: sitesError,
    mutate: sitesMutate,
  } = useSWR(user.clientId ? [`/facilities?clientId=${user.clientId}`] : null);

  const siteValues = [{ label: t('dashboard:users.teams_all'), func: () => true }];
  const [site, setSite] = useState(siteValues[0]);

  if (sites) {
    siteValues.push(...sites.facilities.map(s => ({ label: s.name, func: (e) => e.facilities.map(f => f.id).includes(s.id) })));
  }

  const usersUrl = `/users?full=true&clientId=${user.clientId}${search === '' ? '' : `&search=${encodeURIComponent(search)}`}`;
  const { data, error, isValidating, mutate } = useSWR(user.clientId ? [usersUrl] : null );

  const users = useCallback(
    () =>
      data
        ? data
            .filter((e) => showActive ? (e.status || '').toUpperCase() !== 'DISABLED' : true)
            .filter((e) => showInvalidEmail ? !isValidEmail(e.email, e.events) : true)
            .filter(sort.func)
            .filter(site.func)
            .sort((a, b) => a.email.localeCompare(b.email))
        : [],
    [data, showActive, showInvalidEmail, sort, site]
  );

  // invite management
  const [inviteDisabled, setInviteDisabled] = useState(false);
  const [reinviteDisabled, setReinviteDisabled] = useState(false);
  const [inviteModalOpened, setInviteModalOpened] = useState(false);
  const [inviteError, setInviteError] = useState('');

  const sendInvite = async (filter) => {
    const res = await httpClient.get(`/users/invite?filter=${JSON.stringify(filter)}`);
    setInviteModalOpened(true);
    setInviteError(res.status === 200 ? '' : res.data);
  };

  const invite = async () => {
    setInviteDisabled(true);
    await sendInvite({ status: "PENDING", clientId: user.clientId });
    setInviteDisabled(false);
  };

  const reinvite = async () => {
    setReinviteDisabled(true);
    await sendInvite({ status: "INVITED", clientId: user.clientId });
    setReinviteDisabled(false);
  };


  return (
    <>
      <Page title="Users">
        <Page.Main className="container py-12">
          <ModalInvite opened={inviteModalOpened} setOpened={setInviteModalOpened} error={inviteError} />

          <div className="flex flex-col pb-6 space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
            <div className="flex flex-col items-center w-full space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:w-auto">
              <button
                type="button"
                onClick={() => invite()}
                className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none 2xl:whitespace-nowrap bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 whitespace-nowrap"
                disabled={inviteDisabled}
              >
                {t(['users.invite_users', 'dashboard:users.invite_users'])}
              </button>
              <button
                type="button"
                onClick={() => reinvite()}
                className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none 2xl:whitespace-nowrap bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 whitespace-nowrap"
                disabled={reinviteDisabled}
              >
                {t(['users.reinvite_users', 'dashboard:users.reinvite_users'])}
              </button>
            </div>
          </div>

          <div className="flex flex-col md:items-center md:flex-row md:space-x-4">
            {VERTICAL === 'VACCINE' && user.role === 'ADMIN' && (
              <div>
                <button
                  type="button"
                  disabled={sitesError || !sites || sites.length <= 0}
                  onClick={() => setModalNewUserOpen(true)}
                  className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
                >
                  {t(['dashboard:users.add'])}
                </button>
              </div>
            )}
            <Sorter
              sorts={getSorts(t).filter(
                (s) => !s.admin || (s.admin && user.role === 'ADMIN') || user.role === 'SUPERADMIN'
              )}
              sort={sort}
              setSort={setSort}
              label={t(['users.roles_filter','dashboard:users.roles_filter'])}
            />
            <FacilityFilter
              facilities={siteValues}
              facility={site}
              setFacility={setSite}
              label={t(['users.teams_filter','dashboard:users.teams_filter'])}
            />
            <div className="flex items-center justify-between space-x-4">
              <Searcher search={search} setSearch={setSearch} placeholder={t(['dashboard:users.search'])} />
              {search !== '' && data && (
                <span className="text-sm text-secondary whitespace-nowrap">
                  {`${data.length > 1 ? `${data.length} ${t(['dashboard:users.results'])}` : `${data.length} ${t(['dashboard:users.result'])}`}`}
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col md:items-center md:flex-row md:space-x-4">

            <div className="pt-6">
              <label id="inactive-only" htmlFor="inactive" className="flex items-center">
                <input
                  type="checkbox"
                  aria-labelledby="inactive-only"
                  onChange={() => setShowActive(!showActive)}
                  value={showActive}
                  checked={showActive}
                  className="flex-shrink-0 w-6 h-6 border border-gray-300 rounded appearance-none form-tick checked:bg-primary-light checked:border-transparent focus:outline-none disabled:opacity-50 disabled:text-black disabled:text-opacity-50"
                  style={{
                    backgroundImage:
                      "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e\")",
                  }}
                />
                <span className="pl-3 text-sm text-secondary">{t(['dashboard:users.show_active'])}</span>
              </label>
            </div>
            <div className="pt-6">
              <label id="invalid-email-only" htmlFor="invalid-email" className="flex items-center">
                <input
                  type="checkbox"
                  aria-labelledby="invalid-email-only"
                  onChange={() => setShowInvalidEmail(!showInvalidEmail)}
                  value={showInvalidEmail}
                  checked={showInvalidEmail}
                  className="flex-shrink-0 w-6 h-6 border border-gray-300 rounded appearance-none form-tick checked:bg-primary-light checked:border-transparent focus:outline-none disabled:opacity-50 disabled:text-black disabled:text-opacity-50"
                  style={{
                    backgroundImage:
                      "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e\")",
                  }}
                />
                <span className="pl-3 text-sm text-secondary">{t(['dashboard:users.show_invalid_email'])}</span>
              </label>
            </div>
          </div>
          <div className="pt-12">
            {error && (
              <div>
                <span>An error has occurred.</span>
              </div>
            )}
            {!error && !data && (
              <div className="flex justify-center text-primary-light">
                <Spinner />
              </div>
            )}
            {!error && data && users().length > 0 ? (
              <table className="w-full">
                <thead className="hidden lg:table-header-group">
                  <tr className="text-sm text-left uppercase text-secondary">
                    <th className="pb-2">{t(['users.table.name','dashboard:users.table.name'])}</th>
                    <th className="pb-2">{t(['users.table.assignment','dashboard:users.table.assignment'])}</th>
                    <th className="pb-2">{t(['users.table.email','dashboard:users.table.email'])}</th>
                    <th className="pb-2 text-center">{t(['users.table.status','dashboard:users.table.status'])}</th>
                    <th className="pb-2 opacity-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users().map((u) => (
                    <User
                      key={u.id}
                      actions={user.role === 'ADMIN' || user.role === 'SUPERADMIN'}
                      firstName={u.firstName}
                      lastName={u.lastName}
                      me={user.email === u.email}
                      roles={u.roles}
                      disabled={u.status ? u.status.toUpperCase() === 'DISABLED' : false}
                      email={u.email}
                      phone={u.phone}
                      status={u.status || 'null'}
                      events={u.events}
                      assignments={u.assignments}
                      assignmentType={VERTICAL === 'VACCINE' ? 'SITE' : 'FACILITY'}
                      openAside={() => setUserIdentifier(u.id.toString())}
                      openEdit={() =>
                        setEditUser({
                          id: u.id.toString(),
                          firstName: u.firstName,
                          lastName: u.lastName,
                          email: u.email,
                          phone: u.phone,
                        })
                      }
                      openArchived={() =>
                        setArchivedUser({
                          id: u.id.toString(),
                          firstName: u.firstName,
                          lastName: u.lastName,
                          roles: u.roles,
                        })
                      }
                      openReactivate={() =>
                        setReactivateUser({
                          id: u.id.toString(),
                          firstName: u.firstName,
                          lastName: u.lastName,
                          roles: u.roles,
                        })
                      }
                      openResend={() => setResendUser({ ...u, id: u.id.toString() })}
                      canBeRemoved={u.canBeDeactivated}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              !error && !isValidating && <span>No user</span>
            )}
          </div>
        </Page.Main>
      </Page>
      {userIdentifier && (
        <AsideUser
          open={userIdentifier !== null}
          requestClose={() => setUserIdentifier(null)}
          labels={[t(['dashboard:users.facilities']), 'Role']}
          identifier={userIdentifier}
        />
      )}
      {archivedUser && (
        <ModalArchivedUser
          open={archivedUser !== null}
          requestClose={() => setArchivedUser(null)}
          user={archivedUser}
          mutate={mutate}
        />
      )}
      {reactivateUser && (
        <ModalReactivateUser
          open={reactivateUser !== null}
          requestClose={() => setReactivateUser(null)}
          user={reactivateUser}
          mutate={mutate}
        />
      )}
      {editUser && (
        <ModalEditUser
          open={editUser !== null}
          requestClose={() => setEditUser(false)}
          user={editUser}
          sites={sites}
          userMutate={mutate}
          sitesMutate={sitesMutate}
        />
      )}
      {VERTICAL === 'VACCINE' && modalNewUserOpen && sites && sites.length > 0 && (
        <ModalNewUser
          open={modalNewUserOpen}
          requestClose={() => setModalNewUserOpen(false)}
          mutate={mutate}
          sites={sites}
        />
      )}
      {resendUser && (
        <ModalResendUser
          open={resendUser !== null}
          requestClose={() => setResendUser(null)}
          user={resendUser}
          facilityId="1"
          mutate={mutate}
        />
      )}
    </>
  );
};

export default Users;

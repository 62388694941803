import React, { useState, useEffect } from 'react';
import Loadable from '@loadable/component';
import PropTypes from 'prop-types';

import { httpClient } from 'src/helpers';
import { Page } from 'src/components';
import CONFIG from 'src/config';
import VERTICALS from '../../../../../../constants/views';

const DynamicClientDashboard = Loadable(
  () => import('powerbi-client-react').then((mod) => mod.PowerBIEmbed),
  { ssr: false }
);

const Dashboard = ({ facility }) => {
  const [embedData, setEmbedData] = useState(null);

  const initializeBot = async () => {
    try {
      const { data } = await httpClient.get(`/facilities/${facility.id}/dashboard/token`);
      setEmbedData(data);
    } catch (err) {
      console.log('embedData fetching error');
    }
  };

  useEffect(() => {
    initializeBot();
  }, []);

  return (
    <Page title="Dashboard">
      <Page.Main className="h-screen pt-16">
        {embedData && (
          <DynamicClientDashboard
            embedConfig={{
              type: 'report',
              accessToken: embedData.accessToken,
              embedUrl: embedData.embedUrl[0].embedUrl,
              id: embedData.embedUrl[0].reportId,
              tokenType: 1,
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
              },
            }}
            cssClassName="powerbi-dashboard"
            eventHandlers={
              new Map([
                ['loaded', () => console.log('Report loaded')],
                ['rendered', () => console.log('Report rendered')],
                ['error', (event) => console.log(event.detail)],
              ])
            }
          />
        )}
      </Page.Main>
    </Page>
  );
};

Dashboard.propTypes = {
  facility: PropTypes.shape({
    name: PropTypes.string.isRequired,
    dashboardId: PropTypes.string,
    id: PropTypes.string,
  }),
};

Dashboard.defaultProps = {
  facility: {
    dashboardId: undefined,
    id: undefined,
  },
};

export default Dashboard;

import React from 'react';
import { Page } from 'src/components';
import Contracts from '../../../contracts';

const DashboardContracts = () => (
  <Page title="Dashboard">
    <Page.Main className="container w-screen pt-8 pb-12">
      <div>
        <Contracts />
      </div>
    </Page.Main>
  </Page>
);

export default DashboardContracts;

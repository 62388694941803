import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Page, Searcher, Sorter } from 'src/components';
import { Vehicule, ModalNewVehicule } from './components';

const data = [
  {
    name: 'Vehicle 1',
    internalId: 'Unique internal Id',
    plate: 'XXXX-XXX',
    status: 'VERIFIED',
    owner: 'Romain Tichadou',
    out: '06/14/21 7:35pm ET',
    qr: 'QRCODE',
  },
  {
    name: 'Vehicle 2',
    internalId: 'Unique internal Id',
    plate: 'XXXX-XXX',
    status: 'PENDING',
    owner: 'Romain Tichadou',
    out: '06/14/21 7:35pm ET',
    qr: 'QRCODE',
  },
  {
    name: 'Vehicle 3',
    internalId: 'Unique internal Id',
    plate: 'XXXX-XXX',
    status: 'REVOKED',
    owner: 'Romain Tichadou',
    out: '06/14/21 7:35pm ET',
    qr: 'QRCODE',
  },
];

const sorts = [
  { label: 'Creation date', func: (a, b) => b.createdAt.localeCompare(a.createdAt) },
  { label: 'Name', func: (a, b) => a.name.localeCompare(b.name) },
  { label: 'Providers', admin: true, func: (a, b) => a.viewerAmount - b.viewerAmount },
  { label: 'Managers', admin: true, func: (a, b) => a.managerAmount - b.managerAmount },
];

const filters = [
  { label: 'Verification date', func: (a, b) => b.createdAt.localeCompare(a.createdAt) },
  { label: 'Status', func: (a, b) => a.name.localeCompare(b.name) },
];

const QRCode = () => (
  <svg viewBox="0 0 15 15" className="w-4 h-4 text-black fill-current">
    <path d="M7.85678 0H9.28523V0.714228H8.57101V1.42846H7.14232V3.5716H5.71387V2.85714H6.42809V0.714228H7.85678V0Z" />
    <path d="M0 0V5.00005H5.00005V0H0ZM4.2856 4.2856H0.714227V0.714228H4.2856V4.2856Z" />
    <path d="M10 0V5.00005H14.9998V0H10ZM14.2854 4.2856H10.7142V0.714228H14.2856V4.2856H14.2854Z" />
    <path d="M3.57162 1.42847H1.42871V3.57138H3.57162V1.42847Z" />
    <path d="M13.5716 1.42847H11.4287V3.57138H13.5716V1.42847Z" />
    <path d="M8.57165 2.14282H7.85742V2.85705H8.57165V2.14282Z" />
    <path d="M8.57126 2.85718H9.28549V5.71432H8.57126V4.28563H7.85704V5.00009H7.14258V3.57164H8.57126V2.85718Z" />
    <path d="M5.71482 4.28564H6.42905V5.0001H7.14327V5.71433H6.42905V7.14279H7.14327V6.42856H7.85773V5.71433H8.57196V7.85724H7.14327V8.57147H6.42905V7.85724H5.71482V6.42856H4.28613V5.71433H5.71482V4.28564Z" />
    <path d="M2.85717 5.71436H1.42871V6.42881H2.85717V5.71436Z" />
    <path d="M9.28613 5.71436H10.7148V6.42858H10.0006V7.14281H9.28613V5.71436Z" />
    <path d="M12.1432 5.71436H11.4287V6.42881H12.1432V5.71436Z" />
    <path d="M0 6.42847H1.42845V7.14269H2.14291V7.85715H1.42845V8.57138H0.714227V7.14269H0V6.42847Z" />
    <path d="M2.85742 6.42847H4.28588V7.14269H3.57165V7.85715H4.28588V8.57138H2.85742V6.42847Z" />
    <path d="M12.1423 6.42847H12.8568V7.14269H13.571V9.28561H12.8568V8.57138H11.4279V9.28561H10.7139V7.85715H12.1423V6.42847Z" />
    <path d="M5.00036 7.14282H4.28613V7.85728H5.00036V7.14282Z" />
    <path d="M14.9996 7.14282H14.2852V7.85728H14.9996V7.14282Z" />
    <path d="M5.00059 7.85718H5.71482V9.28563H4.28613V8.57141H5.00059V7.85718Z" />
    <path d="M9.28574 7.85718H8.57129V8.57141H9.28574V7.85718Z" />
    <path d="M0.714227 8.57153H0V9.28599H0.714227V8.57153Z" />
    <path d="M2.14294 8.57153H1.42871V9.28599H2.14294V8.57153Z" />
    <path d="M7.14232 8.57153H8.57101V9.28576H7.85678V10.7144H7.14232V12.1429H6.42809V10.7144H5.71387V9.28576H6.42809V9.99999H7.14232V8.57153Z" />
    <path d="M10.0006 8.57153H9.28613V9.28599H10.0006V8.57153Z" />
    <path d="M14.9996 8.57153H14.2852V9.28599H14.9996V8.57153Z" />
    <path d="M9.28574 9.28564H8.57129V10.7141H9.28574V9.28564Z" />
    <path d="M12.857 9.28564H12.1426V10.7141H12.857V9.28564Z" />
    <path d="M14.2855 9.28564H13.5713V9.99987H14.2855V9.28564Z" />
    <path d="M10.7145 9.99976H11.4285V10.7142H12.143V12.1427H14.2857V12.8569H11.4285V14.2854H10.7145V12.8569H10.0003V13.5714H9.28585V14.2854H10.0003V14.9998H6.42871V14.2854H8.57162V12.8569H9.28585V10.7142H10.7145V9.99976Z" />
    <path d="M0 9.99976V14.9998H5.00005V9.99976H0ZM4.2856 14.2854H0.714227V10.7142H4.2856V14.2854Z" />
    <path d="M8.57165 10.7144H7.85742V11.4286H8.57165V10.7144Z" />
    <path d="M14.2856 10.7144H12.8574V11.4286H14.2856V10.7144Z" />
    <path d="M3.57162 11.4282H1.42871V13.5711H3.57162V11.4282Z" />
    <path d="M14.9996 11.4282H14.2852V12.1427H14.9996V11.4282Z" />
    <path d="M5.71387 12.1428H6.4281V12.8571H7.14232V13.5715H5.71387V12.1428Z" />
    <path d="M8.57103 12.1428H7.14258V12.857H8.57103V12.1428Z" />
    <path d="M13.5716 13.5715H12.8574V14.2858H13.5716V13.5715Z" />
    <path d="M14.9996 13.5715H14.2852V14.2858H14.9996V13.5715Z" />
    <path d="M12.1432 14.2854H11.4287V14.9999H12.1432V14.2854Z" />
  </svg>
);

const Vehicules = () => {
  const user = useSelector((state) => state.user);

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(sorts[0]);
  const [filter, setFilter] = useState(filters[0]);

  const [modalNewVehiculeOpen, setModalNewVehiculeOpen] = useState(false);

  return (
    <>
      <Page title="Vehicules">
        <Page.Main>
          <div className="lg:grid" style={{ gridTemplateColumns: '240px 1fr' }}>
            <div
              className="hidden bg-gray-100 border-r lg:block"
              style={{ minHeight: 'calc(100vh - 194px)' }}
            >
              <div className="sticky top-16">
                <ol className="flex flex-col px-6 pt-12 space-y-6">
                  <li className="px-4 py-2 text-lg font-bold bg-gray-200 rounded whitespace-nowrap">
                    Los Angeles
                  </li>
                  <li className="px-4 py-2 text-lg whitespace-nowrap">New York</li>
                </ol>
              </div>
            </div>
            <div className="w-full max-w-screen-sm px-8 py-8 mx-auto md:max-w-screen-md lg:pl-8 lg:pr-4 lg:max-w-screen-2xl">
              <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:items-center lg:space-x-4 xl:space-x-8">
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => setModalNewVehiculeOpen(true)}
                    className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
                  >
                    Add Vehicule
                  </button>
                </div>
                <div className="flex space-x-4">
                  <Sorter
                    sorts={sorts.filter(
                      (s) =>
                        !s.admin ||
                        (s.admin && (user.role === 'ADMIN' || user.role === 'SUPERADMIN'))
                    )}
                    sort={sort}
                    setSort={setSort}
                  />
                  <Sorter
                    sorts={filters.filter(
                      (s) =>
                        !s.admin ||
                        (s.admin && (user.role === 'ADMIN' || user.role === 'SUPERADMIN'))
                    )}
                    sort={filter}
                    setSort={setFilter}
                    label="Filter by"
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <Searcher
                    search={search}
                    setSearch={setSearch}
                    placeholder="Search a vehicule..."
                  />
                </div>
              </div>
              <div className="grid pt-8 lg:pt-12 gap-y-12">
                {data.map((e) => (
                  <Vehicule
                    key={e.name}
                    name={e.name}
                    internalId={e.internalId}
                    plate={e.plate}
                    status={e.status}
                    owner={e.owner}
                    out={e.out}
                    qr={<QRCode />}
                  />
                ))}
              </div>
            </div>
          </div>
        </Page.Main>
      </Page>
      <ModalNewVehicule
        open={modalNewVehiculeOpen}
        requestClose={() => setModalNewVehiculeOpen(false)}
        mutate={() => {}}
      />
    </>
  );
};

export default Vehicules;

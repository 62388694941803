import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CONFIG from 'src/config';
import { httpClient } from 'src/helpers';
import { Modal, Snackbar } from 'src/components';

const Modalconfirmdeletebu = ({
  open,
  requestClose,
  requestCloseBothModals,
  mutate,
  selectedSubclientId,
}) => {
  const { t } = useTranslation([CONFIG.VERTICAL.toLowerCase()]);
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const handleSubmit = async () => {
    setSnackbarState({ type: 'LOADING' });
    document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });
    try {
      if (window.gtag) window.gtag('event', 'Delete_bu');

      const deleteBUAPI = `/subclients/${selectedSubclientId}`;
      const { status } = await httpClient.delete(deleteBUAPI);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();

        setTimeout(() => {
          requestCloseBothModals();
        }, 1000);
      } else throw Error('Subclient delete failed.');
    } catch (err) {
      if (err.response.status === 409 && err.response.data && err.response.data.code)
        switch (err.response.data.code) {
          default:
            setSnackbarState({ type: 'ERROR' });
        }
      else setSnackbarState({ type: 'ERROR' });
    }
  };

  return (
    <div>
      <Modal open={open} requestClose={requestClose}>
        <Snackbar
          type={snackbarState.type}
          message={snackbarState.msg}
          requestClose={() => setSnackbarState({ type: 'HIDDEN' })}
        />
        <Modal.Body className="flex flex-col px-16 py-10">
          <div className="text-lg font-bold">
            {t(['forms.subclient.delete_header', 'dashboard:forms.subclient.delete_header'])}
          </div>
          <div className="mb-8 text-sm text-gray-500">
            {t(['forms.subclient.delete_tagline', 'dashboard:forms.subclient.delete_tagline'])}
          </div>
          <div className="flex flex-row justify-end space-x-4">
            <button
              type="button"
              onClick={requestClose}
              className="px-8 py-2 border-2 rounded-md hover:border-gray-500 hover:bg-gray-200"
            >
              {t(['forms.subclient.cancel', 'dashboard:forms.subclient.cancel'])}
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={!snackbarState.type === ('LOADING' || 'ERROR')}
              className="px-8 py-2 text-red-500 border-2 border-red-500 rounded-md hover:bg-red-100"
            >
              {t(['forms.subclient.delete', 'dashboard:forms.subclient.delete'])}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

Modalconfirmdeletebu.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  requestCloseBothModals: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  selectedSubclientId: PropTypes.number.isRequired,
};

export default Modalconfirmdeletebu;

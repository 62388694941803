/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';

const NewLogoForm = ({ handleDelete }) => {
  const { t } = useTranslation(process.env.GATSBY_VERTICAL.toLowerCase());
  const [{ value: logo }, , { setValue: setLogo }] = useField('logo');
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    maxSize: 5_242_880,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => ({ ...file, preview: URL.createObjectURL(file) })));
      setLogo(acceptedFiles[0]);
    },
  });

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className="py-4">
      {!files.length && !logo ? (
        <div {...getRootProps()}>
          <input {...getInputProps({ multiple: false })} />

          <div className="border border-dashed rounded-lg cursor-pointer h-96 focus:outline-none">
            <div
              className="flex flex-col items-center justify-center h-full"
              style={{ color: '#CCD6DD' }}
            >
              <svg viewBox="0 0 40 40" className="w-10 h-10 fill-current ">
                <path d="M30.9434 31.6988H24.1509V28.6799H30.9434C34.2725 28.6799 36.9811 25.9712 36.9811 22.6422C36.9811 19.5041 34.6264 16.9207 31.5042 16.6339L30.2558 16.5192L30.1404 15.2709C29.6498 10.0075 25.2913 6.0384 20 6.0384C14.7087 6.0384 10.3502 10.0075 9.85962 15.2701L9.74415 16.5184L8.49585 16.6331C5.37358 16.9207 3.01887 19.5041 3.01887 22.6422C3.01887 25.9712 5.72755 28.6799 9.0566 28.6799H15.0943V31.6988H9.0566C4.06264 31.6988 0 27.6361 0 22.6422C0 18.3471 2.94113 14.7441 7.01509 13.818C8.15019 7.58481 13.5389 3.01953 20 3.01953C26.4611 3.01953 31.8498 7.58481 32.9849 13.818C37.0589 14.7441 40 18.3471 40 22.6422C40 27.6361 35.9374 31.6988 30.9434 31.6988Z" />
                <path d="M21.1322 19.623H18.1133V36.9815H21.1322V19.623Z" />
                <path d="M23.8385 24.4638L19.6227 20.2487L15.4068 24.4638L13.2725 22.3295L19.6227 15.9785L25.9728 22.3295L23.8385 24.4638Z" />
              </svg>
              <span className="pt-2 font-bold uppercase">
                {t(['settings.logo.upload_logo', 'dashboard:settings.logo.upload_logo'])}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pt-4 space-y-1 text-sm text-secondary">
            <span>
              {t(['settings.logo.supported_formats', 'dashboard:settings.logo.supported_formats'])}
            </span>
            <span>{t(['settings.logo.layout', 'dashboard:settings.logo.layout'])}</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center space-y-4">
          <div
            className="flex flex-col items-center justify-center p-8 bg-white rounded-md"
            style={{ boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}
          >
            {logo && !files.length ? (
              <img src={logo} alt={logo} className="flex-shrink-0 object-contain w-48 h-48" />
            ) : (
              <img
                src={files[0].preview}
                alt={files[0].name}
                className="flex-shrink-0 object-contain w-48 h-48"
              />
            )}
          </div>

          <button
            type="button"
            onClick={() => {
              if (typeof logo === 'string') handleDelete(setLogo);
              else setLogo(null);
              setFiles([]);
            }}
            className="flex items-center justify-center"
          >
            <svg viewBox="0 0 20 20" className="w-5 h-5 fill-current text-danger-light">
              <path d="M16.7297 18.1L17.301 6.66667H17.3337C17.8641 6.66667 18.3728 6.45595 18.7479 6.08088C19.1229 5.70581 19.3337 5.1971 19.3337 4.66667C19.3337 4.13623 19.1229 3.62753 18.7479 3.25245C18.3728 2.87738 17.8641 2.66667 17.3337 2.66667H13.8537L13.5663 1.51467C13.4581 1.08194 13.2083 0.697817 12.8566 0.423369C12.505 0.148921 12.0717 -9.8965e-05 11.6257 7.72631e-07H8.40566C7.96527 -0.00038619 7.53707 0.144591 7.1875 0.412435C6.83793 0.680279 6.58653 1.05601 6.47232 1.48133L6.15566 2.66667H2.66699C2.13656 2.66667 1.62785 2.87738 1.25278 3.25245C0.877706 3.62753 0.666992 4.13623 0.666992 4.66667C0.666992 5.1971 0.877706 5.70581 1.25278 6.08088C1.62785 6.45595 2.13656 6.66667 2.66699 6.66667H2.69966L3.27099 18.0993C3.29647 18.6117 3.51783 19.0946 3.88932 19.4484C4.26081 19.8021 4.75402 19.9996 5.26699 20H14.7337C15.2465 19.9996 15.7396 19.8022 16.1111 19.4486C16.4826 19.095 16.704 18.6122 16.7297 18.1ZM7.76232 1.82667C7.80043 1.68516 7.88409 1.56015 8.00038 1.47097C8.11668 1.3818 8.25911 1.33342 8.40566 1.33333H11.6257C11.7745 1.33323 11.9191 1.38292 12.0364 1.4745C12.1537 1.56607 12.237 1.69427 12.273 1.83867L12.4803 2.66667H7.53366L7.76232 1.82667ZM2.00033 4.66667C2.00033 4.48986 2.07056 4.32029 2.19559 4.19526C2.32061 4.07024 2.49018 4 2.66699 4H17.3337C17.5105 4 17.68 4.07024 17.8051 4.19526C17.9301 4.32029 18.0003 4.48986 18.0003 4.66667C18.0003 4.84348 17.9301 5.01305 17.8051 5.13807C17.68 5.2631 17.5105 5.33333 17.3337 5.33333H2.66699C2.49018 5.33333 2.32061 5.2631 2.19559 5.13807C2.07056 5.01305 2.00033 4.84348 2.00033 4.66667ZM4.60033 18.0333L4.03433 6.66667H15.9677L15.4003 18.034C15.3916 18.2049 15.3175 18.3658 15.1934 18.4836C15.0693 18.6013 14.9047 18.6669 14.7337 18.6667H5.26699C5.09581 18.6669 4.9311 18.6012 4.80699 18.4833C4.68288 18.3654 4.60888 18.2043 4.60033 18.0333Z" />
              <path d="M13.3669 9.33291C13.2793 9.32842 13.1917 9.34125 13.1091 9.37067C13.0265 9.40008 12.9505 9.44551 12.8855 9.50434C12.8205 9.56317 12.7677 9.63425 12.7302 9.71351C12.6927 9.79277 12.6712 9.87865 12.6669 9.96624L12.4002 15.2996C12.3914 15.4764 12.4532 15.6495 12.5719 15.7807C12.6907 15.912 12.8568 15.9907 13.0336 15.9996C13.2104 16.0084 13.3835 15.9467 13.5147 15.8279C13.646 15.7091 13.7247 15.543 13.7336 15.3662L14.0002 10.0329C14.0047 9.94533 13.9919 9.85773 13.9625 9.77513C13.9331 9.69252 13.8876 9.61653 13.8288 9.55151C13.77 9.48648 13.6989 9.4337 13.6196 9.39619C13.5404 9.35868 13.4545 9.33717 13.3669 9.33291Z" />
              <path d="M6.63323 9.33272C6.54565 9.33699 6.45976 9.35849 6.38051 9.396C6.30125 9.43352 6.23016 9.48629 6.17133 9.55132C6.1125 9.61634 6.06708 9.69233 6.03766 9.77494C6.00824 9.85755 5.99541 9.94514 5.9999 10.0327L6.26656 15.3661C6.2779 15.5419 6.35736 15.7065 6.48806 15.8247C6.61876 15.943 6.79042 16.0056 6.96656 15.9994C7.05415 15.9951 7.14003 15.9736 7.21929 15.9361C7.29855 15.8986 7.36963 15.8458 7.42846 15.7808C7.48729 15.7158 7.53272 15.6398 7.56214 15.5572C7.59155 15.4746 7.60439 15.387 7.5999 15.2994L7.33323 9.96605C7.32357 9.78954 7.24461 9.62398 7.11353 9.50538C6.98244 9.38677 6.80983 9.32472 6.63323 9.33272Z" />
              <path d="M10.0007 9.33398C9.82384 9.33398 9.65427 9.40422 9.52925 9.52925C9.40422 9.65427 9.33398 9.82384 9.33398 10.0007V15.334C9.33398 15.5108 9.40422 15.6804 9.52925 15.8054C9.65427 15.9304 9.82384 16.0006 10.0007 16.0006C10.1775 16.0006 10.347 15.9304 10.4721 15.8054C10.5971 15.6804 10.6673 15.5108 10.6673 15.334V10.0007C10.6673 9.82384 10.5971 9.65427 10.4721 9.52925C10.347 9.40422 10.1775 9.33398 10.0007 9.33398Z" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

NewLogoForm.propTypes = {
  handleDelete: PropTypes.func.isRequired,
};

export default NewLogoForm;

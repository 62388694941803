import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { httpClient, mapRoles } from 'src/helpers';

import { Modal, Spinner, Snackbar, Avatar } from 'src/components';

const ModalResend = ({ open, requestClose, facilityId, user, mutate }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });
  const [isResending, setIsResending] = useState(false);
  const { t } = useTranslation('users');

  const handleConfirm = async () => {
    if (window.gtag) window.gtag('event', 'Resend_email_user');
    setIsResending(true);

    try {
      const { status } = await httpClient.get(
        `/facilities/${facilityId}/users/${user.id}/emails/resend`
      );

      if (status === 200) {
        setTimeout(() => {
          setSnackbarState({ type: 'SUCCESS' });
        }, 1000);

        setTimeout(() => {
          mutate();
          requestClose();
        }, 2000);
      } else throw Error('Resend email to user failed.');
    } catch (error) {
      setSnackbarState({ type: 'ERROR' });
      setIsResending(false);
    }
  };

  const handleCancel = () => {
    setIsResending(false);
    requestClose();
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      {!isResending ? (
        <Modal.Body className="px-6 pt-6">
          <Snackbar
            type={snackbarState.type}
            message={snackbarState.msg}
            requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
          />
          <div className="max-w-md mx-auto">
            <div className="pt-6 pb-12">
              <h5 className="font-bold">{t('dashboard:users.resend_email.message')}</h5>
              <div className="flex items-center pt-12 space-x-4">
                <Avatar
                  initials={`${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`}
                  role={user.roles[0]}
                />
                <div className="flex flex-col">
                  <span className="font-semibold capitalize">
                    {`${user.firstName} ${user.lastName}`}
                  </span>
                  <span className="text-xs capitalize lg:text-sm text-secondary">
                    {user.roles
                      .filter((r) => r !== 'USER')
                      .map(mapRoles)
                      .join(', ')}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center pb-6">
              <div className="flex items-center w-full space-x-4">
                <button
                  type="button"
                  onClick={() => handleCancel()}
                  className="w-1/2 py-4 transition border-2 rounded-lg select-none focus:outline-none hover:bg-gray-200 focus:bg-gray-200"
                >
                  {t('dashboard:users.resend_email.cancel')}
                </button>
                <button
                  type="button"
                  onClick={() => handleConfirm()}
                  className="w-full py-4 text-white transition border-2 rounded-lg select-none border-primary-light bg-primary-light hover:bg-primary-light-hover hover:border-primary-light-hover focus:bg-primary-light-hover focus:border-primary-light-hover focus:outline-none"
                >
                  {t('dashboard:users.resend_email.ok')}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <div className="flex justify-center p-6 text-primary-light">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

ModalResend.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  facilityId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default ModalResend;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash.debounce';

import { httpClient } from 'src/helpers';
import { Modal, InputField, Snackbar, Button } from 'src/components';

import countries from 'src/constants/countries.json';
import states from 'src/constants/states.json';
import ct from 'city-timezones';
import CONFIG from 'src/config';

const vertical = CONFIG.VERTICAL.toLowerCase();

const editSchema = Yup.object().shape({
  facilityName: Yup.string()
    .max(250, 'forms.errors.facility.name')
    .required('forms.required')
    .test(
      'facility-name-backend-validation',
      'forms.errors.facility.taken',
      debounce(async (name) => {
        try {
          if (!name || name === '' || name === localStorage.getItem('facilityName')) return true;
          const { data } = await httpClient.get(`/facilities/${encodeURIComponent(name)}/exists`);
          return !data.exists;
        } catch (error) {
          return false;
        }
      }, 500)
    ),
  facilityRooms: Yup.number().min(1, 'forms.min.rooms').required('forms.required'),
  facilityEmployees: Yup.number().min(1, 'forms.min.employees').required('forms.required'),
  facilityAddress: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  facilityAdditionalAddress: Yup.string().max(250, 'forms.too_long'),
  facilityCity: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  facilityPostalCode: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  facilityCountry: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  facilityState: Yup.string().when('facilityCountry', {
    is: (val) => val === 'US' || val === 'CA',
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  facilityTimezone: Yup.string().max(250, 'forms.too_long').required('forms.required'),
});

const editSchemaVulcain = Yup.object().shape({
  facilityName: Yup.string()
    .max(250, 'forms.errors.facility.name')
    .required('forms.required')
    .test(
      'facility-name-backend-validation',
      'forms.errors.facility.taken',
      debounce(async (name) => {
        try {
          if (!name || name === '' || name === localStorage.getItem('facilityName')) return true;
          const { data } = await httpClient.get(`/facilities/${encodeURIComponent(name)}/exists`);
          return !data.exists;
        } catch (error) {
          return false;
        }
      }, 500)
    ),
  facilityRooms: Yup.number().min(1, 'forms.min.rooms'),
  facilityEmployees: Yup.number().min(1, 'forms.min.employees'),
  facilityAddress: Yup.string().max(250, 'forms.too_long'),
  facilityAdditionalAddress: Yup.string().max(250, 'forms.too_long'),
  facilityCity: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  facilityPostalCode: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  facilityCountry: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  facilityState: Yup.string().when('facilityCountry', {
    is: (val) => val === 'US' || val === 'CA',
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  facilityTimezone: Yup.string().max(250, 'forms.too_long').required('forms.required'),
});

const formatValues = (values) => ({
  name: values.facilityName,
  protocolUrl: null,
  capacityEmployees: values.facilityEmployees,
  capacityRooms: values.facilityRooms,
  timezone: values.facilityTimezone,
  address: {
    line1: values.facilityAddress,
    line2: values.facilityAdditionalAddress,
    city: values.facilityCity,
    zipCode: values.facilityPostalCode,
    country: values.facilityCountry,
    state: values.facilityState,
  },
});

const TimezoneField = ({ values, setFieldValue }) => {
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    let tzs = ct.cityMapping.filter(
      (e) => (e.iso2 === values.facilityCountry || e.iso3 === values.facilityCountry) && e.timezone
    );

    // TODO refactor
    // handle countries not supported by city-timezones (ie: Anguilla)
    if (values.facilityCountry === 'AI') {
      tzs = [{ timezone: 'America/Anguilla' }];
    }

    const uniqueTzs = [...new Set(tzs.map((i) => i.timezone))];

    setTimezones(uniqueTzs);
    setFieldValue('facilityTimezone', uniqueTzs[0], true);
  }, [values.facilityCountry]);

  return (
    values.facilityCountry &&
    timezones.length > 1 && (
      <InputField
        type="select"
        name="facilityTimezone"
        label="forms.address.timezone"
        namespaces={['dashboard']}
        options={timezones.map((tz) => ({ label: tz, value: tz }))}
      />
    )
  );
};

const ModalInformations = ({ open, requestClose, facilityId, unit, initialValues, mutate }) => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation(['dashboard', 'facility']);
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const handleSubmit = async (values, actions) => {
    if (window.gtag) window.gtag('event', 'Edit_property_information');
    setSnackbarState({ type: 'LOADING' });
    actions.setSubmitting(true);

    const formatedValues = formatValues(values);
    formatedValues.clientId = user.clientId;

    try {
      document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });
      const { status } = await httpClient.put(`/facilities/${facilityId}`, formatedValues);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();
      } else {
        throw Error('Facility creation failed.');
      }
    } catch (error) {
      console.error(error);
      setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    localStorage.setItem('facilityName', initialValues.facilityName);
    return () => {
      localStorage.removeItem('facilityName');
    };
  }, [initialValues.facilityName]);

  useEffect(
    () => () => {
      setSnackbarState({ type: 'HIDDEN', msg: '' });
    },
    []
  );

  const vulcainFormBody = (values, setFieldValue) => (
    <div className="max-w-md pt-6 pb-12 space-y-6">
      <InputField
        name="facilityName"
        label={[`${vertical}:forms.team.name`,'forms.team.name']}
        namespaces={['dashboard']}
        autoComplete="ada"
      />
      <InputField
        name="facilityCity"
        label="forms.address.city"
        autoComplete="city"
        namespaces={['dashboard']}
      />
      <div className="flex w-1/2">
        <InputField
          id="label-team-postal"
          name="facilityPostalCode"
          label="forms.address.postal"
          autoComplete="zip"
          namespaces={['dashboard']}
        />
      </div>

      <InputField
        type="select"
        name="facilityCountry"
        label="forms.address.country"
        namespaces={['dashboard']}
        options={countries
          .map((country) => ({
            ...country,
            label: t(`countries:${country.label}`),
          }))
          .sort((a, b) => t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`)))}
      />
      {(values.facilityCountry === 'CA' || values.facilityCountry === 'US') &&
        states[values.facilityCountry] && (
          <InputField
            type="select"
            name="facilityState"
            label="forms.address.state"
            namespaces={['dashboard']}
            options={states[values.facilityCountry].map((state) => ({
              ...state,
              label: t(`states:${values.facilityCountry}.${state.label}`),
            }))}
          />
        )}
      <TimezoneField values={values} setFieldValue={setFieldValue} />
    </div>
  );

  const formBody = (values, setFieldValue) => (
    <div>
      <div className="pt-6 space-y-6">
        <span className="text-2xl font-bold">
          {t([`${vertical}:forms.address.titles.information`, 'forms.address.titles.information'])}
        </span>
        <InputField
          name="facilityName"
          label={[`${vertical}:forms.address.name`,'forms.address.name']}
          namespaces={CONFIG.VERTICAL === 'DEKRA' ? 'dekra' : ['dashboard']}
          autoComplete="ada"
        />
        <div className="flex w-1/2">
          <InputField
            type="number"
            name={unit === 'employee' ? 'facilityEmployees' : 'facilityRooms'}
            label={`forms.units.${unit}.quantity`}
            namespaces={['dashboard']}
          />
        </div>
      </div>
      <div className="pt-12 space-y-6">
        <span className="text-2xl font-bold">
          {t([`${vertical}:forms.address.titles.address`, 'forms.address.titles.address'])}
        </span>
        <InputField
          name="facilityAddress"
          label="forms.address.address"
          namespaces={['dashboard']}
        />
        <InputField
          name="facilityAdditionalAddress"
          label="forms.address.additionalAddress"
          namespaces={['dashboard']}
        />
        <InputField
          name="facilityCity"
          label="forms.address.city"
          autoComplete="city"
          namespaces={['dashboard']}
        />
        <div className="flex w-1/2">
          <InputField
            name="facilityPostalCode"
            label="forms.address.postal"
            autoComplete="zip"
            namespaces={['dashboard']}
          />
        </div>
        <InputField
          type="select"
          name="facilityCountry"
          label="forms.address.country"
          namespaces={['dashboard']}
          options={countries
            .map((country) => ({
              ...country,
              label: t(`countries:${country.label}`),
            }))
            .sort((a, b) => t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`)))}
        />
        {(values.facilityCountry === 'CA' || values.facilityCountry === 'US') &&
          states[values.facilityCountry] && (
            <InputField
              type="select"
              name="facilityState"
              label="forms.address.state"
              namespaces={['dashboard']}
              options={states[values.facilityCountry].map((state) => ({
                ...state,
                label: t(`states:${values.facilityCountry}.${state.label}`),
              }))}
            />
          )}
        <TimezoneField values={values} setFieldValue={setFieldValue} />
      </div>
    </div>
  );

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <h4 className="text-lg font-bold">
          {t([
            `${vertical}:forms.modal.edit_facility.title`,
            'dashboard:forms.modal.edit_facility.title',
          ])}
        </h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        <Formik
          initialValues={{
            ...initialValues,
            facilityCountry:
              initialValues.facilityCountry === 'USA' ? 'US' : initialValues.facilityCountry,
          }}
          validationSchema={['VULCAIN','GIFEN'].includes(CONFIG.VERTICAL) ? editSchemaVulcain : editSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, dirty, values, setFieldValue }) => (
            <Form className="max-w-md mx-auto">
              <Snackbar
                type={snackbarState.type}
                message={snackbarState.msg}
                requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
              />
              {['VULCAIN', 'GIFEN'].includes(CONFIG.VERTICAL)
                ? vulcainFormBody(values, setFieldValue)
                : formBody(values, setFieldValue)}
              <div className="flex items-center justify-center pt-12 pb-6">
                <Button type="submit" size="md" disabled={!(isValid && dirty) || isSubmitting}>
                  {t('dashboard:forms.buttons.confirm')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

TimezoneField.propTypes = {
  values: PropTypes.shape({
    facilityCountry: PropTypes.string.isRequired,
    facilityTimezone: PropTypes.string.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

ModalInformations.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  facilityId: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    facilityName: PropTypes.string.isRequired,
    facilityRooms: PropTypes.string.isRequired,
    facilityEmployees: PropTypes.string.isRequired,
    facilityAddress: PropTypes.string.isRequired,
    facilityAdditionalAddress: PropTypes.string.isRequired,
    facilityCity: PropTypes.string.isRequired,
    facilityPostalCode: PropTypes.string.isRequired,
    facilityCountry: PropTypes.string.isRequired,
    facilityState: PropTypes.string.isRequired,
    facilityTimezone: PropTypes.string.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default ModalInformations;

const formatValues = (values) => {
  const data = {
    drmUser: {
      firstName: values.manager.firstName,
      lastName: values.manager.lastName,
      email: values.manager.email,
      phone: { number: values.manager.phone },
      externalChatbotManagerId: values.manager.externalChatbotManagerId,
      title: values.manager.title,
    },
  };

  return data;
};

export default formatValues;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import PropTypes from 'prop-types';

import { Spinner } from 'src/components';
import { fetchOnce } from 'src/helpers';
import Address from './Address';
import ModalAddress from './ModalAddress';

const GetAddress = (addressData) => ({
  addressee: addressData.addressee,
  attention: addressData.attention,
  phone: addressData.phone,
  address: {
    line1: addressData.line1,
    line2: addressData.line2,
    city: addressData.city,
    zipCode: addressData.zipCode,
    country: addressData.country,
  },
});

const GetAddressValues = (data) => ({
  facilityName: data.contact.companyName,
  contactEmail: data.contact.email,
  contactPhone: data.contact.phone,
  billingAddressee: data.billing.addressee,
  billingAttention: data.billing.attention,
  billingAddresseePhone: data.billing.phone,
  billingUseFacilityAddress:
    process.env.GATSBY_VERTICAL === 'GREENSHIELD' ? false : data.billing.billingUseFacilityAddress,
  billingAddress: data.billing.line1,
  billingAdditionalAddress: data.billing.line2,
  billingCity: data.billing.city,
  billingPostalCode: data.billing.zipCode,
  billingCountry: data.billing.country,
  billingState: data.billing.state,
  shippingUseBillingAddress: data.shipping.shippingUseBillingInformation,
  shippingAddressee: data.shipping.addressee,
  shippingAttention: data.shipping.attention,
  shippingAddresseePhone: data.shipping.phone,
  shippingAddress: data.shipping.line1,
  shippingAdditionalAddress: data.shipping.line2,
  shippingCity: data.shipping.city,
  shippingPostalCode: data.shipping.zipCode,
  shippingCountry: data.shipping.country,
  shippingState: data.shipping.state,
});

const Information = ({ facility }) => {
  const user = useSelector((state) => state.user);

  let infoUrl = `/facilities/${facility?.id}/billing`;
  if (process.env.GATSBY_VERTICAL === 'GREENSHIELD') {
    infoUrl = `/clients/${user.clientId}/billing`;
  }

  const { data, error, mutate, isValidating } = useSWR(infoUrl, fetchOnce);
  const { t } = useTranslation('facility', 'dashboard', 'common');
  const [modalOpen, setModalOpen] = useState(false);

  const editButtonDisabled = () => {
    switch (process.env.GATSBY_VERTICAL) {
      case 'FTG':
        return !['admin', 'manager'].includes(facility?.userRole);
      case 'GREENSHIELD':
        return !['ADMIN', 'MANAGER'].includes(user.role);
      default:
        return true;
    }
  };

  return (
    <>
      <div className="flex flex-col mt-8 lg:w-1/3 lg:mt-0">
        <div className="flex items-center justify-between h-20">
          <div className="text-xl font-bold">{t('dashboard:contracts.information.header')}</div>
          <div>
            {data && !error && (
              <button
                type="button"
                disabled={editButtonDisabled()}
                onClick={() => setModalOpen(true)}
                className="px-6 py-2 font-bold text-blue-500 transition rounded-md w-min bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
              >
                {t('informations.buttons.edit')}
              </button>
            )}
          </div>
        </div>
        <div className="px-8 py-6 text-xs border-2 rounded-lg md:px-6 md:py-12 lg:px-12 xl:px-24">
          {isValidating ? (
            <div className="flex justify-center text-primary-light">
              <Spinner />
            </div>
          ) : (
            error && <div>{t('common:msgs.error')}</div>
          )}
          {data && !isValidating && (
            <div className="flex flex-col">
              <div className="space-y-12">
                <Address
                  title="billing"
                  data={{ ...GetAddress(data.billing), email: data.contact.email }}
                />
                <Address title="shipping" data={GetAddress(data.shipping)} />
              </div>
            </div>
          )}
        </div>
      </div>
      {modalOpen && (
        <ModalAddress
          open={modalOpen}
          requestClose={() => setModalOpen(false)}
          mutate={mutate}
          facilityId={facility?.id}
          clientId={user.clientId}
          initialValues={GetAddressValues(data)}
        />
      )}
    </>
  );
};

Information.defaultProps = {
  facility: {
    id: '',
    userRole: '',
  },
};

Information.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string,
    userRole: PropTypes.string,
  }),
};

export default Information;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Router as ReachRouter } from '@reach/router';
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';

import views from 'src/constants/views';
import { Page, NotFound, Spinner } from 'src/components';
import { signOut, updateConfigLink } from 'src/store';
import { fetchOnce } from 'src/helpers';
import Layout from './layout';

const Router = ({ facilityId }) => {
  const dispatch = useDispatch();
  const { data: facility, error, mutate } = useSWR(
    facilityId ? [`/facilities/${facilityId}`] : null
  );
  const { data: config, error: errorConfig } = useSWR('/config/links', fetchOnce);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (config && config?.contexts) {
      let currentContext = null;
      if (facility?.context) {
        currentContext = config.contexts.find((context) => context.name === facility.context);
      } else {
        currentContext = Array.isArray(config.contexts) ? config.contexts.pop() : null;
      }
      if (currentContext) dispatch(updateConfigLink(currentContext));
    }
  }, [config, facility?.context]);

  if (error || errorConfig) {
    const { sessionLost } = error;

    if (sessionLost) {
      const { instance } = useMsal();

      // trigger sign out if session lost
      dispatch(signOut())
        .then(async () => instance.logout())
        .catch(async () => instance.logout());
    } else {
      return <NotFound />;
    }
  }

  if (!facility) {
    return (
      <Page title="Facility">
        <Page.Main className="container pt-24">
          <div className="flex justify-center text-primary-light">
            <Spinner />
          </div>
        </Page.Main>
      </Page>
    );
  }

  if (facility.statusChatbot === 'PENDING' || facility.statusChatbot === 'FAILED') {
    navigate('/dashboard/', { replace: true });
    return null;
  }

  return (
    <Layout facility={{ ...facility, id: facilityId }}>
      <ReachRouter>
        {views.facility
          .map(
            (v) => {
              // hide dashboard and users for viewer in vulcain
              const viewerInVulcain = ['dashboard', 'users', 'report', '/'].includes(v.path) && 
                user.role === 'VIEWER' &&
                ['VULCAIN', 'GIFEN'].includes(process.env.GATSBY_VERTICAL);

              if (viewerInVulcain) {
                /* eslint-disable no-param-reassign */
                v.enable = false;
              }

              return  v.enable && (
                <v.component
                  key={v.path}
                  path={v.path}
                  facility={{ ...facility, id: facilityId }}
                  mutate={mutate}
                />
              )
            }
          )
        }
        <NotFound default />
      </ReachRouter>
    </Layout>
  );
};

Router.propTypes = {
  facilityId: PropTypes.string,
};

Router.defaultProps = {
  facilityId: null,
};

export default Router;

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InputField } from 'src/components';
import CONFIG from 'src/config';

const EditUserForm = ({ context }) => {
  const { t } = useTranslation(CONFIG.VERTICAL.toLowerCase());

  const titles = t(['forms.manager.user_titles', 'dashboard:forms.manager.user_titles'], {
    returnObjects: true,
  }).filter((ttl) => ttl.valid.includes('user'));

  const options = titles
    .filter((title) => title.context === context)
    .map((title) => {
      /* eslint-disable no-param-reassign */
      delete title.context;

      return {
        ...title,
        label: title.label,
        key: title.value
      };
    });

  return (
    <div className="pb-12 space-y-12">
      <div className="space-y-6">
        <span className="text-2xl font-bold">
          {t(['users.update_user', 'dashboard:users.update_user'])}
        </span>
        <div className="space-y-6 md:flex md:space-x-6 md:space-y-0">
          <InputField name="firstName" label="forms.manager.firstName" namespaces={['dashboard']} />
          <InputField name="lastName" label="forms.manager.lastName" namespaces={['dashboard']} />
        </div>
        <InputField name="email" label="forms.manager.email" namespaces={['dashboard']} disabled />
        <InputField
          type="select"
          name="title"
          label="forms.manager.role"
          namespaces={['dashboard']}
          options={options}
        />
        <div className="flex w-1/2">
          <InputField
            name="phone"
            label="forms.manager.phone_optional"
            namespaces={['dashboard']}
          />
        </div>
      </div>
    </div>
  );
};

EditUserForm.propTypes = {
  context: PropTypes.string.isRequired
}

export default EditUserForm;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ct from 'city-timezones';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import countries from 'src/constants/countries.json';
import { InputField } from 'src/components';
import AssignmentForm from '../AssignmentForm';

const UpdateJourneyForm = () => {
  const currentDate = new Date();

  const { t } = useTranslation(['countries']);

  const [minDate] = useState(currentDate.setDate(currentDate.getDate() + 1));
  const { values, setFieldValue } = useFormikContext();
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    let tzs = ct.cityMapping.filter(
      (e) => (e.iso2 === values.country || e.iso3 === values.country) && e.timezone
    );

    // TODO refactor
    // handle countries not supported by city-timezones (ie: Anguilla)
    if (values.facilityCountry === 'AI') {
      tzs = [{ timezone: 'America/Anguilla' }];
    }

    const uniqueTzs = [...new Set(tzs.map((i) => i.timezone))];

    setTimezones(uniqueTzs);
    setFieldValue('timezone', uniqueTzs[0], true);
  }, [values.country]);

  return (
    <div className="max-w-md pt-4 pb-12">
      <div className="flex items-center p-2 border rounded bg-secondary-grey-light">
        <svg viewBox="0 0 70 70" className="flex-shrink-0 h-12 fill-current text-secondary">
          <path d="M34.1388 40.9258H5.25L10.25 66.4813L34.1388 69.9998L58.0276 66.4813L63.0276 40.9258H34.1388Z" />
          <path d="M60.7125 19.4444H51.9162V14.7222H40.3421L38.6755 2.49999H40.157V0H34.6014H33.4903H27.9348V2.49999H29.4162L27.7496 14.7222H16.3607V19.4444H7.56445V39.1666H60.7125V19.4444ZM19.4163 31.111H13.3978V25.0925H19.4163V31.111ZM28.3051 31.111H22.2866V25.0925H28.3051V31.111ZM37.1014 31.111H31.0829V25.0925H37.1014V31.111ZM45.9903 31.111H39.9718V25.0925H45.9903V31.111ZM54.8791 31.111H48.8606V25.0925H54.8791V31.111Z" />
        </svg>
        <div className="pl-4 text-sm text-left">
          <p className="font-bold truncate-2" style={{ maxWidth: '320px' }}>
            {values.ship.name}
          </p>
        </div>
      </div>

      <div className="pt-12 space-y-4">
        <InputField name="homeport" label="Homeport*" namespaces={['dashboard']} />
        <InputField
          type="select"
          name="country"
          label="Country of departure*"
          namespaces={['dashboard']}
          options={countries
            .map((country) => ({
              ...country,
              label: t(`countries:${country.label}`),
            }))
            .sort((a, b) => t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`)))}
        />
        {values.country && timezones.length > 1 && (
          <InputField
            type="select"
            name="timezone"
            label="forms.address.timezone"
            namespaces={['dashboard']}
            options={timezones.map((tz) => ({ label: tz, value: tz }))}
          />
        )}
        <div className="flex flex-col space-y-1">
          <span className="text-sm">Sail date*</span>
          <div className="w-1/2">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={values.sail}
              onChange={(date) => {
                if (date === null) return;
                setFieldValue('sail', date);
              }}
              minDate={minDate}
              className="w-full px-3 py-2 text-sm transition border rounded focus:outline-none disabled:pointer-events-none"
            />
          </div>
        </div>
      </div>

      <div className="pt-12 space-y-8">
        <div>
          <span className="text-xl font-bold">Assignments</span>
          <p className="pt-1 text-sm text-secondary">
            Select the application Health Security sections for each user.
          </p>
        </div>
        {values.assignments.map((assignment, i) => (
          <AssignmentForm
            key={assignment.user.id}
            index={i}
            user={assignment.user}
            topics={assignment.topics}
          />
        ))}
      </div>
    </div>
  );
};

export default UpdateJourneyForm;

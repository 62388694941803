import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Spinner } from 'src/components';

import { textFromEvidenceType, formatEpochToPrettyDate } from '../helpers/formatting';

const ModalEvidenceProof = ({ open, requestClose, employee }) => {
  // const employee = {
  //   id: 'b39f0873-db4e-4ab3-8ace-a126858a0072',
  //   employeeExternalId: '543643',
  //   firstName: 'Erik',
  //   lastName: 'Bailly',
  //   lastFacilityId: '1861-687',
  //   lastCheckStatus: 'SUCCESS',
  //   lastCheckDate: 1641466371844,
  //   evidences: [
  //     {
  //       evidencePagePaths: [
  //         {
  //           path:
  //             'https://stgssandbox.blob.core.windows.net/attachments/28fcn26mwc83.93f2840207bbe3bc849b1b2e3f90a13852a65771.1927b045.jpg',
  //           page: 1,
  //         },
  //         {
  //           path:
  //             'https://stgssandbox.blob.core.windows.net/attachments/44mrz97mrh96.374c81999b1e2f2588856a3bd9e39a39ad0470e9.45188b29.png',
  //           page: 2,
  //         },
  //         {
  //           path:
  //             'https://stgssandbox.blob.core.windows.net/attachments/49vwm89bgq68.fdd7c1b4067a8558565174f30774f17ead6f7c2d.8f6b9adf.png',
  //           page: 3,
  //         },
  //       ],
  //       evidenceType: 'VACCINE_CARD',
  //       evidenceDeclaredDate: 1626912000000,
  //       evidenceExpireDate: 1637539200000,
  //       createdAt: 1641466390360,
  //       evidenceRemainingTime: -5845419599,
  //     },
  //     {
  //       evidencePagePaths: [
  //         {
  //           path:
  //             'https://stgssandbox.blob.core.windows.net/attachments/49vwm89bgq68.fdd7c1b4067a8558565174f30774f17ead6f7c2d.8f6b9adf.png',
  //           page: 1,
  //         },
  //         {
  //           path:
  //             'https://stgssandbox.blob.core.windows.net/attachments/28fcn26mwc83.93f2840207bbe3bc849b1b2e3f90a13852a65771.1927b045.jpg',
  //           page: 2,
  //         },
  //       ],
  //       evidenceType: 'VACCINE_CARD',
  //       evidenceDeclaredDate: 1638921600000,
  //       evidenceExpireDate: 1649376000000,
  //       createdAt: 1641466613215,
  //       evidenceRemainingTime: 5991380401,
  //     },
  //     {
  //       evidencePagePaths: [
  //         {
  //           path:
  //             'https://stgssandbox.blob.core.windows.net/attachments/28fcn26mwc83.93f2840207bbe3bc849b1b2e3f90a13852a65771.1927b045.jpg',
  //           page: 1,
  //         },
  //       ],
  //       evidenceType: 'EMPLOYER_EXEMPTION',
  //       evidenceDeclaredDate: 1641831822252,
  //       evidenceExpireDate: 1657470222252,
  //       createdAt: 1641831822275,
  //       evidenceRemainingTime: 14085602653,
  //     },
  //     {
  //       evidencePagePaths: [
  //         {
  //           path:
  //             'https://stgssandbox.blob.core.windows.net/attachments/98fex96dhv49.412df0ee2ea22ffc9710a105fe1428abccc8b8e1.68b18e69.png',
  //           page: 1,
  //         },
  //       ],
  //       evidenceType: 'VACCINE_CARD',
  //       evidenceDeclaredDate: 1639267200000,
  //       evidenceExpireDate: 1649721600000,
  //       createdAt: 1641894513043,
  //       evidenceRemainingTime: 6336980401,
  //     },
  //   ],
  //   exposureStatus: 'NOT EXPOSED',
  //   exposureLastCheckAge: 1918247755,
  //   protectionStatus: 'EXPIRED',
  // };

  const { evidences } = employee;
  const user = useSelector((state) => state.user);

  const [currEvidence, setCurrEvidence] = useState({
    evidence: 0,
    page: 0,
  });
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgUrl, setImgUrl] = useState(
    evidences[currEvidence.evidence].evidencePagePaths[currEvidence.page].path
  );

  useEffect(() => {
    setImgLoaded(false);
  }, [imgUrl]);

  useEffect(() => {
    setImgUrl(evidences[currEvidence.evidence].evidencePagePaths[currEvidence.page].path);
  }, [currEvidence]);

  const handleImageLoaded = () => {
    setImgLoaded(true);
  };

  const handlePageTurn = (direction) => {
    setCurrEvidence((prev) => {
      return { ...prev, page: prev.page + direction };
    });
  };

  const handleEvidenceClick = (index) => {
    setCurrEvidence({ evidence: index, page: 0 });
  };

  return (
    <Modal open={open} requestClose={requestClose} maxWidth>
      <div className="flex flex-row">
        <div className="flex flex-col items-center gap-2 p-6 border-r">
          {evidences.map((e, i) => {
            return (
              <div key={i}>
                {i === currEvidence.evidence ? (
                  <div
                    key={i}
                    className="py-2 pl-4 text-sm font-bold rounded-lg cursor-pointer w-44"
                    style={{ background: '#E7ECF0' }}
                    onClick={() => handleEvidenceClick(i)}
                  >
                    {textFromEvidenceType(employee.evidences[i]?.evidenceType)}
                  </div>
                ) : (
                  <div
                    key={i}
                    className="py-2 pl-4 text-sm rounded-lg cursor-pointer w-44"
                    onClick={() => handleEvidenceClick(i)}
                  >
                    {textFromEvidenceType(employee.evidences[i]?.evidenceType)}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col w-full">
          <Modal.Header>
            <h4 className="text-lg font-bold">Evidence</h4>
            <div className="absolute right-0 pr-6">
              <button type="button" onClick={requestClose}>
                <svg viewBox="0 0 20 20" className="w-5 h-5">
                  <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
                </svg>
              </button>
            </div>
          </Modal.Header>

          <Modal.Body className="flex flex-col items-center px-2 lg:mx-4">
            <div className="flex flex-row items-center gap-2">
              {currEvidence.page > 0 && (
                <div
                  className="p-2 rounded-full cursor-pointer"
                  style={{ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)' }}
                  onClick={() => handlePageTurn(-1)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.13174 9.1018L12.8743 0.359281C13.3832 -0.11976 14.1617 -0.11976 14.6407 0.359281C15.1198 0.838323 15.1198 1.61677 14.6407 2.12575L6.76647 10L14.6407 17.8743C15.1198 18.3533 15.1198 19.1617 14.6407 19.6407C14.1617 20.1198 13.3832 20.1198 12.8743 19.6407L4.13174 10.8683C3.6527 10.3892 3.6527 9.61078 4.13174 9.1018Z"
                      fill="#5D6A74"
                    />
                  </svg>
                </div>
              )}
              {currEvidence.page < 1 && <div className="w-9"></div>}
              <div
                className="flex p-4 m-4 max-h-96"
                style={{ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', maxHeight: '60vh' }}
              >
                {!imgLoaded && (
                  <div className="flex justify-center m-40 text-primary-light">
                    <Spinner />
                  </div>
                )}
                <img
                  style={{ maxHeight: '64vh' }}
                  src={imgUrl}
                  alt="Evidence Image"
                  onLoad={handleImageLoaded}
                  className="object-scale-down"
                  style={{ display: imgLoaded ? 'block' : 'none' }}
                />
              </div>
              {currEvidence.page <
                evidences[currEvidence.evidence].evidencePagePaths.length - 1 && (
                <div
                  className="p-2 rounded-full cursor-pointer"
                  style={{ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)' }}
                  onClick={() => handlePageTurn(1)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.8683 10.8982L7.12574 19.6407C6.61676 20.1198 5.83832 20.1198 5.35928 19.6407C4.88024 19.1617 4.88024 18.3832 5.35928 17.8743L13.2335 10L5.35928 2.12575C4.88024 1.64671 4.88024 0.838324 5.35928 0.359282C5.83832 -0.119761 6.61676 -0.119761 7.12574 0.359282L15.8683 9.13174C16.3473 9.61078 16.3473 10.3892 15.8683 10.8982Z"
                      fill="#5D6A74"
                    />
                  </svg>
                </div>
              )}
              {currEvidence.page >=
                evidences[currEvidence.evidence].evidencePagePaths.length - 1 && (
                <div className="w-9"></div>
              )}
            </div>
            {evidences[currEvidence.evidence].evidencePagePaths.length > 1 && (
              <div className="flex flex-row gap-2 mb-2">
                {evidences[currEvidence.evidence].evidencePagePaths.map((epp, i) => {
                  return (
                    <span key={i} className={i !== currEvidence.page ? 'text-gray-400' : ''}>
                      {i + 1}
                    </span>
                  );
                })}
              </div>
            )}
            <div className="flex flex-row mb-4 space-x-10 text-xs font-bold uppercase text-secondary">
              <div className="flex flex-col py-1 space-y-4 text-center w-44">
                <span>
                  <span className="font-normal">Evidence </span>
                  Type
                </span>
                <div className="font-normal text-center w-44">
                  {textFromEvidenceType(employee.evidences[currEvidence.evidence]?.evidenceType)}
                </div>
              </div>
              <div className="flex flex-col py-1 space-y-4 text-center w-44">
                <span>
                  <span className="font-normal">Evidence </span>Start Date
                </span>
                <div className="font-normal text-center w-44">
                  {formatEpochToPrettyDate(
                    employee.evidences[currEvidence.evidence]?.evidenceDeclaredDate
                  )}
                </div>
              </div>
              <div className="flex flex-col py-1 space-y-4 text-center w-44">
                <span>
                  <span className="font-normal">Evidence </span>Upload Date
                </span>
                <div className="font-normal text-center w-44">
                  {formatEpochToPrettyDate(employee.evidences[currEvidence.evidence]?.createdAt)}
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEvidenceProof;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CONFIG from 'src/config';

const { VERTICAL } = CONFIG;
const verticalLower = VERTICAL.toLowerCase();

const CLASSNAMES = {
  VERIFIED: 'border-black hover:bg-secondary-grey-light focus:bg-secondary-grey-light',
  PENDING:
    'text-white border-primary-light bg-primary-light hover:bg-primary-light-hover hover:border-primary-light-hover focus:bg-primary-light-hover focus:border-primary-light-hover',
  UNVERIFIED:
    'text-white border-warning bg-warning hover:bg-warning-light hover:border-warning-light focus:bg-warning-light focus:border-warning-light',
  REVOKED:
    'text-white border-danger bg-danger hover:bg-danger-light hover:border-danger-light focus:bg-danger-light focus:border-danger-light',
};

const Actions = ({ externalId, dashboardId, status }) => {
  const { t } = useTranslation('facility');

  const configLinks = useSelector((state) => state.configLink);
  const { botLink, certificationLink, auditLink } = configLinks;

  const user = useSelector((state) => state.user);

  // 1 or 2 buttons
  return (
    <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-6 md:max-w-screen-sm">
      <a
        href={botLink}
        onClick={() => {
          if (window.gtag) window.gtag('event', 'Clic_take_assessment');
        }}
        target="_blank"
        rel="noreferrer"
        className={`w-full md:w-3/5 max-w-md py-4 transition border-2 rounded-md text-center focus:outline-none ${CLASSNAMES[status]}`}
      >
        {t([`${verticalLower}:facility.badge.buttons.platform`, 'badge.buttons.platform'])}
      </a>
      {
        ((user.role !== 'VIEWER' &&  ['VULCAIN','GIFEN'].includes(VERTICAL)) || !['VULCAIN','GIFEN'].includes(VERTICAL)) && (
          <a
            href={
              ['VULCAIN', 'GIFEN', 'DEKRA'].includes(VERTICAL)
                ? `${auditLink}?t=${encodeURIComponent(dashboardId)}`
                : `${certificationLink}/${externalId}`
            }
            className="w-full max-w-md py-4 text-center transition border-2 border-gray-300 rounded-md hover:bg-secondary-grey-light focus:bg-secondary-grey-light focus:outline-none md:w-2/5"
            target="_blank"
            rel="noreferrer"
          >
            {t([`${verticalLower}:facility.badge.buttons.verification`, 'badge.buttons.verification'])}
          </a>  
        )
      }
    </div>
  );
};

Actions.propTypes = {
  status: PropTypes.oneOf(['PENDING', 'VERIFIED', 'UNVERIFIED', 'REVOKED']).isRequired,
  externalId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string,
};

Actions.defaultProps = {
  dashboardId: '',
};

export default Actions;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { httpClient } from 'src/helpers';
import initialValues from 'src/views/app/pages/dashboard/pages/facilities/components/modal-add/initial-values';
import { Modal, Snackbar, Button } from 'src/components';
import NewTeamForm from './NewTeamForm';

import formatValues from './format-values';

const newTeamSchema = Yup.object().shape({
  facilityName: Yup.string().max(250, 'forms.errors.facility.name').required('forms.required'),
  facilityCity: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityPostalCode: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityCountry: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  manager: Yup.object().shape({
    firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    email: Yup.string().max(250, 'forms.too_long').email('forms.format').required('forms.required'),
    title: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    phone: Yup.string()
      .max(250, 'forms.too_long')
      .test('phone-match-regex', 'forms.format', (phone) => {
        if (!phone || phone === '') return true;
        return phone.match(
          /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
        );
      })
      .nullable(),
  }),
});

const ModalNewTeam = ({ open, requestClose, users, products, industries, mutate, subClientId }) => {
  const { t } = useTranslation(process.env.GATSBY_VERTICAL.toLowerCase());
  const user = useSelector((state) => state.user);
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    setSnackbarState({ type: 'LOADING' });
    document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });

    try {
      if (window.gtag) window.gtag('event', 'Add_team');

      const formatedValues = formatValues(values);
      formatedValues.clientId = user.clientId;

      const facilityAPI =
        ['VULCAIN', 'GIFEN', 'EQUILIBRE'].includes(process.env.GATSBY_VERTICAL) && subClientId
          ? `/subclients/${subClientId}/facility`
          : '/facilities';
      const { status } = await httpClient.post(facilityAPI, formatedValues);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();
        setTimeout(() => {
          requestClose();
        }, 1000);
      } else throw Error('Facility creation failed.');
    } catch (err) {
      if (err.response.status === 409 && err.response.data && err.response.data.code)
        switch (err.response.data.code) {
          case 'EMAIL':
            setSnackbarState({
              type: 'ERROR',
              msg: `${err.response.data.data} is already used. Please select existing user or change email.`,
            });
            break;
          case 'NAME':
            setSnackbarState({ type: 'ERROR', msg: 'Team name already exists' });
            break;
          default:
            setSnackbarState({ type: 'ERROR' });
        }
      else setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  // set Amba Context (= facilityIndustry)
  let facilityIndustry = industries[0];
  
  switch (process.env.GATSBY_VERTICAL) {
    case 'DEKRA': {
      facilityIndustry = '';

      break;
    }
    case 'VULCAIN': {
      if (user.endorser === 'edvance') {
        facilityIndustry = industries.find((i) => i.name === 'edvance');
      }

      break;
    }
    default: {
      break;
    }
  }

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <h4 className="text-lg font-bold">
          {t(['forms.team.new_team', 'dashboard:forms.team.new_team'])}
        </h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        <Formik
          initialValues={{
            ...initialValues,
            facilityCountry: 'FR',
            facilityIndustry,
            facilityProduct: { ...products[0] },
            billingAttention: `${user.firstName} ${user.lastName}`,
            billingAddressee: `${user.clientName}`,
            contactEmail: user.email,
            billingAddresseePhone: user.phone,
          }}
          validationSchema={newTeamSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form className="max-w-md mx-auto" autoComplete="off">
              <Snackbar
                type={snackbarState.type}
                message={snackbarState.msg}
                requestClose={() => setSnackbarState({ type: 'HIDDEN' })}
              />
              <NewTeamForm
                users={users.map((u) => {
                  if (u.email === user.email) return { ...u, me: true };
                  return u;
                })}
                context={facilityIndustry.name}
              />

              <div className="flex flex-col items-center justify-center pb-6">
                <div className="flex w-full">
                  <Button
                    type="submit"
                    size="md"
                    disabled={
                      !(isValid && dirty) || isSubmitting || snackbarState.type === 'SUCCESS'
                    }
                  >
                    {t(['forms.team.create_team', 'dashboard:forms.team.create_team'])}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ModalNewTeam.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      policy: PropTypes.shape({}).isRequired,
    }).isRequired
  ).isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      industryId: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  subClientId: PropTypes.number,
};

ModalNewTeam.defaultProps = {
  subClientId: null,
};

export default ModalNewTeam;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { fetchOnce, httpClient } from 'src/helpers';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const filterOptions = (option, input) => {
  if (!input || input === '') return true;
  return option.data.name.toLowerCase().includes(input.toLowerCase());
};

const initialSaving = { inProgress: false, success: false, error: false };

function AssociateBU({ facility, setSnackbarState, associateBU, facilityMutate }) {
  const { t, i18n } = useTranslation([process.env.GATSBY_VERTICAL.toLowerCase(), 'facility']);
  const user = useSelector((state) => state.user);

  const noneItem = { value: null, label: t(['bu.none', 'facility:bu.none']) };

  const { data: OUData, isValidating: OUIsValidating } = useSWR(
    user.clientId ? [`/clients/${user.clientId}/subclients`] : null,
    fetchOnce
  );

  const [selectedOU, setSelectedOU] = useState(associateBU || '');
  const [associateOptions, setassociateOptions] = useState([]);

  useEffect(() => {
    let tempAssociateOptions = Array.isArray(OUData)
      ? OUData.map((m) => ({ ...m, value: m.id, label: m.name }))
      : [];
    const prevSelectedOU = tempAssociateOptions.filter((item) => item.id === associateBU).pop();
    setSelectedOU(prevSelectedOU);

    tempAssociateOptions = tempAssociateOptions
      .filter((item) => item.id !== associateBU)
      .sort((a, b) => a.name.localeCompare(b.name));
    tempAssociateOptions.unshift(noneItem);

    setassociateOptions(tempAssociateOptions);
  }, [OUIsValidating, i18n.language, associateBU]);

  const [saving, setSaving] = useState({ ...initialSaving });
  const { inProgress } = saving;

  const handleChange = async (val) => {
    setSaving({ ...initialSaving, inProgress: true });
    const prevSelValue = selectedOU;
    setSelectedOU(val);
    const requestObj = {
      name: facility.name,
      protocolUrl: null,
      capacityEmployees: facility.employees,
      capacityRooms: facility.rooms,
      timezone: facility.timezone,
      address: facility.address,
      clientId: user.clientId,
      subclientId: val.value,
    };
    try {
      const { status } = await httpClient.put(`/facilities/${facility.id}`, requestObj);
      if (status === 200) {
        setSaving({ ...initialSaving, success: true });
        facilityMutate();
      } else throw Error;
    } catch {
      setSelectedOU(prevSelValue);
      setSaving({ ...initialSaving, error: true });
      setSnackbarState({ type: 'ERROR', msg: t('common:msgs.error'), scrollIntoView: true });
    }
  };

  return (
    <div className="px-8 py-6 md:px-6 lg:px-12 xl:px-24">
      <div className="space-y-6">
        <div className="text-3xl font-bold">{t(['bu.associate_bu','facility:bu.associate_bu'])}</div>
        <div className="relative p-10 2xl:w-4/6 lg:w-3/5 md:w-3/5 w-full items-center lg:space-x-8 bg-secondary-grey-light rounded-md">
          <div>
            <Select
              classNamePrefix="r-select"
              options={associateOptions}
              filterOption={filterOptions}
              // formatOptionLabel={formatOptionLabel}
              placeholder={t([
                'bu.select_bu',
                'facility:bu.select_bu',
              ])}
              closeMenuOnSelect
              onChange={(value) => handleChange(value)}
              value={selectedOU}
              isDisabled={!['ADMIN', 'SUPERADMIN'].includes(user.role) ? true : inProgress}
              isLoading={inProgress}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AssociateBU.propTypes = {
  setSnackbarState: PropTypes.func.isRequired,
  associateBU: PropTypes.number.isRequired,
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    employees: PropTypes.number.isRequired,
    rooms: PropTypes.number.isRequired.isRequired,
    unit: PropTypes.string.isRequired,
    subclientId: PropTypes.number.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
    }),
    drmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
    gmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    prUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    status: PropTypes.oneOf(['PENDING', 'VERIFIED', 'UNVERIFIED', 'REVOKED']),
    timezone: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
  }).isRequired,
  facilityMutate: PropTypes.func.isRequired,
};

export default AssociateBU;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Address = ({ data, title }) => {
  const { addressee, attention, phone, address, email } = data;
  const { t } = useTranslation('facility');

  return (
    <div>
      <h5 className="font-bold">{t(`billing.titles.${title}`)}</h5>
      <div className="grid max-w-screen-sm grid-cols-2 gap-4 pt-4 break-words">
        <span className="text-secondary">{t('billing.address.attention')}</span>
        <span>{attention}</span>
        <span className="text-secondary">{t('billing.address.addressee')}</span>
        <span>{addressee}</span>
        {email && (
          <>
            <span className="text-secondary">{t('billing.address.email')}</span>
            <span className="break-all">{email}</span>
          </>
        )}
        <span className="text-secondary">{t('billing.address.phone')}</span>
        <span>{phone}</span>
        <span className="text-secondary">{t('informations.items.address')}</span>
        <span>
          {address.line1}
          {address.line2 && (
            <>
              <br />
              {address.line2}
            </>
          )}
          <br />
          <span>{`${address.zipCode}, ${address.city} `}</span>
          {address.country && <span className="uppercase">{address.country}</span>}
        </span>
      </div>
    </div>
  );
};

Address.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    addressee: PropTypes.string.isRequired,
    attention: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string,
      zipCode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Address;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { httpClient } from 'src/helpers';
import { Modal, Multistep, Spinner } from 'src/components';

import CONFIG from 'src/config';
import initialValues from './initial-values';
import formatValues from './format-values';
import FacilityAddressForm from './FacilityAddressForm';
import ManagerForm from './ManagerForm';
import PackageForm from './PackageForm';
import IndustryForm from './IndustryForm';
import Success from './Success';
import Error from './Error';

import {
  facilityAddr1V2Schema,
  facilityAddr2Schema,
  facilityAddr1V1RoomSchema,
  managerSchema,
  packageSchema,
  industrySchema,
} from './Schemas';

const verticalLower = CONFIG.VERTICAL.toLowerCase();

const ModalAddMultistep = ({ open, requestClose, users, products, industries, mutate }) => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation(['dashboard', 'common', 'greenshield']);

  const [facilityAPI, setFacilityAPI] = useState({
    isLoading: false,
    isComplete: false,
    isSuccess: false,
    isError: false,
  });

  const { isLoading, isComplete, isSuccess, isError } = facilityAPI;

  const [product] = useState(products[0]);

  const handleSubmitRequest = async (values) => {
    setFacilityAPI({ ...facilityAPI, isLoading: true });

    const formatedValues = formatValues(values);
    formatedValues.clientId = user.clientId;

    try {
      const { status } = await httpClient.post(`/facilities`, formatedValues);

      if (status === 200) {
        mutate();

        setTimeout(() => {
          setFacilityAPI({
            ...facilityAPI,
            isLoading: false,
            isComplete: true,
            isError: false,
            isSuccess: true,
          });
        }, 4500);
      } else {
        throw Error('Facility creation failed.');
      }
    } catch (error) {
      setFacilityAPI({
        ...facilityAPI,
        isLoading: false,
        isComplete: true,
        isError: true,
        isSuccess: false,
      });
    }
  };

  const handleSubmit = (values, actions) => {
    if (typeof document !== 'undefined') document.getElementById('modal-body').scrollTop = 0;

    handleSubmitRequest(values);
    if (window.gtag) window.gtag('event', `Create_facility_validated`);

    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleClose = () => {
    setFacilityAPI({
      isLoading: false,
      isComplete: false,
      isSuccess: false,
      isError: false,
    });
    requestClose();
  };

  const getSteps = () => {
    switch (CONFIG.VERTICAL) {
      case 'HOSPITALITY': {
        return [
          <FacilityAddressForm key={1} schema={facilityAddr1V1RoomSchema} />,
          <FacilityAddressForm
            key={2}
            header={t(`forms.address.titles.address`)}
            schema={facilityAddr2Schema}
          />,
          <ManagerForm key={3} schema={managerSchema} users={users} />,
          <PackageForm key={4} schema={packageSchema} />,
        ];
      }
      case 'GREENSHIELD': {
        return [
          <FacilityAddressForm key={1} schema={facilityAddr1V2Schema} />,
          <FacilityAddressForm
            key={2}
            header={t(`forms.address.titles.address`)}
            schema={facilityAddr2Schema}
          />,
          <ManagerForm key={3} schema={managerSchema} users={users} />,
          <PackageForm key={4} schema={packageSchema} />,
        ];
      }
      case 'DEKRA': {
        return [
          <IndustryForm key={1} industries={industries} schema={industrySchema} />,
          <FacilityAddressForm
            key={1}
            schema={facilityAddr1V2Schema}
            header={t(`dekra:forms.address.titles.information`)}
          />,
          <FacilityAddressForm
            key={2}
            header={t(`dekra:forms.address.titles.address`)}
            schema={facilityAddr2Schema}
          />,
          <ManagerForm key={3} schema={managerSchema} users={users} />,
          <PackageForm key={4} schema={packageSchema} />,
        ];
      }
      default:
        return [
          <FacilityAddressForm key={1} schema={facilityAddr1V2Schema} />,
          <FacilityAddressForm
            key={2}
            header={t(`forms.address.titles.address`)}
            schema={facilityAddr2Schema}
          />,
          <ManagerForm key={3} schema={managerSchema} users={users} />,
          <PackageForm key={4} schema={packageSchema} />,
        ];
    }
  };

  return (
    <Modal
      open={open}
      requestClose={handleClose}
      closeOnEsc={facilityAPI.isComplete}
      closeOnOverlay={facilityAPI.isComplete}
      maxWidth
    >
      {isLoading && (
        <div className="flex items-center justify-center py-24 text-primary-light">
          <div className="h-12">
            <Spinner />
          </div>
        </div>
      )}
      {isError && <Error handleClose={handleClose} />}
      {isSuccess && <Success handleClose={handleClose} />}
      {!isLoading && !isComplete && !isError && !isSuccess && (
        <Multistep
          initialValues={{
            ...initialValues,
            facilityIndustry: CONFIG.VERTICAL === 'DEKRA' ? '' : industries[0],
            facilityProduct: { ...product },
          }}
          handleSubmit={handleSubmit}
          requestClose={handleClose}
          fixed={
            <div className="flex flex-col items-center justify-center pb-12 text-center">
              <h5 className="text-xl font-bold lg:text-3xl">
                {t([
                  `${verticalLower}:forms.modal.add_facility.title`,
                  'forms.modal.add_facility.title',
                ])}
              </h5>
            </div>
          }
          labels={{
            next: 'Next',
            last: 'Create Facility',
          }}
        >
          {getSteps()}
        </Multistep>
      )}
    </Modal>
  );
};

ModalAddMultistep.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      policy: PropTypes.shape({
        id: PropTypes.number.isRequired,
        flat: PropTypes.bool.isRequired,
        price: PropTypes.number,
        rate: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      industryId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  mutate: PropTypes.func.isRequired,
};

ModalAddMultistep.defaultProps = {
  users: null,
};

export default ModalAddMultistep;

import React, { useState, useEffect } from 'react';
import Loadable from '@loadable/component';
import PropTypes from 'prop-types';

import { httpClient } from 'src/helpers';
import { Page, Spinner } from 'src/components';
import iconPdf from 'src/assets/images/icon-pdf.png'
import iconPdfSingle from 'src/assets/images/icon-pdf-single.png'
import iconPpt from 'src/assets/images/icon-ppt.png'
import iconPptSingle from 'src/assets/images/icon-ppt-single.png'


const DynamicClientDashboard = Loadable(
  () => import('powerbi-client-react').then((mod) => mod.PowerBIEmbed),
  { ssr: false }
);

const Dashboard = ({ path }) => {
  const [embedData, setEmbedData] = useState(null);
  const [report, setReport] = useState(null);
  const [exporting, setexporting] = useState(false);

  const initializeBot = async () => {
    try {
      const level = path === '/roster' ? 'roster' : 'client';
      const { data } = await httpClient.get(`/users/me/dashboard/token?field=userId&level=${level}`);
      setEmbedData(data);
    } catch (err) {
      console.log('embedData fetching error');
    }
  };

  const exportFull = async (format) => {
    setexporting(true);
    try {
      const request =       {    
        format,
        powerBIReportConfiguration: {
            settings: {
                locale: 'fr-FR'
            }
        }
      }

      const bookmarkResult = await report.bookmarksManager.capture({
        personalizeVisuals: false,
        allPages: true
      });
      if( bookmarkResult ) {
        request.powerBIReportConfiguration.defaultBookmark = { state: bookmarkResult.state };
      }

      await exportRequest(request, format);
    } catch (err) {
      console.log('export fetching error ' + err);
      throw err;
    }
    setexporting(false);
  }

  const exportPage = async (format) => {
    setexporting(true);
    try {
      const request =       {    
        format,
        powerBIReportConfiguration: {
            settings: {
                locale: 'fr-FR'
            }
        }
      }

      const page = await report.getActivePage();
      request.powerBIReportConfiguration.pages = [{
        pageName: page.name
      }]

      const bookmarkResult = await report.bookmarksManager.capture({
        personalizeVisuals: false,
        allPages: false
      });
      if( bookmarkResult ) {
        request.powerBIReportConfiguration.defaultBookmark = { state: bookmarkResult.state };
      }

      await exportRequest(request, format);
    } catch (err) {
      console.log('export fetching error ' + err);
      throw err;
    }
    setexporting(false);
  }

  const exportRequest = async (request, format) => {
    try {
      const level = path === '/roster' ? 'roster' : 'client';
      const response = await httpClient.post(`/users/me/dashboard/export?field=userId&level=${level}`, 
      request,
      {
        responseType: 'blob'
      });
      
      const disposition = response.headers['content-disposition'];
      let filename = disposition?.split(/;(.+)/)[1].split(/=(.+)/)[1] || 'EXPORT.' + format;
      if (filename.toLowerCase().startsWith("utf-8''"))
        filename = filename.replace("utf-8''", '');
      filename = decodeURIComponent(filename)
      filename = filename.replace(/['"]/g, '');

      //const url = window.URL.createObjectURL([new Blob(response.data)]);
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // append the element to the dom
      a.click();
      a.remove(); // afterwards, remove the element 

    } catch (err) {
      console.log('export fetching error ' + err);
    }    
  }

  useEffect(() => {
    initializeBot();
  }, []);

  return (
    <Page title="Dashboard">
      <Page.Main>
        {embedData && ( 
          <div className="flex block">
            <DynamicClientDashboard
              embedConfig={{
                type: 'report',
                accessToken: embedData.accessToken,
                embedUrl: embedData.embedUrl[0].embedUrl,
                id: embedData.embedUrl[0].reportId,
                tokenType: 1,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                },
              }}
              cssClassName="powerbi-dashboard"
              eventHandlers={
                new Map([
                  ['loaded', () => console.log('Report loaded')],
                  ['rendered', () => console.log('Report rendered')],
                  ['error', (event) => console.log(event.detail)],
                ])
              }
              getEmbeddedComponent = { (embeddedReport) => {
                //this.report = embeddedReport as Report;
                setReport(embeddedReport)
              }}
            />
            <div className="export-panel flex flex-col items-center">
              {exporting ? 
              <Spinner/>
              :<>
              <button className="relative whitespace-nowrap justify-center w-full px-8 py font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 lg:w-max"
                onClick={ async () => {await exportFull('PDF'); type="button"}}>
                  <img src={iconPdf} width={30} />
              </button>
              <button className="relative whitespace-nowrap justify-center w-full px-8 py font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 lg:w-max"
                onClick={ async () => {await exportFull('PPTX'); type="button"}}>
                  <img src={iconPpt} width={30} />
              </button>
              <button className="relative whitespace-nowrap justify-center w-full px-8 py font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 lg:w-max"
                onClick={ async () => {await exportPage('PDF'); type="button"}}>
                  <img src={iconPdfSingle} width={30} />
              </button>
              <button className="relative whitespace-nowrap justify-center w-full px-8 py font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 lg:w-max"
                onClick={ async () => {await exportPage('PPTX'); type="button"}}>
                  <img src={iconPptSingle} width={30} />
              </button>
              </>}
            </div>
          </div>
        )}
      </Page.Main>
    </Page>
  );
};

export default Dashboard;

Dashboard.propTypes = {
  path: PropTypes.string.isRequired
};


import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';

import { httpClient } from 'src/helpers';
import { Modal, Button } from 'src/components';

const ModalTransfer = ({ open, requestClose, mutate, user, facility }) => {
    const { t } = useTranslation(process.env.GATSBY_VERTICAL.toLowerCase());
    //  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });
    //  const { values, setValues } = useFormikContext();
    const [isSubmitting, setIsSubmitting] = useState(false);


    const [selectedFacility, setSelectedFacility] = useState(null);

    const fetchFacilities = (input) =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`/facilities?clientId=${facility.clientId}&size=100`)
        .then(({ data }) => {
            let facilities = data.facilities.filter(f => facility.id != f.id)
            if( input ) {
                facilities = facilities.filter(f => f.name.toLowerCase().includes(input.toLowerCase()))
            }
            facilities = facilities.filter(f => f.statusChatbot !== "DELETED")
            const order = (a, b) => a.name.localeCompare(b.name);
            facilities = facilities.sort(order)

            resolve(facilities);
        })
        .catch((e) => reject(e));
    });


      const formatOptionLabel = ({ name }) => (
        <div className="flex items-center p-2 border rounded bg-secondary-grey-light">
            <svg viewBox="0 0 75 80" className="h-12 fill-current text-secondary">
                <path d="M68.7458 21.718C65.9837 16.9349 42.6068 3.44434 37.0826 3.44434C31.5668 3.44434 8.18158 16.9349 5.41948 21.718C2.85106 26.1643 2.85106 53.8359 5.41948 58.2822C8.19001 63.0654 31.5668 76.5559 37.0826 76.5559C42.6068 76.5559 65.9837 63.0654 68.7458 58.2822C71.3142 53.8359 71.3142 26.1643 68.7458 21.718Z" />
                <path d="M71.7263 20C68.3747 14.2063 44.0379 0 37.0821 0C30.1263 0 5.78947 14.2063 2.43789 19.9916C-0.812631 25.6084 -0.812631 54.3916 2.43789 60C5.78947 65.7937 30.1263 80 37.0821 80C44.0379 80 68.3747 65.7937 71.7263 60.0084C74.9768 54.3916 74.9768 25.6084 71.7263 20ZM69.941 58.9726C66.8589 64.2863 43.2211 77.9368 37.0821 77.9368C30.9432 77.9368 7.29684 64.2863 4.22316 58.9642C1.35158 54.0042 1.35158 25.9874 4.22316 21.0274C7.30526 15.7137 30.9516 2.06316 37.0821 2.06316C43.2211 2.06316 66.8589 15.7053 69.941 21.0274C72.8126 25.9958 72.8126 54.0042 69.941 58.9726Z" />
            </svg>
            <div className="pl-4 text-sm text-left">
                <p className="font-bold truncate-2" style={{ maxWidth: '320px' }}>
                {`${name}`}
                </p>
            </div>
        </div>
      );

      const handleSelect = (value) => {
        setSelectedFacility(value);
      };

      const handleTransfer = async () => {
        try {
            setIsSubmitting(true);
          const { status, body } = await httpClient.patch(`/facilities/${facility.id}/users`, 
            {targetFacilityId: selectedFacility.id, email: user.email});
    
          if (status === 200) {
            mutate();
            requestClose();
          } 
          else throw Error(body);
        } 
        catch(error) {
          console.error('=== ERROR');
          console.error(error);
        }
      };

    return (
        <Modal open={open} requestClose={requestClose}>
            <Modal.Header>
                <div className="h-5" />
                <div className="absolute right-0 pr-6">
                    <button type="button" onClick={requestClose}>
                        <svg viewBox="0 0 20 20" className="w-5 h-5">
                            <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
                        </svg>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className="px-6">
                <span className="text-2xl font-bold">
                {t(['modals.transfer.title', 'dashboard:modals.transfer.title'])}
                </span>
                <p className="font-bold">{t(['modals.transfer.from','dashboard:modals.transfer.from'])}</p>
                <div className="flex items-center p-2 border rounded bg-secondary-grey-light">
                    <svg viewBox="0 0 75 80" className="h-12 fill-current text-secondary">
                        <path d="M68.7458 21.718C65.9837 16.9349 42.6068 3.44434 37.0826 3.44434C31.5668 3.44434 8.18158 16.9349 5.41948 21.718C2.85106 26.1643 2.85106 53.8359 5.41948 58.2822C8.19001 63.0654 31.5668 76.5559 37.0826 76.5559C42.6068 76.5559 65.9837 63.0654 68.7458 58.2822C71.3142 53.8359 71.3142 26.1643 68.7458 21.718Z" />
                        <path d="M71.7263 20C68.3747 14.2063 44.0379 0 37.0821 0C30.1263 0 5.78947 14.2063 2.43789 19.9916C-0.812631 25.6084 -0.812631 54.3916 2.43789 60C5.78947 65.7937 30.1263 80 37.0821 80C44.0379 80 68.3747 65.7937 71.7263 60.0084C74.9768 54.3916 74.9768 25.6084 71.7263 20ZM69.941 58.9726C66.8589 64.2863 43.2211 77.9368 37.0821 77.9368C30.9432 77.9368 7.29684 64.2863 4.22316 58.9642C1.35158 54.0042 1.35158 25.9874 4.22316 21.0274C7.30526 15.7137 30.9516 2.06316 37.0821 2.06316C43.2211 2.06316 66.8589 15.7053 69.941 21.0274C72.8126 25.9958 72.8126 54.0042 69.941 58.9726Z" />
                    </svg>
                    <div className="pl-4 text-sm text-left">
                        <p className="font-bold truncate-2" style={{ maxWidth: '320px' }}>
                            {facility.name}
                        </p>
                    </div>
                </div>
                <p className="font-bold">{t(['modals.transfer.to','dashboard:modals.transfer.to'])}</p>
                <div className="flex flex-col space-y-6">
                    <div>
                        <AsyncSelect
                        cacheOptions
                        defaultOptions
                        id="new-provider"
                        classNamePrefix="r-select"
                        loadOptions={fetchFacilities}
                        formatOptionLabel={formatOptionLabel}
                        noOptionsMessage={() => 'No facility'}
                        loadingMessage={() => 'Loading...'}
                        placeholder=""
                        onChange={handleSelect}
                        value={selectedFacility}
                        closeMenuOnSelect
                        />
                    </div>
                </div>
                <div className="pt-12" style={{ minHeight: '10rem' }} />
                <div className="pb-6">
                  <Button
                    type='button'
                    size="md"
                    disabled={
                      !selectedFacility || isSubmitting
                    }
                    onClick={handleTransfer}
                  >
                    {t(['modals.transfer.submit', 'dashboard:modals.transfer.submit'])}
                  </Button>
                </div>

            </Modal.Body>
        </Modal>
    );
};

ModalTransfer.propTypes = {
    open: PropTypes.bool.isRequired,
    requestClose: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        title: PropTypes.string,
        phone: PropTypes.string,
        roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
    facility: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        address: PropTypes.shape({
            city: PropTypes.string.isRequired,
            country: PropTypes.string,
        }).isRequired,
        context: PropTypes.string.isRequired,
        clientId: PropTypes.number.isRequired,
    }).isRequired,
};

export default ModalTransfer;
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import handlebars from 'handlebars/dist/cjs/handlebars';

import { Richtext } from 'src/components';

const { GATSBY_VERTICAL } = process.env;
const QUANTITY = 12;

const CheckoutInformation = ({ product }) => {
  const { t } = useTranslation(['dashboard', 'greenshield']);
  const [{ value: rooms }] = useField('facilityRooms');

  const subtotal = useCallback(() => {
    if (product.policy.flat) return product.policy.price;
    return rooms * product.policy.price < product.policy.rate
      ? product.policy.rate * QUANTITY
      : rooms * QUANTITY * product.policy.price;
  }, [rooms]);

  const productDescription = useCallback(() => {
    const template = handlebars.compile(product.policy.description);
    return template({ unitCount: rooms, flatPricePerYear: product.policy.price });
  }, [product.policy.description]);

  return (
    <div className="px-6 pt-12 pb-6 lg:sticky lg:top-0">
      <span className="text-2xl font-bold">{t('forms.checkout.title')}</span>
      <div className="pt-6 space-y-1">
        <span className="font-bold">
          {t(
            GATSBY_VERTICAL === 'GREENSHIELD'
              ? 'greenshield:forms.checkout.introduction.title'
              : 'dashboard:forms.checkout.introduction.title'
          )}
        </span>
        <Richtext content={productDescription()} className="text-sm text-secondary" />
      </div>
      <div className="pt-4 space-y-6">
        {!product.policy.flat && product.policy.price !== 0 && (
          <>
            <div className="flex items-center justify-between pb-2 border-b">
              <span>{t('forms.checkout.items.rooms')}</span>
              <span>{rooms}</span>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <span>{t('forms.checkout.items.rate')}</span>
                <span>{`$${(rooms * product.policy.price < product.policy.rate
                  ? product.policy.rate
                  : rooms * product.policy.price
                ).toFixed(2)}`}</span>
              </div>
              <div className="flex items-center justify-between">
                <span>{t('forms.checkout.items.quantity')}</span>
                <span>{`${QUANTITY}`}</span>
              </div>
            </div>
          </>
        )}
        <div className="flex items-center justify-between pt-2 font-bold border-t">
          <span className="uppercase">{t('forms.checkout.items.subtotal')}</span>
          <span>{`$${subtotal().toFixed(2)}`}</span>
        </div>
        <div className="space-y-4 text-sm text-secondary">
          <p>
            {t(
              GATSBY_VERTICAL === 'GREENSHIELD'
                ? 'greenshield:forms.checkout.terms.renews'
                : 'dashboard:forms.checkout.terms.renews'
            )}
            <a
              href="/legal"
              target="_blank"
              className="pl-1 text-blue-400 hover:underline focus:underline focus:outline-none focus:text-blue-900 hover:text-blue-900"
            >
              {t(
                GATSBY_VERTICAL === 'GREENSHIELD'
                  ? 'greenshield:forms.checkout.terms.term'
                  : 'dashboard:forms.checkout.terms.term'
              )}
            </a>
          </p>
          <p>
            {t(
              GATSBY_VERTICAL === 'GREENSHIELD'
                ? 'greenshield:forms.checkout.terms.vat'
                : 'dashboard:forms.checkout.terms.vat'
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

CheckoutInformation.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    policy: PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      flat: PropTypes.bool.isRequired,
      price: PropTypes.number.isRequired,
      rate: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CheckoutInformation;

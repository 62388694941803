import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CONFIG from 'src/config';
import { httpClient } from 'src/helpers';
import ModalInformations from './ModalInformations';
import ModalDeactivate from './ModalDeactivate';

const vertical = CONFIG.VERTICAL.toLowerCase();

const Informations = ({ facility, mutate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeactivate, setModalDeactivate] = useState(false);
  const { t } = useTranslation('facility', vertical);
  const config = useSelector((state) => state.configLink);
  const user = useSelector((state) => state.user);

  const handleDeactivate = async () => {
    try {
      const { status } = await httpClient.put(`/facilities/${facility.id}/deactivate`);

      if (status === 200) {
        mutate();
      } 
      else throw Error;
    } 
    catch(error) {
      console.error('=== ERROR');
      console.error(error);
    }
  };

  return (
    <>
      <div>
        <div className="flex items-center justify-between lg:justify-start lg:space-x-8">
          <h5 className="text-3xl font-bold">
            {t([
              `${vertical}:facility.informations.titles.property`,
              'informations.titles.property',
            ])}
          </h5>
          <button
            type="button"
            disabled={!['admin', 'superAdmin', 'manager'].includes(facility.userRole)}
            onClick={() => setModalOpen(true)}
            className="px-6 py-2 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
          >
            {t('informations.buttons.edit')}
          </button>
          {
            ((['SUPERADMIN', 'ADMIN'].includes(user.role) && CONFIG.VERTICAL === 'VULCAIN') ||
            (['SUPERADMIN', 'ADMIN'].includes(user.role) && CONFIG.VERTICAL === 'GIFEN') ||
            (['SUPERADMIN', 'ADMIN'].includes(user.role) && CONFIG.VERTICAL === 'EQUILIBRE')) 
            && (
              <button
                type="button"
                disabled={facility.statusChatbot !== 'DONE'}
                onClick={() => setModalDeactivate(true)}
                className="px-6 py-2 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
              >
                {t('informations.buttons.deactivate')}
              </button>
            )
          }

        </div>
        {['VULCAIN','GIFEN'].includes(CONFIG.VERTICAL) ? (
          <div className="grid max-w-lg grid-cols-3 gap-4 pt-4">
            <span className="col-span-1 text-secondary">{t([`${vertical}:informations.items.team_name`,'informations.items.team_name'])}</span>
            <p className="col-span-2 truncate" style={{ maxWidth: '200px' }}>
              {facility.name}
            </p>
            <span className="col-span-1 text-secondary">{t('informations.items.city')}</span>
            <span className="col-span-2">{facility.address.city}</span>
            <span className="col-span-1 text-secondary">{t('informations.items.postal')}</span>
            <span className="col-span-2">{facility.address.zipCode}</span>
            <span className="col-span-1 text-secondary">{t(`informations.items.country`)}</span>
            <span className="col-span-2">{facility.address.country}</span>
          </div>
        ) : (
          <div className="grid max-w-lg grid-cols-3 gap-4 pt-4 pb-8">
            <span className="col-span-1 text-secondary">
              {t([
                `${vertical}:facility.informations.items.property`,
                'informations.items.property',
              ])}
            </span>
            <p className="col-span-2 truncate" style={{ maxWidth: '200px' }}>
              {facility.name}
            </p>

            <span className="col-span-1 text-secondary">{t('informations.items.address')}</span>
            {facility.address ? (
              <span className="col-span-2">
                {facility.address.line1}
                {facility.address.line2 && (
                  <>
                    <br />
                    {facility.address.line2}
                  </>
                )}
                <br />
                <span>{`${facility.address.zipCode}, ${facility.address.city} `}</span>
                {facility.address.country && (
                  <span className="uppercase">{facility.address.country}</span>
                )}
              </span>
            ) : (
              <span className="col-span-2" />
            )}
            <span className="col-span-1 text-secondary">
              {t(`units.${facility.unit}.quantity`)}
            </span>
            <span className="col-span-2">
              {facility.unit === 'employee' ? facility.employees : facility.rooms}
            </span>
            {CONFIG.VERTICAL === 'DEKRA' && (
              <>
                <span className="col-span-1 text-secondary">
                  {t('informations.titles.product')}
                </span>
                <span className="col-span-2">{config.label}</span>
              </>
            )}
          </div>
        )}
      </div>
      {modalOpen && (
        <ModalInformations
          open={modalOpen}
          requestClose={() => setModalOpen(false)}
          facilityId={facility.id}
          unit={facility.unit}
          initialValues={{
            facilityName: facility.name,
            facilityRooms: `${facility.rooms}`,
            facilityEmployees: `${facility.employees}`,
            facilityAddress: facility.address.line1,
            facilityAdditionalAddress: facility.address.line2,
            facilityCity: facility.address.city,
            facilityPostalCode: facility.address.zipCode,
            facilityCountry: facility.address.country,
            facilityState: facility.address.state,
            facilityTimezone: facility.timezone,
          }}
          mutate={mutate}
        />
      )}
      {modalDeactivate && (
        <ModalDeactivate
          open={modalDeactivate}
          requestClose={() => setModalDeactivate(false)}
          confirmAction={handleDeactivate}
          mutate={mutate}
        />
      )}
    </>
  );
};

Informations.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string,
      zipCode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      state: PropTypes.string,
    }).isRequired,
    rooms: PropTypes.number.isRequired,
    employees: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    contexts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    context: PropTypes.string.isRequired,
    statusChatbot: PropTypes.string.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default Informations;

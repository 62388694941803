const formatValues = (values, scope) => {
  const data = {
    type: 'BU',
    scope,
    name: values.subclientName,

    drmUser: {
      firstName: values.manager.firstName,
      lastName: values.manager.lastName,
      email: values.manager.email,
      phone: { number: values.manager.phone },
    },
  };

  return data;
};

export default formatValues;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CONFIG from 'src/config';
import { Page, Snackbar } from 'src/components';
import { PropertyRessources, PropertyInformations, AssociateBU } from './components';

const { VERTICAL } = CONFIG;

const Facility = ({ facility, mutate }) => {
  const [snackbarState, setSnackbarState] = useState({
    type: 'HIDDEN',
    msg: '',
    scrollIntoView: false,
  });
  return (
    <Page title={facility.name || 'Facility'}>
      <Page.Main className="pt-16">
        <div className="flex flex-row">
          <div className="flex flex-col">
            <Snackbar
              type={snackbarState.type}
              message={snackbarState.msg}
              requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
              snackbarState={snackbarState}
              setSnackbarState={setSnackbarState}
            />
            {/* {VERTICAL !== 'GREENSHIELD' && VERTICAL !== 'EQUILIBRE' && (
              <PropertyBadge
                id={facility.id}
                externalId={facility.externalId}
                dashboardId={facility.dashboardId}
              />
            )} */}
            <PropertyInformations facility={facility} mutate={mutate} />
            {['VULCAIN', 'GIFEN', 'EQUILIBRE'].includes(VERTICAL) && (
              <AssociateBU
                facility={facility}
                setSnackbarState={setSnackbarState}
                associateBU={facility.subclientId}
                facilityMutate={mutate}
              />
            )}
          </div>
          <div>
            {!['VULCAIN', 'DEKRA', 'GIFEN'].includes(VERTICAL) && (
                <PropertyRessources 
                  facilityId={facility.id}
                  shortName={facility.shortName}
                />
              )}
          </div>
        </div>
      </Page.Main>
    </Page>
  );
};

Facility.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortName: PropTypes.string,
    employees: PropTypes.number.isRequired,
    rooms: PropTypes.number.isRequired.isRequired,
    unit: PropTypes.string.isRequired,
    subclientId: PropTypes.number,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
    }),
    drmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
    gmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    prUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    status: PropTypes.oneOf(['PENDING', 'VERIFIED', 'UNVERIFIED', 'REVOKED']),
    timezone: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
  }),
  mutate: PropTypes.func.isRequired,
};

Facility.defaultProps = {
  facility: {
    status: 'PENDING',
    address: {},
    gmUser: null,
    prUser: null,
    subclientId: null,
    shortName: '123456'
  },
};

export default Facility;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const plusImage = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.31339 1.62251C7.34568 1.00902 7.8623 0.492403 8.47578 0.460114C9.12156 0.460114 9.63818 0.976733 9.63818 1.62251L9.67046 7.33761L15.3533 7.33761C15.999 7.33761 16.5157 7.85423 16.5157 8.5C16.5157 9.14577 15.999 9.66239 15.3533 9.66239L9.67046 9.66239L9.63818 15.3775C9.63818 16.0233 9.12156 16.5399 8.47578 16.5399C7.8623 16.5076 7.34568 15.991 7.31339 15.3775L7.34568 9.66239H1.59829C0.984805 9.6301 0.468186 9.11349 0.435897 8.5C0.468186 7.88651 0.984805 7.3699 1.59829 7.33761H7.34568L7.31339 1.62251Z"
      fill="#0077CC"
    />
  </svg>
);

const SubclientList = ({
  SubclientData,
  selectedSubclient,
  setSelectedSubclient,
  handleAddSubclient,
}) => {
  const { t } = useTranslation(process.env.GATSBY_VERTICAL.toLowerCase(), 'dashboard');
  const user = useSelector((state) => state.user);

  return (
    <div className="flex flex-col gap-1 pt-8 pr-4 ">
      {SubclientData.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
        const { name, OUId } = item;
        return (
          <button
            key={OUId}
            type="button"
            onClick={() => setSelectedSubclient(item)}
            className={`px-2 w-56 py-2 text-sm text-left transition rounded-md hover:bg-gray-300 truncate ${
              selectedSubclient?.OUId === OUId ? 'bg-gray-200 font-semibold' : ''
            }`}
          >
            {name}
          </button>
        );
      })}
      {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
        <button
          type="button"
          onClick={handleAddSubclient}
          className="flex flex-row items-center w-56 px-2 py-1 mt-5 space-x-2 text-sm text-left text-blue-500 transition rounded-md hover:bg-blue-100"
        >
          <div className="p-2 bg-blue-100">{plusImage()}</div>
          <div>{t(['organizations.button.new_subclient','dashboard:organizations.button.new_subclient'])}</div>
        </button>
      )}
    </div>
  );
};

SubclientList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  SubclientData: PropTypes.arrayOf(PropTypes.object),
  selectedSubclient: PropTypes.shape({ OUId: PropTypes.number }),
  setSelectedSubclient: PropTypes.func,
  handleAddSubclient: PropTypes.func,
};

SubclientList.defaultProps = {
  SubclientData: [],
  selectedSubclient: { OUId: 0 },
  setSelectedSubclient: null,
  handleAddSubclient: null,
};

export default SubclientList;

const { GATSBY_VERTICAL } = process.env;

const formatValues = (values) => {
  const data = {
    protocolUrl: null,
    context: values.facilityIndustry,
    name: values.facilityName,
    product: values.facilityProduct,
    websiteUrl: values.facilityWebsiteUrl,
    capacityRooms: values.facilityRooms || 1,
    capacityEmployees: values.facilityEmployees || 1,
    timezone: values.facilityTimezone,
    address: {
      line1: values.facilityAddress,
      line2: values.facilityAdditionalAddress,
      city: values.facilityCity,
      zipCode: values.facilityPostalCode,
      country: values.facilityCountry,
      state: values.facilityState,
    },
    drmUser: {
      firstName: values.manager.firstName,
      lastName: values.manager.lastName,
      email: values.manager.email,
      title: values.manager.title,
      phone: {
        number: values.manager.phone,
      },
    },
  };

  if (GATSBY_VERTICAL !== 'FTG') return data;

  const billingInformation = {
    currency: values.preferedCurrency,
    billingAddressee: values.billingAddressee,
    billingAttention: values.billingAttention,
    billingPhoneId: values.billingAddresseePhone,
    phone: { number: values.billingAddresseePhone },
    email: values.contactEmail,
    billingUseFacilityAddress: values.billingUseFacilityAddress,
    shippingUseBillingInformation: values.shippingUseBillingAddress,
  };

  if (!values.billingUseFacilityAddress) {
    billingInformation.billingAddress = {
      line1: values.billingAddress,
      line2: values.billingAdditionalAddress,
      city: values.billingCity,
      zipCode: values.billingPostalCode,
      country: values.billingCountry,
      state: values.billingState,
    };
    billingInformation.billingPhone = {
      number: values.billingAddresseePhone,
    };
  }

  if (!values.shippingUseBillingAddress) {
    billingInformation.shippingAddress = {
      line1: values.shippingAddress,
      line2: values.shippingAdditionalAddress,
      city: values.shippingCity,
      zipCode: values.shippingPostalCode,
      country: values.shippingCountry,
      state: values.shippingState,
    };
    billingInformation.shippingPhone = {
      number: values.shippingAddresseePhone,
    };
    billingInformation.shippingAddressee = values.shippingAddressee;
    billingInformation.shippingAttention = values.shippingAttention;
  }

  const marketingInformation = values.marketing;

  return { ...data, billingInformation, marketingInformation };
};

export default formatValues;

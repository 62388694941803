import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import './assessment.scss';
import { Page } from 'src/components';

const Assessment = ({ facility }) => {
  const token = localStorage.getItem('auth')

  const configLinks = useSelector((state) => state.configLink)
  const { botLink } = configLinks

  const iframe = useRef(null)

  return (
    <Page title={`Assessment ${facility.name}` || 'Assessment'}>
      <Page.Main className="pt-16">
        {
          botLink &&
          <iframe
            id="iframe"
            ref={iframe}
            title="Bot"
            src={`${botLink}?framed=1&token=${encodeURIComponent(token)}&pid=${facility.externalId}`}
            className="w-full framed-bot"
          />
        }
      </Page.Main>
    </Page>
  )
};

Assessment.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired
  }).isRequired,
};

export default Assessment;

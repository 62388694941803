import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ShipCardItem = ({ id, name }) => (
  <Link
    to={`/dashboard/ships/${id}`}
    className="flex items-center p-4 transition bg-gray-100 border-2 border-gray-200 rounded-lg select-none lg:flex-col hover:shadow-md focus:shadow-md focus:outline-none"
  >
    <svg viewBox="0 0 70 70" className="flex-shrink-0 w-20 h-20 fill-current text-secondary">
      <path d="M34.1388 40.9258H5.25L10.25 66.4813L34.1388 69.9998L58.0276 66.4813L63.0276 40.9258H34.1388Z" />
      <path d="M60.7125 19.4444H51.9162V14.7222H40.3421L38.6755 2.49999H40.157V0H34.6014H33.4903H27.9348V2.49999H29.4162L27.7496 14.7222H16.3607V19.4444H7.56445V39.1666H60.7125V19.4444ZM19.4163 31.111H13.3978V25.0925H19.4163V31.111ZM28.3051 31.111H22.2866V25.0925H28.3051V31.111ZM37.1014 31.111H31.0829V25.0925H37.1014V31.111ZM45.9903 31.111H39.9718V25.0925H45.9903V31.111ZM54.8791 31.111H48.8606V25.0925H54.8791V31.111Z" />
    </svg>
    <div className="pl-8 text-lg lg:pt-8 lg:pl-0 lg:w-full">
      <p className="font-medium text-center truncate card-add-facility-name">{name}</p>
    </div>
  </Link>
);

ShipCardItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default ShipCardItem;

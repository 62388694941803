import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { httpClient } from 'src/helpers';
import { Modal, InputField, Snackbar, Button } from 'src/components';
import countries from 'src/constants/countries.json';
import states from 'src/constants/states.json';
import { useSelector } from 'react-redux';

const editSchema = Yup.object().shape({
  contactEmail: Yup.string()
    .email('forms.format')
    .max(250, 'forms.too_long')
    .required('forms.required'),
  billingUseFacilityAddress: Yup.boolean().default(true).required(),
  billingAddressee: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  billingAttention: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  billingAddresseePhone: Yup.string()
    .max(250, 'forms.too_long')
    .matches(
      /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/,
      'forms.format'
    )
    .required('forms.required'),
  billingAddress: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  billingAdditionalAddress: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long'),
  }),
  billingCity: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  billingPostalCode: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  billingCountry: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  billingState: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().when('billingCountry', {
      is: (val) => val === 'US' || val === 'CA',
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
  }),
  shippingUseBillingAddress: Yup.boolean().default(true).required(),
  shippingAddressee: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingAttention: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingAddresseePhone: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string()
      .max(250, 'forms.too_long')
      .matches(
        /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[ ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,4})?$/,
        'forms.format'
      )
      .required('forms.required'),
  }),
  shippingAddress: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingAdditionalAddress: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long'),
  }),
  shippingCity: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingPostalCode: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingCountry: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingState: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().when('shippingCountry', {
      is: (val) => val === 'US' || val === 'CA',
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
  }),
});

const formatValues = (values) => {
  const billingInformation = {
    billingAddressee: values.billingAddressee,
    billingAttention: values.billingAttention,
    billingPhoneId: values.billingAddresseePhone,
    phone: { number: values.billingAddresseePhone },
    email: values.contactEmail,
    billingUseFacilityAddress: values.billingUseFacilityAddress,
    shippingUseBillingInformation: values.shippingUseBillingAddress,
  };

  if (!values.billingUseFacilityAddress) {
    billingInformation.billingAddress = {
      line1: values.billingAddress,
      line2: values.billingAdditionalAddress,
      city: values.billingCity,
      zipCode: values.billingPostalCode,
      country: values.billingCountry,
      state: values.billingState,
    };
    billingInformation.billingPhone = {
      number: values.billingAddresseePhone,
    };
  }

  if (!values.shippingUseBillingAddress) {
    billingInformation.shippingAddress = {
      line1: values.shippingAddress,
      line2: values.shippingAdditionalAddress,
      city: values.shippingCity,
      zipCode: values.shippingPostalCode,
      country: values.shippingCountry,
      state: values.shippingState,
    };
    billingInformation.shippingPhone = {
      number: values.shippingAddresseePhone,
    };
    billingInformation.shippingAddressee = values.shippingAddressee;
    billingInformation.shippingAttention = values.shippingAttention;
  }

  return billingInformation;
};

const ModalInformations = ({ open, requestClose, mutate, facilityId, initialValues }) => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation('dashboard');
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const handleSubmit = async (values, actions) => {
    if (window.gtag) window.gtag('event', 'Edit_billing_information');
    setSnackbarState({ type: 'LOADING' });
    actions.setSubmitting(true);

    const formatedValues = formatValues(values);
    formatedValues.clientId = user.clientId;

    try {
      document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });
      const { status } = await httpClient.put(`/facilities/${facilityId}/billing`, formatedValues);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();
      } else {
        throw Error('Facility creation failed.');
      }
    } catch (error) {
      console.log(error);
      setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  useEffect(
    () => () => {
      setSnackbarState({ type: 'HIDDEN', msg: '' });
    },
    []
  );

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <div className="h-5" />
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        <Formik initialValues={initialValues} validationSchema={editSchema} onSubmit={handleSubmit}>
          {({ isValid, isSubmitting, dirty, values }) => (
            <Form className="max-w-md mx-auto">
              <Snackbar
                type={snackbarState.type}
                message={snackbarState.msg}
                requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
              />
              <div>
                <div className="pt-16 space-y-6">
                  <span className="text-2xl font-bold">
                    {t(`dashboard:forms.contact.titles.billing`)}
                  </span>
                  <InputField
                    name="billingAttention"
                    label="forms.contact.attention"
                    namespaces={['dashboard']}
                  />
                  <InputField
                    name="billingAddressee"
                    label="forms.contact.addressee"
                    namespaces={['dashboard']}
                  />
                  <InputField
                    name="billingAddresseePhone"
                    label="forms.contact.addresseePhone"
                    namespaces={['dashboard']}
                  />
                  <InputField
                    name="billingUseFacilityAddress"
                    label="forms.contact.same.billing"
                    namespaces={['dashboard']}
                    type="checkbox"
                  />
                  {!values.billingUseFacilityAddress && (
                    <>
                      <InputField
                        name="billingAddress"
                        label="forms.address.address"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="billingAdditionalAddress"
                        label="forms.address.additionalAddress"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="billingCity"
                        label="forms.address.city"
                        autoComplete="city"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="billingPostalCode"
                        label="forms.address.postal"
                        autoComplete="zip"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        type="select"
                        name="billingCountry"
                        label="forms.address.country"
                        namespaces={['dashboard']}
                        options={countries
                          .map((country) => ({
                            ...country,
                            label: t(`countries:${country.label}`),
                          }))
                          .sort((a, b) =>
                            t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`))
                          )}
                      />
                      {(values.billingCountry === 'CA' || values.billingCountry === 'US') &&
                        states[values.billingCountry] && (
                          <InputField
                            type="select"
                            name="billingState"
                            label="forms.address.state"
                            namespaces={['dashboard']}
                            options={states[values.billingCountry].map((state) => ({
                              ...state,
                              label: t(`states:${values.billingCountry}.${state.label}`),
                            }))}
                          />
                        )}
                    </>
                  )}
                </div>
                <div className="pt-16 space-y-6">
                  <span className="text-2xl font-bold">
                    {t(`dashboard:forms.contact.titles.shipping`)}
                  </span>
                  <InputField
                    type="checkbox"
                    name="shippingUseBillingAddress"
                    label="forms.contact.same.shipping"
                    namespaces={['dashboard']}
                  />
                  {!values.shippingUseBillingAddress && (
                    <>
                      <InputField
                        name="shippingAddressee"
                        label="forms.contact.addressee"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="shippingAttention"
                        label="forms.contact.attention"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="shippingAddresseePhone"
                        label="forms.contact.addresseePhone"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="shippingAddress"
                        label="forms.address.address"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="shippingAdditionalAddress"
                        label="forms.address.additionalAddress"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="shippingCity"
                        label="forms.address.city"
                        autoComplete="city"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        name="shippingPostalCode"
                        label="forms.address.postal"
                        autoComplete="zip"
                        namespaces={['dashboard']}
                      />
                      <InputField
                        type="select"
                        name="shippingCountry"
                        label="forms.address.country"
                        namespaces={['dashboard']}
                        options={countries
                          .map((country) => ({
                            ...country,
                            label: t(`countries:${country.label}`),
                          }))
                          .sort((a, b) =>
                            t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`))
                          )}
                      />
                      {(values.shippingCountry === 'CA' || values.shippingCountry === 'US') &&
                        states[values.shippingCountry] && (
                          <InputField
                            type="select"
                            name="shippingState"
                            label="forms.address.state"
                            namespaces={['dashboard']}
                            options={states[values.shippingCountry].map((state) => ({
                              ...state,
                              label: t(`states:${values.shippingCountry}.${state.label}`),
                            }))}
                          />
                        )}
                    </>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-center pt-12 pb-6">
                <Button type="submit" size="md" disabled={!(isValid && dirty) || isSubmitting}>
                  {t('forms.buttons.confirm')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ModalInformations.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  facilityId: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    facilityName: PropTypes.string.isRequired,
    contactEmail: PropTypes.string.isRequired,
    contactPhone: PropTypes.string.isRequired,
    billingAddressee: PropTypes.string.isRequired,
    billingAttention: PropTypes.string.isRequired,
    billingAddresseePhone: PropTypes.string.isRequired,
    billingUseFacilityAddress: PropTypes.bool.isRequired,
    billingAddress: PropTypes.string,
    billingAdditionalAddress: PropTypes.string,
    billingCity: PropTypes.string,
    billingPostalCode: PropTypes.string,
    billingCountry: PropTypes.string,
    billingState: PropTypes.string,
    shippingUseBillingAddress: PropTypes.bool.isRequired,
    shippingAddressee: PropTypes.string,
    shippingAttention: PropTypes.string,
    shippingAddresseePhone: PropTypes.string,
    shippingAddress: PropTypes.string,
    shippingAdditionalAddress: PropTypes.string,
    shippingCity: PropTypes.string,
    shippingPostalCode: PropTypes.string,
    shippingCountry: PropTypes.string,
    shippingState: PropTypes.string,
  }).isRequired,
};

export default ModalInformations;

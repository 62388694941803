import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CONFIG from 'src/config';
import { InputField } from 'src/components';
import UserPicker from './UserPicker';

const AddEditBUForm = ({ users }) => {
  const { t } = useTranslation([CONFIG.VERTICAL.toLowerCase(), 'dashboard']);
  const [selectableUsers] = useState([...users].sort((a, b) => a.email.localeCompare(b.email)));

  return (
    <div className="max-w-md pt-6 pb-8 space-y-6">
      <InputField
        name="subclientName"
        label={['forms.subclient.name','dashboard:forms.subclient.name']}
        namespaces={[CONFIG.VERTICAL.toLowerCase(),'dashboard']}
        autoComplete="ada"
      />
      <div className="pt-6">
        <span className="text-lg font-bold">
          {t(['forms.subclient.select_manager', 'dashboard:forms.subclient.select_manager'])}
        </span>
        <p className="pt-1 pb-6 text-sm text-gray-500">
          {t([
            'forms.subclient.select_manager_tagline',
            'dashboard:forms.subclient.select_manager_tagline',
          ])}
        </p>
        <UserPicker name="manager" options={selectableUsers} removable={false} />
      </div>
    </div>
  );
};

AddEditBUForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default AddEditBUForm;

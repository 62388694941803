import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CONFIG from 'src/config';
import UserPicker from './UserPicker';

const ManagerForm = ({ users }) => {
  const { t } = useTranslation(CONFIG.VERTICAL.toLowerCase());
  const [selectableUsers, setSelectableUsers] = useState(
    [...users].sort((a, b) => a.email.localeCompare(b.email))
  );

  const resetUsers = (userId) => {
    setSelectableUsers(
      users.filter((s) => s.id !== userId).sort((a, b) => a.email.localeCompare(b.email))
    );
  };

  return (
    <div className="pb-24 space-y-6">
      <div>
        <div className="sm:w-2/3">
          <span className="text-xl font-bold">
            {t([
              'forms.manager.titles.introduction',
              'dashboard:forms.manager.titles.introduction',
            ])}
          </span>
          <p className="pt-1 text-sm text-gray-500">
            {t(['forms.manager.titles.description', 'dashboard:forms.manager.titles.description'])}
          </p>
        </div>
      </div>
      <div className="sm:w-2/3">
        <UserPicker
          name="manager"
          options={selectableUsers}
          addUserWithId={resetUsers}
          removeUserWithId={resetUsers}
        />
      </div>
    </div>
  );
};

ManagerForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default ManagerForm;

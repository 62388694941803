import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { fetchOnce } from 'src/helpers';

import CONFIG from 'src/config';
import { Page, Pagination, Spinner, Searcher } from 'src/components';
import { CardList, CardItem, ModalNewTeam } from 'src/views/app/shared';
import { ModalAdd } from './components';
import ModalAddMultistep from './components/modal-add-withMultistep/ModalAddMultistep';

const Facilities = () => {
  const { t } = useTranslation(CONFIG.VERTICAL.toLowerCase());
  const user = useSelector((state) => state.user);

  const [search, setSearch] = useState('');

  // Vulcain modal
  const [modalNewTeamOpen, setModalNewTeamOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [facilitiesData, setFacilitiesData] = useState(undefined)

  // When the api resolves, set this to true if the number of facilities is > 0. Then KEEP it to true
  const [facilitiesHaveEverBeenFound, setFacilitiesHaveEverBeenFound] = useState(false);

  const [newFacilityModalOpen, setNewFacilityModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 12;
  const {
    data: rawFacilitiesData,
    error: errorFacilities,
    mutate: mutateFacilities,
  } = useSWR(
    user.clientId
      ? [
        `/facilities?clientId=${user.clientId}&pages=true&page=${search ? 1 : page
        }&size=${pageSize}${search ? `&search=${encodeURIComponent(search)}` : ''}`,
      ]
      : null
  );
  const { data: users, error: errorUsers } = useSWR(
    user.clientId ? [`/users?clientId=${user.clientId}`] : null,
    fetchOnce
  );
  const { data: products, error: errorProducts } = useSWR(
    user.endorser ? [`/products/${user.endorser}`] : null,
    fetchOnce
  );
  const { data: config, error: errorConfig } = useSWR('/config/links', fetchOnce);

  useEffect(() => {
    const data = {...rawFacilitiesData};
    if( showActive && data.facilities ) {
      data.facilities = data.facilities.filter((e) => (e.statusChatbot || '').toUpperCase() !== 'DELETED')
    }
    setFacilitiesData(data)},
    [rawFacilitiesData, showActive]
  );

  // MULTISTEP ADD FACILITY
  const { GATSBY_VERTICAL } = process.env;

  useEffect(() => {
    if (rawFacilitiesData && rawFacilitiesData.facilities.length > 0) {
      setFacilitiesHaveEverBeenFound(true);
    }
  }, [rawFacilitiesData]);

  if (errorFacilities || errorProducts || errorUsers || errorConfig) {
    return (
      <Page title="Dashboard">
        <Page.Main className="container pt-12">
          <div>An error has occurred.</div>
        </Page.Main>
      </Page>
    );
  }

  const triggerHandler = () => {
    if (!['VULCAIN', 'GIFEN', 'EQUILIBRE'].includes(GATSBY_VERTICAL)) {
      setNewFacilityModalOpen(true);
    } else {
      setModalNewTeamOpen(true);
    }
  };

  const NewTeamBtn = ({ type }) => {
    if ((user.role !== 'ADMIN' && user.role !== 'SUPERADMIN') || GATSBY_VERTICAL === 'VACCINE')
      return null;
    return (
      <button
        type="button"
        onClick={triggerHandler}
        className="w-full px-8 py-2 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 lg:w-max"
        disabled={!facilitiesData || !products || !config.contexts}
      >
        {type === 'new'
          ? t(['buttons.new_facility', 'dashboard:buttons.new_facility'])
          : t(['buttons.first_facility', 'dashboard:buttons.first_facility'])}
      </button>
    );
  };

  NewTeamBtn.propTypes = {
    type: PropTypes.string.isRequired,
  };

  return (
    <Page title="Dashboard">
      <Page.Main className="container pt-8 pb-12">
        <div>
          {facilitiesHaveEverBeenFound && (
            <div className="flex flex-row flex-wrap items-center pb-8 space-y-8 lg:space-y-0">
              <NewTeamBtn type="new" />
              <div className="flex-grow lg:w-60 lg:ml-4 lg:flex-grow-0">
                <Searcher
                  search={search}
                  setSearch={setSearch}
                  setPage={setPage}
                  placeholder={t(['buttons.search_facility', 'dashboard:buttons.search_facility'])}
                />
              </div>
              {search && facilitiesData && facilitiesData.facilities && (
                <span className="ml-4 text-sm text-secondary whitespace-nowrap">
                  {`${facilitiesData.facilities.length === 1
                      ? `${facilitiesData.facilities.length} ${t([
                        'users.result',
                        'dashboard:users.result',
                      ])}`
                      : `${facilitiesData.facilities.length} ${t([
                        'users.results',
                        'dashboard:users.results',
                      ])}`
                    }`}
                </span>
              )}
              <div className="flex flex-col md:items-center md:flex-row md:space-x-4 lg:ml-4">

                <div>
                  <label id="inactive-only" htmlFor="inactive" className="flex items-center">
                    <input
                      type="checkbox"
                      aria-labelledby="inactive-only"
                      onChange={() => setShowActive(!showActive)}
                      value={showActive}
                      checked={showActive}
                      className="flex-shrink-0 w-6 h-6 border border-gray-300 rounded appearance-none form-tick checked:bg-primary-light checked:border-transparent focus:outline-none disabled:opacity-50 disabled:text-black disabled:text-opacity-50"
                      style={{
                        backgroundImage:
                          "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e\")",
                      }}
                    />
                    <span className="pl-3 text-sm text-secondary">{t(['forms.team.show_active','dashboard:forms.team.show_active'])}</span>
                  </label>
                </div>

              </div>
            </div>
          )}
          <div>
            {(!facilitiesData || !products || !config.contexts) && (
              <Page title="Dashboard">
                <Page.Main className="container pt-12">
                  <div className="flex justify-center text-primary-light">
                    <Spinner />
                  </div>
                </Page.Main>
              </Page>
            )}
            {facilitiesData && products && config.contexts && (
              <div>
                {facilitiesData && facilitiesData.facilities && facilitiesData.facilities.length <= 0 && (
                  <div>
                    {facilitiesHaveEverBeenFound && (
                      <div className="flex flex-col items-center gap-6 pt-28">
                        <span className="text-sm font-bold">
                          {t(['users.no_results', 'dashboard:users.no_results'])}
                        </span>
                        <svg
                          width="255"
                          height="200"
                          viewBox="0 0 255 200"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_8370_22481)">
                            <path
                              d="M230.628 0.130859H0.0986328V147.772H230.628V0.130859Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M224.036 18.6484H6.69043V139.037H224.036V18.6484Z"
                              fill="white"
                            />
                            <path d="M230.529 0H0V9.79374H230.529V0Z" fill="#00BFA5" />
                            <path
                              d="M7.27808 6.82179C8.28059 6.82179 9.09328 6.0091 9.09328 5.0066C9.09328 4.0041 8.28059 3.19141 7.27808 3.19141C6.27558 3.19141 5.46289 4.0041 5.46289 5.0066C5.46289 6.0091 6.27558 6.82179 7.27808 6.82179Z"
                              fill="white"
                            />
                            <path
                              d="M14.1677 6.82179C15.1702 6.82179 15.9829 6.0091 15.9829 5.0066C15.9829 4.0041 15.1702 3.19141 14.1677 3.19141C13.1652 3.19141 12.3525 4.0041 12.3525 5.0066C12.3525 6.0091 13.1652 6.82179 14.1677 6.82179Z"
                              fill="white"
                            />
                            <path
                              d="M21.0584 6.82179C22.0609 6.82179 22.8736 6.0091 22.8736 5.0066C22.8736 4.0041 22.0609 3.19141 21.0584 3.19141C20.0559 3.19141 19.2432 4.0041 19.2432 5.0066C19.2432 6.0091 20.0559 6.82179 21.0584 6.82179Z"
                              fill="white"
                            />
                            <path
                              d="M82.287 31.1396H26.4395V126.546H82.287V31.1396Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M138.466 31.1396H91.9268V57.4013H138.466V31.1396Z"
                              fill="#00BFA5"
                            />
                            <path
                              d="M138.466 64.7148H91.9268V92.9711H138.466V64.7148Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M138.466 100.284H91.9268V126.546H138.466V100.284Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M204.287 31.1396H148.439V72.7172H204.287V31.1396Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M204.287 84.9688H148.439V126.546H204.287V84.9688Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M182.772 75.7042V66.7532C182.772 63.5721 184.036 60.5213 186.286 58.2719C188.535 56.0225 191.586 54.7588 194.767 54.7588C197.948 54.7588 200.999 56.0225 203.248 58.2719C205.498 60.5213 206.761 63.5721 206.761 66.7532V75.7042C206.761 76.1314 206.591 76.5409 206.289 76.843C205.987 77.145 205.577 77.315 205.15 77.3154H184.384C183.956 77.315 183.547 77.145 183.245 76.843C182.943 76.5409 182.773 76.1314 182.772 75.7042Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M212.503 192.191L208.272 193.362L201.743 177.602L207.987 175.874L212.503 192.191Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M204.916 192.99L213.075 190.732L214.497 195.87L201.201 199.549C201.014 198.875 200.962 198.17 201.048 197.475C201.134 196.78 201.355 196.109 201.7 195.5C202.045 194.891 202.507 194.356 203.059 193.926C203.61 193.495 204.242 193.177 204.916 192.99Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M168.397 195.446H164.007L161.919 178.515H168.398L168.397 195.446Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M160.872 194.191H169.338V199.522H155.542C155.542 198.822 155.68 198.128 155.948 197.482C156.216 196.835 156.608 196.248 157.103 195.753C157.598 195.258 158.186 194.865 158.832 194.597C159.479 194.329 160.172 194.191 160.872 194.191Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M163.104 100.824C163.417 101.247 163.82 101.596 164.282 101.846C164.745 102.097 165.257 102.243 165.783 102.275C166.308 102.306 166.834 102.222 167.324 102.028C167.813 101.834 168.254 101.535 168.615 101.152L180.284 106.4L179.222 99.8369L168.304 95.8594C167.621 95.295 166.755 95.0008 165.869 95.0326C164.983 95.0644 164.14 95.4199 163.499 96.0318C162.858 96.6437 162.464 97.4695 162.391 98.3527C162.318 99.2359 162.572 100.115 163.104 100.824H163.104Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M187.349 140.294C183.683 140.294 179.888 139.748 176.698 138.123C175.004 137.278 173.503 136.093 172.289 134.641C171.075 133.189 170.173 131.502 169.641 129.686C167.979 124.423 170.075 119.253 172.101 114.253C173.357 111.154 174.543 108.228 174.851 105.343L174.959 104.319C175.438 99.7191 175.852 95.7464 178.144 94.2563C179.332 93.484 180.93 93.4399 183.028 94.1214L202.736 100.524L202.012 137.936L201.892 137.976C201.789 138.01 194.841 140.294 187.349 140.294Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M182.683 82.4746C182.683 82.4746 192.35 79.6102 199.869 80.6844C199.869 80.6844 195.573 104.315 197.005 112.192C198.437 120.069 172.121 115.235 177.492 107.717L179.282 98.7655C179.282 98.7655 175.701 95.1851 178.924 90.8886L182.683 82.4746Z"
                              fill="#00BFA5"
                            />
                            <path
                              d="M177.928 139.585L166.014 137.351L170.173 105.34C170.453 104.446 176.897 84.164 179.823 83.0666C181.948 82.3183 184.121 81.7146 186.327 81.2596L186.751 81.1748L184.362 84.7584L174.851 107.549L177.928 139.585Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M171.72 191.523L156.326 188.958L164.965 153.682L177.82 105.44L177.947 106.297C177.957 106.361 179.158 112.663 196.977 109.867L197.134 109.842L197.177 109.995L218.714 186.092L201.173 189.016L185.175 133.495L171.72 191.523Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M193.057 145.575L193.066 145.304C193.077 145.004 194.136 115.095 193.782 103.067C193.427 90.9978 197.335 80.5459 197.374 80.4417L197.406 80.3578L197.492 80.333C202.548 78.8887 206.934 83.1992 206.977 83.2429L207.039 83.3045L205.599 95.1829L211.763 138.378L193.057 145.575Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M192.303 77.1073C197.159 77.1073 201.097 73.1701 201.097 68.3134C201.097 63.4567 197.159 59.5195 192.303 59.5195C187.446 59.5195 183.509 63.4567 183.509 68.3134C183.509 73.1701 187.446 77.1073 192.303 77.1073Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M179.936 67.4696C179.938 64.954 180.939 62.5423 182.718 60.7636C184.496 58.9848 186.908 57.9843 189.424 57.9814H191.214C193.729 57.9843 196.141 58.9848 197.92 60.7636C199.699 62.5423 200.699 64.954 200.702 67.4696V67.6486H196.919L195.628 64.0353L195.37 67.6486H193.415L192.764 65.8255L192.634 67.6486H179.936V67.4696Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M191.437 78.3226C191.26 78.0821 191.154 77.7963 191.131 77.4982C191.109 77.2 191.171 76.9016 191.311 76.6373C193.208 73.0289 195.865 66.3614 192.339 62.2496L192.085 61.9541H202.321V77.3212L193.023 78.9618C192.929 78.9784 192.833 78.9868 192.738 78.9869C192.484 78.9869 192.234 78.9267 192.008 78.8112C191.782 78.6957 191.586 78.5283 191.437 78.3226Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M150.227 14.8755C143.065 8.83187 134.3 5.00028 125 3.84699C115.7 2.6937 106.265 4.26843 97.8434 8.37956C89.4219 12.4907 82.3768 18.9609 77.5655 27.003C72.7542 35.0451 70.3841 44.3122 70.7436 53.6768C71.1031 63.0413 74.1767 72.0994 79.5904 79.749C85.0041 87.3985 92.5245 93.3096 101.236 96.763C109.948 100.216 119.476 101.063 128.661 99.2002C137.845 97.3372 146.29 92.845 152.968 86.27L212.266 136.306C213.168 137.068 214.337 137.442 215.514 137.343C216.691 137.244 217.781 136.682 218.544 135.78C219.307 134.878 219.68 133.709 219.581 132.532C219.483 131.355 218.921 130.265 218.018 129.502L218.012 129.496L158.713 79.4607C165.602 69.5741 168.55 57.4713 166.98 45.5242C165.411 33.5771 159.436 22.6469 150.227 14.8755ZM146.404 74.6426C141.785 80.1174 135.645 84.0993 128.763 86.085C121.88 88.0706 114.563 87.9706 107.737 85.7978C100.911 83.625 94.883 79.4768 90.4146 73.8779C85.9463 68.279 83.2385 61.4808 82.6338 54.343C82.0291 47.2052 83.5546 40.0483 87.0173 33.7775C90.4801 27.5067 95.7246 22.4035 102.088 19.1133C108.451 15.8231 115.647 14.4936 122.765 15.2931C129.884 16.0925 136.605 18.9849 142.08 23.6045H142.08C145.715 26.6718 148.711 30.425 150.895 34.6499C153.08 38.8749 154.411 43.4887 154.813 48.228C155.214 52.9674 154.678 57.7393 153.236 62.2716C151.793 66.8038 149.472 71.0075 146.404 74.6426Z"
                              fill="#3F3D56"
                            />
                            <path
                              opacity="0.3"
                              d="M95.3659 78.9661C88.4177 73.1027 83.9287 64.8408 82.7901 55.8208C81.6515 46.8008 83.9467 37.6824 89.2199 30.2764C88.5274 30.9738 87.8579 31.7042 87.2115 32.4675C84.1442 36.1027 81.8229 40.3063 80.3802 44.8386C78.9375 49.3708 78.4016 54.1428 78.8031 58.8821C79.2046 63.6215 80.5357 68.2353 82.7203 72.4602C84.9049 76.6851 87.9003 80.4384 91.5354 83.5057C95.1705 86.573 99.3742 88.8942 103.906 90.337C108.439 91.7797 113.211 92.3156 117.95 91.9141C122.689 91.5126 127.303 90.1815 131.528 87.9969C135.753 85.8123 139.506 82.8169 142.574 79.1818C143.218 78.4174 143.826 77.6346 144.396 76.8334C137.982 83.2774 129.38 87.0738 120.297 87.4689C111.214 87.864 102.315 84.8289 95.3659 78.9661Z"
                              fill="black"
                            />
                            <path
                              d="M195.126 116.198C195.637 116.323 196.17 116.334 196.686 116.231C197.202 116.128 197.689 115.913 198.113 115.601C198.537 115.29 198.888 114.889 199.14 114.427C199.393 113.965 199.541 113.453 199.574 112.928L211.903 109.506L206.891 105.137L195.952 109.056C195.066 109.059 194.211 109.386 193.551 109.977C192.89 110.567 192.469 111.38 192.367 112.26C192.265 113.14 192.49 114.027 192.998 114.753C193.507 115.479 194.264 115.993 195.126 116.198Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M200.25 114.386L199.5 107.259L209.798 101.577L203.084 93.4495L204.18 84.3196L206.908 83.1504L206.993 83.2595C208.279 84.9137 219.562 99.4719 219.562 101.272C219.562 103.12 217.403 108.548 214.45 110.025C211.602 111.449 200.905 114.217 200.451 114.334L200.25 114.386Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M253.684 200H117.27C117.175 200 117.084 199.963 117.017 199.895C116.95 199.828 116.912 199.737 116.912 199.642C116.912 199.547 116.95 199.456 117.017 199.389C117.084 199.322 117.175 199.284 117.27 199.284H253.684C253.779 199.284 253.87 199.322 253.937 199.389C254.004 199.456 254.042 199.547 254.042 199.642C254.042 199.737 254.004 199.828 253.937 199.895C253.87 199.963 253.779 200 253.684 200Z"
                              fill="#CCCCCC"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_8370_22481">
                              <rect width="254.042" height="200" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    )}
                    {!facilitiesHaveEverBeenFound && (
                      <div className="flex flex-col items-center gap-10 pt-28">
                        <svg
                          width="255"
                          height="200"
                          viewBox="0 0 255 200"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_8370_22481)">
                            <path
                              d="M230.628 0.130859H0.0986328V147.772H230.628V0.130859Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M224.036 18.6484H6.69043V139.037H224.036V18.6484Z"
                              fill="white"
                            />
                            <path d="M230.529 0H0V9.79374H230.529V0Z" fill="#00BFA5" />
                            <path
                              d="M7.27808 6.82179C8.28059 6.82179 9.09328 6.0091 9.09328 5.0066C9.09328 4.0041 8.28059 3.19141 7.27808 3.19141C6.27558 3.19141 5.46289 4.0041 5.46289 5.0066C5.46289 6.0091 6.27558 6.82179 7.27808 6.82179Z"
                              fill="white"
                            />
                            <path
                              d="M14.1677 6.82179C15.1702 6.82179 15.9829 6.0091 15.9829 5.0066C15.9829 4.0041 15.1702 3.19141 14.1677 3.19141C13.1652 3.19141 12.3525 4.0041 12.3525 5.0066C12.3525 6.0091 13.1652 6.82179 14.1677 6.82179Z"
                              fill="white"
                            />
                            <path
                              d="M21.0584 6.82179C22.0609 6.82179 22.8736 6.0091 22.8736 5.0066C22.8736 4.0041 22.0609 3.19141 21.0584 3.19141C20.0559 3.19141 19.2432 4.0041 19.2432 5.0066C19.2432 6.0091 20.0559 6.82179 21.0584 6.82179Z"
                              fill="white"
                            />
                            <path
                              d="M82.287 31.1396H26.4395V126.546H82.287V31.1396Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M138.466 31.1396H91.9268V57.4013H138.466V31.1396Z"
                              fill="#00BFA5"
                            />
                            <path
                              d="M138.466 64.7148H91.9268V92.9711H138.466V64.7148Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M138.466 100.284H91.9268V126.546H138.466V100.284Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M204.287 31.1396H148.439V72.7172H204.287V31.1396Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M204.287 84.9688H148.439V126.546H204.287V84.9688Z"
                              fill="#E6E6E6"
                            />
                            <path
                              d="M182.772 75.7042V66.7532C182.772 63.5721 184.036 60.5213 186.286 58.2719C188.535 56.0225 191.586 54.7588 194.767 54.7588C197.948 54.7588 200.999 56.0225 203.248 58.2719C205.498 60.5213 206.761 63.5721 206.761 66.7532V75.7042C206.761 76.1314 206.591 76.5409 206.289 76.843C205.987 77.145 205.577 77.315 205.15 77.3154H184.384C183.956 77.315 183.547 77.145 183.245 76.843C182.943 76.5409 182.773 76.1314 182.772 75.7042Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M212.503 192.191L208.272 193.362L201.743 177.602L207.987 175.874L212.503 192.191Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M204.916 192.99L213.075 190.732L214.497 195.87L201.201 199.549C201.014 198.875 200.962 198.17 201.048 197.475C201.134 196.78 201.355 196.109 201.7 195.5C202.045 194.891 202.507 194.356 203.059 193.926C203.61 193.495 204.242 193.177 204.916 192.99Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M168.397 195.446H164.007L161.919 178.515H168.398L168.397 195.446Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M160.872 194.191H169.338V199.522H155.542C155.542 198.822 155.68 198.128 155.948 197.482C156.216 196.835 156.608 196.248 157.103 195.753C157.598 195.258 158.186 194.865 158.832 194.597C159.479 194.329 160.172 194.191 160.872 194.191Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M163.104 100.824C163.417 101.247 163.82 101.596 164.282 101.846C164.745 102.097 165.257 102.243 165.783 102.275C166.308 102.306 166.834 102.222 167.324 102.028C167.813 101.834 168.254 101.535 168.615 101.152L180.284 106.4L179.222 99.8369L168.304 95.8594C167.621 95.295 166.755 95.0008 165.869 95.0326C164.983 95.0644 164.14 95.4199 163.499 96.0318C162.858 96.6437 162.464 97.4695 162.391 98.3527C162.318 99.2359 162.572 100.115 163.104 100.824H163.104Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M187.349 140.294C183.683 140.294 179.888 139.748 176.698 138.123C175.004 137.278 173.503 136.093 172.289 134.641C171.075 133.189 170.173 131.502 169.641 129.686C167.979 124.423 170.075 119.253 172.101 114.253C173.357 111.154 174.543 108.228 174.851 105.343L174.959 104.319C175.438 99.7191 175.852 95.7464 178.144 94.2563C179.332 93.484 180.93 93.4399 183.028 94.1214L202.736 100.524L202.012 137.936L201.892 137.976C201.789 138.01 194.841 140.294 187.349 140.294Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M182.683 82.4746C182.683 82.4746 192.35 79.6102 199.869 80.6844C199.869 80.6844 195.573 104.315 197.005 112.192C198.437 120.069 172.121 115.235 177.492 107.717L179.282 98.7655C179.282 98.7655 175.701 95.1851 178.924 90.8886L182.683 82.4746Z"
                              fill="#00BFA5"
                            />
                            <path
                              d="M177.928 139.585L166.014 137.351L170.173 105.34C170.453 104.446 176.897 84.164 179.823 83.0666C181.948 82.3183 184.121 81.7146 186.327 81.2596L186.751 81.1748L184.362 84.7584L174.851 107.549L177.928 139.585Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M171.72 191.523L156.326 188.958L164.965 153.682L177.82 105.44L177.947 106.297C177.957 106.361 179.158 112.663 196.977 109.867L197.134 109.842L197.177 109.995L218.714 186.092L201.173 189.016L185.175 133.495L171.72 191.523Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M193.057 145.575L193.066 145.304C193.077 145.004 194.136 115.095 193.782 103.067C193.427 90.9978 197.335 80.5459 197.374 80.4417L197.406 80.3578L197.492 80.333C202.548 78.8887 206.934 83.1992 206.977 83.2429L207.039 83.3045L205.599 95.1829L211.763 138.378L193.057 145.575Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M192.303 77.1073C197.159 77.1073 201.097 73.1701 201.097 68.3134C201.097 63.4567 197.159 59.5195 192.303 59.5195C187.446 59.5195 183.509 63.4567 183.509 68.3134C183.509 73.1701 187.446 77.1073 192.303 77.1073Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M179.936 67.4696C179.938 64.954 180.939 62.5423 182.718 60.7636C184.496 58.9848 186.908 57.9843 189.424 57.9814H191.214C193.729 57.9843 196.141 58.9848 197.92 60.7636C199.699 62.5423 200.699 64.954 200.702 67.4696V67.6486H196.919L195.628 64.0353L195.37 67.6486H193.415L192.764 65.8255L192.634 67.6486H179.936V67.4696Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M191.437 78.3226C191.26 78.0821 191.154 77.7963 191.131 77.4982C191.109 77.2 191.171 76.9016 191.311 76.6373C193.208 73.0289 195.865 66.3614 192.339 62.2496L192.085 61.9541H202.321V77.3212L193.023 78.9618C192.929 78.9784 192.833 78.9868 192.738 78.9869C192.484 78.9869 192.234 78.9267 192.008 78.8112C191.782 78.6957 191.586 78.5283 191.437 78.3226Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M150.227 14.8755C143.065 8.83187 134.3 5.00028 125 3.84699C115.7 2.6937 106.265 4.26843 97.8434 8.37956C89.4219 12.4907 82.3768 18.9609 77.5655 27.003C72.7542 35.0451 70.3841 44.3122 70.7436 53.6768C71.1031 63.0413 74.1767 72.0994 79.5904 79.749C85.0041 87.3985 92.5245 93.3096 101.236 96.763C109.948 100.216 119.476 101.063 128.661 99.2002C137.845 97.3372 146.29 92.845 152.968 86.27L212.266 136.306C213.168 137.068 214.337 137.442 215.514 137.343C216.691 137.244 217.781 136.682 218.544 135.78C219.307 134.878 219.68 133.709 219.581 132.532C219.483 131.355 218.921 130.265 218.018 129.502L218.012 129.496L158.713 79.4607C165.602 69.5741 168.55 57.4713 166.98 45.5242C165.411 33.5771 159.436 22.6469 150.227 14.8755ZM146.404 74.6426C141.785 80.1174 135.645 84.0993 128.763 86.085C121.88 88.0706 114.563 87.9706 107.737 85.7978C100.911 83.625 94.883 79.4768 90.4146 73.8779C85.9463 68.279 83.2385 61.4808 82.6338 54.343C82.0291 47.2052 83.5546 40.0483 87.0173 33.7775C90.4801 27.5067 95.7246 22.4035 102.088 19.1133C108.451 15.8231 115.647 14.4936 122.765 15.2931C129.884 16.0925 136.605 18.9849 142.08 23.6045H142.08C145.715 26.6718 148.711 30.425 150.895 34.6499C153.08 38.8749 154.411 43.4887 154.813 48.228C155.214 52.9674 154.678 57.7393 153.236 62.2716C151.793 66.8038 149.472 71.0075 146.404 74.6426Z"
                              fill="#3F3D56"
                            />
                            <path
                              opacity="0.3"
                              d="M95.3659 78.9661C88.4177 73.1027 83.9287 64.8408 82.7901 55.8208C81.6515 46.8008 83.9467 37.6824 89.2199 30.2764C88.5274 30.9738 87.8579 31.7042 87.2115 32.4675C84.1442 36.1027 81.8229 40.3063 80.3802 44.8386C78.9375 49.3708 78.4016 54.1428 78.8031 58.8821C79.2046 63.6215 80.5357 68.2353 82.7203 72.4602C84.9049 76.6851 87.9003 80.4384 91.5354 83.5057C95.1705 86.573 99.3742 88.8942 103.906 90.337C108.439 91.7797 113.211 92.3156 117.95 91.9141C122.689 91.5126 127.303 90.1815 131.528 87.9969C135.753 85.8123 139.506 82.8169 142.574 79.1818C143.218 78.4174 143.826 77.6346 144.396 76.8334C137.982 83.2774 129.38 87.0738 120.297 87.4689C111.214 87.864 102.315 84.8289 95.3659 78.9661Z"
                              fill="black"
                            />
                            <path
                              d="M195.126 116.198C195.637 116.323 196.17 116.334 196.686 116.231C197.202 116.128 197.689 115.913 198.113 115.601C198.537 115.29 198.888 114.889 199.14 114.427C199.393 113.965 199.541 113.453 199.574 112.928L211.903 109.506L206.891 105.137L195.952 109.056C195.066 109.059 194.211 109.386 193.551 109.977C192.89 110.567 192.469 111.38 192.367 112.26C192.265 113.14 192.49 114.027 192.998 114.753C193.507 115.479 194.264 115.993 195.126 116.198Z"
                              fill="#FFB8B8"
                            />
                            <path
                              d="M200.25 114.386L199.5 107.259L209.798 101.577L203.084 93.4495L204.18 84.3196L206.908 83.1504L206.993 83.2595C208.279 84.9137 219.562 99.4719 219.562 101.272C219.562 103.12 217.403 108.548 214.45 110.025C211.602 111.449 200.905 114.217 200.451 114.334L200.25 114.386Z"
                              fill="#2F2E41"
                            />
                            <path
                              d="M253.684 200H117.27C117.175 200 117.084 199.963 117.017 199.895C116.95 199.828 116.912 199.737 116.912 199.642C116.912 199.547 116.95 199.456 117.017 199.389C117.084 199.322 117.175 199.284 117.27 199.284H253.684C253.779 199.284 253.87 199.322 253.937 199.389C254.004 199.456 254.042 199.547 254.042 199.642C254.042 199.737 254.004 199.828 253.937 199.895C253.87 199.963 253.779 200 253.684 200Z"
                              fill="#CCCCCC"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_8370_22481">
                              <rect width="254.042" height="200" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <NewTeamBtn type="first" />
                      </div>
                    )}
                  </div>
                )}
                {facilitiesData && facilitiesData.facilities && facilitiesData.facilities.length > 0 && (
                  <div>
                    <CardList>
                      {facilitiesData.facilities
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((facility) => (
                          <CardItem
                            key={facility.id}
                            id={facility.id}
                            name={facility.name}
                            address={facility.address}
                            logo={facility.logo}
                            status={facility.statusChatbot}
                            badge={facility.badge}
                            context={facility.context}
                            contexts={facility.contexts}
                            config={config}
                            subClientName={facility.subClientName}
                          />
                        ))}
                    </CardList>

                    {facilitiesData.pages > 1 && (
                      <Pagination
                        page={page}
                        setPage={setPage}
                        pages={facilitiesData.pages}
                        result={facilitiesData.result}
                        size={pageSize}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Page.Main>

      {['GREENSHIELD', 'HOSPITALITY', 'DEKRA'].includes(GATSBY_VERTICAL) &&
        products &&
        config.contexts && (
          <ModalAddMultistep
            open={newFacilityModalOpen}
            requestClose={() => setNewFacilityModalOpen(false)}
            users={users}
            products={products.map(({ product: e }) => ({ ...e }))}
            industries={config.contexts}
            mutate={mutateFacilities}
          />
        )}
      {!['GREENSHIELD', 'HOSPITALITY', 'DEKRA'].includes(GATSBY_VERTICAL) &&
        products &&
        config.contexts && (
          <ModalAdd
            open={newFacilityModalOpen}
            requestClose={() => setNewFacilityModalOpen(false)}
            users={users}
            products={products.map(({ product: e }) => ({ ...e }))}
            industries={config.contexts}
            mutate={mutateFacilities}
          />
        )}
      {modalNewTeamOpen && products && config.contexts && (
        <ModalNewTeam
          open={modalNewTeamOpen}
          requestClose={() => setModalNewTeamOpen(false)}
          users={users}
          products={products.map(({ product: e }) => ({ ...e }))}
          industries={config.contexts}
          mutate={mutateFacilities}
        />
      )}
    </Page>
  );
};

export default Facilities;

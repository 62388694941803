import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { InputField, Avatar } from 'src/components';
import { useField } from 'formik';

const filterUsers = (option, input) => {
  if (!input || input === '') return true;
  return (
    option.data.firstName.toLowerCase().includes(input.toLowerCase()) ||
    option.data.lastName.toLowerCase().includes(input.toLowerCase()) ||
    option.data.email.toLocaleLowerCase().includes(input.toLocaleLowerCase())
  );
};

const formatOptionLabel = ({ firstName, lastName, email, me, roles }) => (
  <div className="flex items-center space-x-4">
    <Avatar initials={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`} role={roles[0]} />
    <div className="flex flex-col">
      <span className="text-base capitalize">
        {`${firstName} ${lastName} ${me ? '(You)' : ''}`}
      </span>
      <p className="text-sm truncate text-secondary" style={{ maxWidth: '220px' }}>
        {email}
      </p>
    </div>
  </div>
);

const UserPicker = ({ name, options, addUserWithId, removeUserWithId }) => {
  const [{ value }, , { setValue, setTouched }] = useField(name);

  const toggleSelect = (pick) => {
    addUserWithId(value.id);
    setValue(
      {
        newUser: pick,
        id: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        title: '',
        roles: undefined,
      },
      true
    );
    setTouched(false);
  };

  const handleSelect = (option) => {
    const { newUser, ...values } = option;

    if (values.title === '') values.title = 'Manager';

    setValue({ ...value, ...values }, true);
    removeUserWithId(option.id);
  };

  return (
    <div className="relative p-6 space-y-6 rounded-md bg-secondary-grey-light">
      <div className="flex items-center space-x-4">
        <button
          type="button"
          onClick={() => toggleSelect(false)}
          className={`transition border-b-2 text-sm hover:text-blue-600 focus:text-blue-600 focus:outline-none ${
            !value.newUser ? 'text-blue-500 border-blue-500' : 'text-secondary border-transparent'
          }`}
        >
          Select existing user
        </button>
        <button
          type="button"
          onClick={() => toggleSelect(true)}
          className={`transition border-b-2 text-sm hover:text-blue-600 focus:text-blue-600 focus:outline-none ${
            !value.newUser ? 'text-secondary border-transparent' : 'text-blue-500 border-blue-500'
          }`}
        >
          Create user
        </button>
      </div>
      <div>
        {!value.newUser ? (
          <Select
            classNamePrefix="r-select"
            options={options.map((m) => ({ ...m, value: m.id }))}
            filterOption={filterUsers}
            formatOptionLabel={formatOptionLabel}
            placeholder=""
            closeMenuOnSelect
            onChange={handleSelect}
            value={value?.firstName !== '' ? value : null}
          />
        ) : (
          <div className="space-y-6">
            <div className="grid gap-6 md:grid-cols-2">
              <InputField
                name={`${name}.firstName`}
                label="forms.manager.firstName"
                namespaces={['dashboard']}
              />
              <InputField
                name={`${name}.lastName`}
                label="forms.manager.lastName"
                namespaces={['dashboard']}
              />
            </div>
            <InputField
              name={`${name}.email`}
              label="forms.manager.email"
              namespaces={['dashboard']}
            />
            <InputField
              name={`${name}.title`}
              label="forms.manager.role"
              namespaces={['dashboard']}
            />
            <div className="flex w-1/2" style={{ display: 'none' }}>
              <InputField
                name={`${name}.phone`}
                label="forms.manager.phone_optional"
                namespaces={['dashboard']}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

UserPicker.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  addUserWithId: PropTypes.func.isRequired,
  removeUserWithId: PropTypes.func.isRequired,
};

export default UserPicker;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CONFIG from 'src/config';
import UserPicker from './UserPicker';

const EditDRMForm = ({ users }) => {
  const { t } = useTranslation([CONFIG.VERTICAL.toLowerCase(), 'dashboard']);
  const [selectableUsers] = useState([...users].sort((a, b) => a.email.localeCompare(b.email)));

  return (
    <div className="max-w-md pb-8">
      <div className="flex flex-col space-y-6">
        <p className="text-sm text-gray-500">
          {t(['facility:forms.modal.change_drm.description'])}
        </p>
        <UserPicker name="manager" options={selectableUsers} removable={false} />
      </div>
    </div>
  );
};

EditDRMForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default EditDRMForm;

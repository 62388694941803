import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Modal, Button } from 'src/components';

const ModalDeactivate = ({ open, requestClose, confirmAction }) => {
    const { t } = useTranslation('facility');

    return (
        <Modal open={open} requestClose={requestClose}>
            <Modal.Header>
                <h4 className="text-xl font-bold lg:text-2xl">
                    {t('forms.modal.deactivate.title')}
                </h4>
                <div className="absolute right-0 pr-6">
                    <button type="button" onClick={requestClose}>
                        <svg viewBox="0 0 20 20" className="w-5 h-5">
                            <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
                        </svg>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className="max-w-md px-6 mx-auto">
                <div className="space-y-6 ">
                    <p>{t('forms.modal.deactivate.description')}</p>
                </div>

                <div className="pt-12">
                    <div className="flex flex-col items-center justify-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                        <div className="w-1/2">
                            <Button
                                onClick={() => { confirmAction(); requestClose() }}
                                size="md"
                                variant="secondary"
                            >
                                {t('forms.modal.deactivate.confirm')}
                            </Button>
                        </div>
                        <div className="w-1/2">
                            <Button
                                onClick={requestClose}
                                type="submit"
                                size="md"
                            >
                                {t('forms.modal.deactivate.cancel')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="pt-12" />
            </Modal.Body>
        </Modal>
    );
};

ModalDeactivate.propTypes = {
    open: PropTypes.bool.isRequired,
    requestClose: PropTypes.func.isRequired,
    confirmAction: PropTypes.func.isRequired
};

export default ModalDeactivate;
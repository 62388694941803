import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

const Error = ({ handleClose }) => {
  const { t } = useTranslation(['dashboard', 'common', 'greenshield']);

  return (
    <div className="flex flex-col p-12">
      <div className="flex justify-end">
        <button type="button" onClick={handleClose}>
          <svg viewBox="0 0 20 20" className="w-5 h-5">
            <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
          </svg>
        </button>
      </div>
      <div className="flex items-center md:space-x-6">
        <div className="hidden w-full md:block">
          <div className="flex items-center justify-center w-full py-24">
            <svg viewBox="0 0 180.000000 161.000000" className="h-56">
              <g transform="translate(0.000000,161.000000) scale(0.100000,-0.100000)">
                <path
                  d="M775 1589 c-276 -43 -513 -225 -619 -477 -43 -101 -59 -187 -59 -307 0 -139 19 -220 82 -350 131 -268 400 -435 702 -436 138 0 230 21 354 81 160 77 282 197 360 355 64 131 79 196 79 350 0 153 -14 217 -79 350 -126 259 -377 423 -673 439 -48 3 -114 1 -147 -5z m254 -194 c210 -50 390 -227 446 -440 19 -76 19 -224 0 -299 -41 -158 -172 -319 -316 -390 -281 -138 -609 -49 -781 213 -118 180 -129 406 -30 604 71 143 229 268 392 311 72 19 209 19 289 1z"
                  fill="#4a4a4a"
                />
                <path
                  d="M605 1131 c-108 -49 -159 -185 -113 -306 35 -92 145 -221 278 -328 88 -70 79 -80 105 119 l17 131 -40 39 -41 39 40 85 40 85 -25 18 c-69 50 -70 52 -58 85 11 31 10 32 -26 42 -56 15 -132 12 -177 -9z"
                  fill="#029099"
                />
                <path
                  d="M976 1136 c-22 -8 -48 -24 -60 -35 l-21 -21 42 -42 41 -41 -31 -64 -32 -64 44 -37 44 -38 -63 -164 c-34 -91 -66 -175 -71 -187 -27 -70 114 18 256 161 121 123 169 211 170 311 0 88 -31 156 -92 199 -35 24 -54 30 -115 33 -48 2 -87 -2 -112 -11z"
                  fill="#029099"
                />
              </g>
            </svg>
          </div>
        </div>

        <div className="flex flex-col w-full space-y-6">
          <h4 className="font-bold">{t('greenshield:forms.failure.title')}</h4>
          <p className="pt-2">
            <Trans
              t={t}
              i18nKey="greenshield:forms.failure.text"
              components={{ br: <br />, strong: <strong /> }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

Error.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default Error;

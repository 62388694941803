import React from 'react';
import { Modal } from 'src/components';
import PropTypes from 'prop-types';

const ModalInvite = ({ opened, setOpened, error }) => (
    <Modal open={opened} requestClose={() => {}}>
        <Modal.Header>
            <h4 className="text-lg font-bold">{error ? 'Une erreur est survenue' : 'Invitations envoyées'}</h4>
            <div className="absolute right-0 pr-6">
                <button type="button" onClick={() => setOpened(false)}>
                    <svg viewBox="0 0 20 20" className="w-5 h-5">
                        <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
                    </svg>
                </button>
            </div>
        </Modal.Header>
        <Modal.Body className="px-6">
            <div className="text-center mb-5">
                {error || 'Les utilisateurs invités recevront un email leur permettant de se connecter à la plateforme'}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="block m-auto w-5" onClick={() => setOpened(false)}>OK</button>
        </Modal.Footer>
    </Modal>
);

ModalInvite.propTypes = {
    opened: PropTypes.bool.isRequired,
    setOpened: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired
};


export default ModalInvite;

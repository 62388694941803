import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Page } from 'src/components';
import { Address, ModalAddress } from './components';

const Billing = ({ facility }) => {
  const { data, error, mutate } = useSWR(
    facility.id ? [`/facilities/${facility.id}/billing`] : null
  );
  const { t } = useTranslation('facility');
  const [modalOpen, setModalOpen] = useState(false);

  if (error || !data) {
    return (
      <Page title="Facility">
        <Page.Main className="container pt-12">
          {error && !data && <div>An error has occurred.</div>}
          {!data && !error && (
            <div className="flex justify-center">
              <svg viewBox="0 0 128 128" className="w-12 h-12 text-primary-light">
                <g>
                  <path
                    d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
                    fill="currentColor"
                  />
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 64 64"
                    to="360 64 64"
                    dur="1800ms"
                    repeatCount="indefinite"
                  />
                </g>
              </svg>
            </div>
          )}
        </Page.Main>
      </Page>
    );
  }

  return (
    <>
      <Page title={`Billing ${facility.name}` || 'Billing'}>
        <Page.Main className="pt-16">
          <Address
            title="billing"
            addressee={data.billing.addressee}
            attention={data.billing.attention}
            phone={data.billing.phone}
            address={{
              line1: data.billing.line1,
              line2: data.billing.line2,
              city: data.billing.city,
              zipCode: data.billing.zipCode,
              country: data.billing.country,
            }}
            email={data.contact.email}
          />
          <Address
            title="shipping"
            addressee={data.shipping.addressee}
            attention={data.shipping.attention}
            phone={data.shipping.phone}
            address={{
              line1: data.shipping.line1,
              line2: data.shipping.line2,
              city: data.shipping.city,
              zipCode: data.shipping.zipCode,
              country: data.shipping.country,
            }}
          />
          <div className="px-8 py-2 md:px-6 md:py-6 lg:px-12 xl:px-24">
            <button
              type="button"
              disabled={!['admin', 'superAdmin', 'manager'].includes(facility.userRole)}
              onClick={() => setModalOpen(true)}
              className="px-24 py-3 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
            >
              {t('billing.update')}
            </button>
          </div>
        </Page.Main>
      </Page>
      {modalOpen && (
        <ModalAddress
          open={modalOpen}
          requestClose={() => setModalOpen(false)}
          mutate={mutate}
          facilityId={facility.id}
          initialValues={{
            facilityName: data.contact.companyName,
            contactEmail: data.contact.email,
            contactPhone: data.contact.phone,
            billingAddressee: data.billing.addressee,
            billingAttention: data.billing.attention,
            billingAddresseePhone: data.billing.phone,
            billingUseFacilityAddress: data.billing.billingUseFacilityAddress,
            billingAddress: data.billing.line1,
            billingAdditionalAddress: data.billing.line2,
            billingCity: data.billing.city,
            billingPostalCode: data.billing.zipCode,
            billingCountry: data.billing.country,
            billingState: data.billing.state,
            shippingUseBillingAddress: data.shipping.shippingUseBillingInformation,
            shippingAddressee: data.shipping.addressee,
            shippingAttention: data.shipping.attention,
            shippingAddresseePhone: data.shipping.phone,
            shippingAddress: data.shipping.line1,
            shippingAdditionalAddress: data.shipping.line2,
            shippingCity: data.shipping.city,
            shippingPostalCode: data.shipping.zipCode,
            shippingCountry: data.shipping.country,
            shippingState: data.shipping.state,
          }}
        />
      )}
    </>
  );
};

Billing.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
  }).isRequired,
};

export default Billing;

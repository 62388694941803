import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { InputField } from 'src/components';
import countries from 'src/constants/countries.json';
import states from 'src/constants/states.json';
import ct from 'city-timezones';
import UserPicker from './UserPicker';

const vertical = process.env.GATSBY_VERTICAL.toLowerCase();

const NewTeamForm = ({ users, context }) => {
  const { t } = useTranslation([
    vertical,
    'dashboard',
    'countries',
    'states',
  ]);
  const { values, setFieldValue } = useFormikContext();
  const [selectableUsers] = useState([...users].sort((a, b) => a.email.localeCompare(b.email)));
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    let tzs = ct.cityMapping.filter(
      (e) => (e.iso2 === values.facilityCountry || e.iso3 === values.facilityCountry) && e.timezone
    );

    // TODO refactor
    // handle countries not supported by city-timezones (ie: Anguilla)
    if (values.facilityCountry === 'AI') {
      tzs = [{ timezone: 'America/Anguilla' }];
    }

    const uniqueTzs = [...new Set(tzs.map((i) => i.timezone))];
    setTimezones(uniqueTzs);
    setFieldValue('facilityTimezone', uniqueTzs[0], true);
  }, [values.facilityCountry]);

  return (
    <div className="max-w-md pt-6 pb-12 space-y-6">
      <InputField
        name="facilityName"
        label={[`forms.team.name`,'dashboard:forms.team.name']}
        namespaces={[vertical,'dashboard']}
        autoComplete="ada"
      />

      <InputField
        name="facilityCity"
        label="forms.address.city"
        autoComplete="city"
        namespaces={['dashboard']}
      />
      <div className="flex w-1/2">
        <InputField
          id="label-team-postal"
          name="facilityPostalCode"
          label="forms.address.postal"
          autoComplete="zip"
          namespaces={['dashboard']}
        />
      </div>

      <InputField
        type="select"
        name="facilityCountry"
        label="forms.address.country"
        namespaces={['dashboard']}
        options={countries
          .map((country) => ({
            ...country,
            label: t(`countries:${country.label}`),
          }))
          .sort((a, b) => t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`)))}
      />
      {(values.facilityCountry === 'CA' || values.facilityCountry === 'US') &&
        states[values.facilityCountry] && (
          <InputField
            type="select"
            name="facilityState"
            label="forms.address.state"
            namespaces={['dashboard']}
            options={states[values.facilityCountry].map((state) => ({
              ...state,
              label: t(`states:${values.facilityCountry}.${state.label}`),
            }))}
          />
        )}
      {values.facilityCountry && timezones.length > 1 && (
        <InputField
          type="select"
          name="facilityTimezone"
          label="forms.address.timezone"
          namespaces={['dashboard']}
          options={timezones.map((tz) => ({ label: tz, value: tz }))}
        />
      )}

      <div className="pt-6">
        <span className="text-lg font-bold">
          {t(['forms.team.select_manager', 'dashboard:forms.team.select_manager'])}
        </span>
        <p className="pt-1 pb-6 text-sm text-gray-500">
          {t(['forms.team.select_manager_tagline', 'dashboard:forms.team.select_manager_tagline'])}
        </p>
        <UserPicker 
          name="manager"
          options={selectableUsers}
          removable={false}
          context={context}
        />
      </div>
    </div>
  );
};

NewTeamForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  context: PropTypes.string.isRequired
};

export default NewTeamForm;

import React from 'react';
import PropTypes from 'prop-types';

import { Page } from 'src/components';
import Contracts from '../../../contracts';

const FacilityContracts = ({ facility }) => (
  <Page title="Dashboard">
    <Page.Main className="container pt-16 pb-12">
      <div>
        <Contracts facility={facility} />
      </div>
    </Page.Main>
  </Page>
);

FacilityContracts.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
  }).isRequired,
};

export default FacilityContracts;

const initialValues = {
  subclientName: '',
  manager: {
    newUser: false,
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    phone: '',
  },
  sme: [],
};

export default initialValues;

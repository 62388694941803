import React from 'react';
import ConfirmImage from 'src/assets/images/add_facility_confirm.jpeg';
import { useTranslation, Trans } from 'react-i18next';

const Success = ({ handleClose }) => {
  const { t } = useTranslation(['dashboard', 'common']);

  return (
    <div className="flex flex-col p-12">
      <div className="flex justify-end">
        <button type="button" onClick={handleClose}>
          <svg viewBox="0 0 20 20" className="w-5 h-5">
            <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
          </svg>
        </button>
      </div>
      <div className="flex items-center md:space-x-6">
        <div className="hidden w-full md:block">
          <img src={ConfirmImage} alt="Facility created" className="object-cover w-full" />
        </div>

        <div className="flex flex-col w-full space-y-6">
          <h4 className="font-bold">{t('forms.confirmation.title')}</h4>
          <p className="pt-2">
            <Trans
              t={t}
              i18nKey={'forms.confirmation.text'}
              components={{ br: <br />, strong: <strong /> }}
            >
              {t('forms.confirmation.text')}
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Success;

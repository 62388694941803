import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'src/components';
import { useSelector } from 'react-redux';

const Manager = ({ type, firstName, lastName, email, roles, editable, editModalOpen }) => {
  const user = useSelector((state) => state.user);
  const visible =
    user.role === 'ADMIN' ||
    (roles.length > 0
      ? ['admin', 'superadmin', 'manager'].includes(roles[0].toLowerCase())
      : false);
  const managerString = 'MANAGER';
  return (
    <div className="px-6 py-4 border rounded-lg">
      <div className="flex flex-row justify-between">
        <span className="text-lg font-bold">{type}</span>
        {visible && editable && (
          <button type="button" onClick={editModalOpen} className="text-blue-500 underline">
            Edit
          </button>
        )}
      </div>
      <div className="flex items-center pt-4 space-x-4">
        <Avatar initials={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`} role={managerString} />
        <div className="flex flex-col">
          <span className="text-base capitalize">{`${firstName} ${lastName}`}</span>
          <p className="text-sm truncate text-secondary" style={{ maxWidth: '220px' }}>
            {email}
          </p>
        </div>
      </div>
    </div>
  );
};

Manager.propTypes = {
  type: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  editable: PropTypes.bool,
  editModalOpen: PropTypes.func,
};

Manager.defaultProps = {
  editable: false,
  editModalOpen: null,
};

export default Manager;

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { dateToDateFormat } from 'src/helpers';

const Contract = ({ name, startDate, endDate, totalPrice, status }) => {
  const { t, i18n } = useTranslation('dashboard');

  const contractName = () => {
    // Check the second word which is the product
    const nameStrings = String(name).split(' ');
    switch (nameStrings[1]) {
      case 'hsp_greenshield':
        // case 'Health':
        return 'Sharecare Health Security Pass';
      // case 'FacVerif_ftg':
      default:
        return 'Sharecare VERIFIED™';
    }
  };

  const statusBadge = () => {
    switch (status) {
      case 'ONGOING':
        return (
          <div className="p-2 text-xs font-bold truncate rounded-md bg-opacity-10 text-primary-light bg-primary-light">
            {t('contracts.history.on_going')}
          </div>
        );
      case 'EXPIRED':
        return (
          <div className="p-2 text-xs font-bold truncate rounded-md bg-opacity-10 text-red-500  bg-red-500">
            {t('contracts.history.expired')}
          </div>
        );
      default:
        return '';
    }
  };

  const monthDiff = (d1, d2) => {
    let months;
    months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
    months -= new Date(d1).getMonth();
    months += new Date(d2).getMonth();
    return months <= 0 ? 0 : months;
  };

  const calendarIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0421 1.03043H8.79158V0.534783C8.79158 0.234783 8.56421 0 8.27368 0C7.98316 0 7.76842 0.234783 7.76842 0.534783V1.03043H4.23158V0.534783C4.23158 0.234783 4.00421 0 3.72632 0C3.44842 0 3.20842 0.234783 3.20842 0.534783V1.03043H1.95789C0.88421 1.03043 0 1.94348 0 3.06522V9.9913C0 11.087 0.88421 12 1.95789 12H10.0421C11.1158 12 12 11.087 12 9.97826V3.05217C12 1.93043 11.1158 1.03043 10.0421 1.03043ZM7.74316 7.46087L5.94947 9.44348C5.84842 9.54783 5.7221 9.61304 5.57053 9.61304C5.41895 9.61304 5.29263 9.54783 5.19158 9.44348L4.24421 8.4C4.05474 8.1913 4.06737 7.85217 4.26947 7.65652C4.48421 7.46087 4.8 7.47391 5.0021 7.68261L5.57053 8.3087L6.97263 6.74348C7.16211 6.53478 7.49053 6.52174 7.70526 6.71739C7.92 6.92609 7.93263 7.25217 7.74316 7.46087ZM10.9768 4.09565H1.02316V3.06522C1.02316 2.53043 1.44 2.08696 1.95789 2.08696H3.20842V2.58261C3.20842 2.88261 3.43579 3.11739 3.72632 3.11739C4.00421 3.11739 4.24421 2.88261 4.24421 2.58261V2.08696H7.78105V2.58261C7.78105 2.88261 8.00842 3.11739 8.29895 3.11739C8.58947 3.11739 8.81684 2.88261 8.81684 2.58261V2.08696H10.0421C10.56 2.08696 10.9768 2.51739 10.9768 3.06522V4.09565Z"
        fill="#5D6A74"
      />
    </svg>
  );

  return (
    <div className="flex flex-col pt-4 pl-4 pr-4 mb-4 border-2 rounded-lg lg:pl-10">
      <div className="flex flex-row items-center justify-between mb-4">
        <div className="text-xl font-bold">{contractName()}</div>
        <div>{statusBadge()}</div>
      </div>
      <div className="text-sm">
        <span className="font-bold">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          }).format(totalPrice)}
        </span>
        {t('contracts.history.for_the_period')}
      </div>
      <div className="mb-4 text-sm">
        {monthDiff(startDate, endDate)}
        {t('contracts.history.month_contract')}
      </div>
      <div className="flex flex-row items-center mb-4 text-sm text-secondary">
        <div className="mr-1">{calendarIcon()}</div>
        <div className="mr-6">
          {t('contracts.history.start_date')}
          {dateToDateFormat(startDate, i18n.language)}
        </div>
        <div className="mr-1">{calendarIcon()}</div>
        <div>
          {t('contracts.history.end_date')}
          {dateToDateFormat(endDate, i18n.language)}
        </div>
      </div>
    </div>
  );
};

Contract.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  totalPrice: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default Contract;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import CONFIG from 'src/config';
import { httpClient } from 'src/helpers';
import { Modal, Snackbar, Button } from 'src/components';
import AddEditBUForm from './AddEditBUForm';
import Modalconfirmdeletebu from './ModalConfirmDeleteBU';

import initialValues from './initial-values';
import formatValues from './format-values';

const getNewBUSchema = (existingSubclientNames) =>
  Yup.object().shape({
    subclientName: Yup.string()
      .max(250, 'forms.errors.subclient.name')
      .test('Unique', 'forms.errors.subclient.taken', (values) => {
        if (values) return !existingSubclientNames.includes(values.toLowerCase().trim());
        return true;
      })
      .required('forms.required'),
    manager: Yup.object().shape({
      firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      email: Yup.string()
        .email('forms.format')
        .required('forms.required')
        .when('newUser', {
          is: true,
          then: Yup.string()
            .email('forms.format')
            .max(250, 'forms.too_long')
            .required('forms.required')
            .test(
              'manager-email-backend-validation',
              'forms.emailUsed',
              debounce(async (email) => {
                try {
                  if (!email || email === '') return true;
                  const { data } = await httpClient.get(`/users?email=${email.toLowerCase()}`);
                  return !data.length;
                } catch (error) {
                  return false;
                }
              }, 750)
            ),
        }),
      phone: Yup.string()
        .max(250, 'forms.too_long')
        .test('phone-match-regex', 'forms.format', (phone) => {
          if (!phone || phone === '') return true;
          return phone.match(
            /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
          );
        }),
    }),
  });

const ModalAddEditBU = ({
  addEditBUType,
  open,
  requestClose,
  users,
  mutate,
  existingSubclientNames,
  selectedSubclient,
}) => {
  const { t } = useTranslation([CONFIG.VERTICAL.toLowerCase()]);

  const getModalText = (modalType) => {
    let tmpModalHeader = '';
    let tmpModalButton = '';

    switch (modalType) {
      case 'add':
        tmpModalHeader = t([
          'organizations.modal.header.new_subclient',
          'dashboard:organizations.modal.header.new_subclient',
        ]);
        tmpModalButton = t([
          'forms.subclient.create_subclient',
          'dashboard:forms.subclient.create_subclient',
        ]);
        break;
      default:
        tmpModalHeader = t([
          'organizations.modal.header.edit_subclient',
          'dashboard:organizations.modal.header.edit_subclient',
        ]);
        tmpModalButton = t([
          'forms.subclient.edit_subclient',
          'dashboard:forms.subclient.edit_subclient',
        ]);
    }
    return [tmpModalHeader, tmpModalButton];
  };

  const [modalHeader, modalButton] = getModalText(addEditBUType);

  const user = useSelector((state) => state.user);
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const esn = () => {
    if (addEditBUType === 'edit') {
      return existingSubclientNames.filter((sc) => sc !== selectedSubclient.name.toLowerCase());
    }
    return existingSubclientNames;
  };

  const newBUSchema = getNewBUSchema(esn());

  const [modalConfirmDeleteOpen, setModalConfirmDeleteOpen] = useState(false);

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    setSnackbarState({ type: 'LOADING' });
    document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });
    try {
      if (window.gtag) window.gtag('event', 'Add_bu');

      const formatedValues = formatValues(values, CONFIG.VERTICAL.toLowerCase());

      formatedValues.clientId = user.clientId;

      let res;
      if (addEditBUType === 'add') {
        res = await httpClient.post('/subclients', formatedValues);
      } else {
        // edit subclient
        if (!values.manager.newUser) {
          formatedValues.drmUserId = values.manager.id;
        }
        res = await httpClient.put(`/subclients/${selectedSubclient.id}`, formatedValues);
      }

      const { status } = res;

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();

        setTimeout(() => {
          requestClose();
        }, 1000);
      } else throw Error('Subclient creation failed.');
    } catch (err) {
      if (err.response.status === 409 && err.response.data && err.response.data.code)
        switch (err.response.data.code) {
          case 'EMAIL':
            setSnackbarState({
              type: 'ERROR',
              msg: `${err.response.data.data} is already used. Please select existing user or change email.`,
            });
            break;
          case 'NAME':
            setSnackbarState({ type: 'ERROR', msg: 'BU name already exists' });
            break;
          default:
            setSnackbarState({ type: 'ERROR' });
        }
      else setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  const getInitVals = (modalType) => {
    const initVals = { ...initialValues };
    if (modalType === 'edit') {
      const manager = users.filter((usr) => usr.email === selectedSubclient.drmUser.email).pop();
      initVals.subclientName = selectedSubclient.name;
      initVals.manager = { ...manager, newUser: false };
    }
    return initVals;
  };

  return (
    <div>
      <Modal open={open} requestClose={requestClose}>
        <Modal.Header>
          <h4 className="text-lg font-bold">{modalHeader}</h4>
          <div className="absolute right-0 pr-6">
            <button type="button" onClick={requestClose}>
              <svg viewBox="0 0 20 20" className="w-5 h-5">
                <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
              </svg>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="px-6">
          <Formik
            initialValues={getInitVals(addEditBUType)}
            validationSchema={newBUSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, isSubmitting, dirty }) => (
              <Form className="max-w-md mx-auto" autoComplete="off">
                <Snackbar
                  type={snackbarState.type}
                  message={snackbarState.msg}
                  requestClose={() => setSnackbarState({ type: 'HIDDEN' })}
                />
                <AddEditBUForm
                  users={users.map((u) => {
                    if (u.email === user.email) return { ...u, me: true };
                    return u;
                  })}
                  setModalConfirmDeleteOpen={setModalConfirmDeleteOpen}
                />
                {addEditBUType === 'edit' && (user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
                  <button
                    type="button"
                    onClick={() => setModalConfirmDeleteOpen(true)}
                    className="mb-12 text-sm text-red-500 transition border-b-2 border-red-500"
                  >
                    {t(['forms.subclient.delete', 'dashboard:forms.subclient.delete'])}
                  </button>
                )}
                <div className="flex flex-col items-center justify-center pb-6">
                  <div className="flex w-full">
                    <Button
                      type="submit"
                      size="md"
                      disabled={
                        !(isValid && dirty) || isSubmitting || snackbarState.type === 'SUCCESS'
                      }
                    >
                      {modalButton}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {modalConfirmDeleteOpen && (
        <Modalconfirmdeletebu
          open={modalConfirmDeleteOpen}
          requestClose={() => setModalConfirmDeleteOpen(false)}
          requestCloseBothModals={requestClose}
          mutate={mutate}
          selectedSubclientId={selectedSubclient.id}
        />
      )}
    </div>
  );
};

ModalAddEditBU.propTypes = {
  addEditBUType: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  existingSubclientNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  selectedSubclient: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    drmUser: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};

ModalAddEditBU.defaultProps = {
  selectedSubclient: {
    name: '',
    id: '',
    drmUser: {
      email: '',
    },
  },
};

export default ModalAddEditBU;

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  exposureText,
  exposureAgeText,
  protectionStatusBadgeClass,
  protectionStatusBadgeText,
  protectionStatusText,
  textFromEvidenceType,
} from '../helpers/formatting';

const Employee = ({ employee, setModalEvidenceProofOpen, facility }) => {
  const user = useSelector((state) => state.user);

  return (
    <div>
      <div className="hidden lg:block">
        <div className="flex items-center text-sm">
          <div className="flex items-center w-64 col-span-1 col-start-1 row-start-1 space-x-1 text-sm">
            <p className="font-semibold capitalize truncate">{`${employee.firstName} ${employee.lastName}`}</p>
            <small className="uppercase text-secondary">({employee.employeeExternalId})</small>
          </div>
          <div className="flex items-center w-56 pl-9">
            <div className="flex items-center justify-center w-20">
              <p
                className={`truncate px-2 py-1 uppercase rounded font-bold text-xs ${protectionStatusBadgeClass(
                  employee.protectionStatus
                )}`}
              >
                {protectionStatusBadgeText(employee.protectionStatus)}
              </p>
            </div>

            <div className="flex items-center justify-center w-20 text-secondary">
              {employee.evidences[0] && (
                <p>{protectionStatusText(employee.evidences[0]?.evidenceRemainingTime)}</p>
              )}
            </div>
          </div>

          <div className="flex items-center w-56 pl-12 mr-16 text-center flew-row">
            <div className="truncate">
              {employee.evidences[0]?.evidenceType
                ? textFromEvidenceType(employee.evidences[0]?.evidenceType)
                : null}
            </div>
            {employee.evidences[0]?.evidencePagePaths[0].path &&
              facility &&
              ['admin', 'manager'].includes(facility.userRole) && (
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    onClick={setModalEvidenceProofOpen}
                    className="px-2 py-2 font-bold text-blue-500 transition rounded-md focus:outline-none focus:text-blue-600 hover:text-blue-600"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.51105 12C2.57477 12 1.70091 11.6411 1.02991 10.9701C0.358908 10.3147 0 9.44083 0 8.52016C0 7.64629 0.343303 6.81925 0.96749 6.21066L6.50715 0.749025C6.9909 0.265279 7.64629 0 8.36411 0C9.11313 0 9.83095 0.280884 10.3303 0.780234C10.8296 1.27958 11.1261 1.9974 11.1261 2.74642C11.1261 3.44863 10.8609 4.10403 10.3615 4.58778L5.63329 9.25358C5.27438 9.61248 4.79064 9.79974 4.27568 9.79974C3.74512 9.79974 3.23017 9.58127 2.87126 9.22237C2.49675 8.84785 2.27828 8.3329 2.27828 7.81795C2.27828 7.31859 2.48114 6.81925 2.84005 6.47594L6.58518 2.77763L7.24057 3.44863L3.49545 7.14694C3.30819 7.31859 3.21456 7.56827 3.21456 7.81795C3.21456 8.08322 3.3238 8.36411 3.52666 8.55137C3.72952 8.75423 3.9948 8.86346 4.27568 8.86346C4.54096 8.86346 4.79064 8.76983 4.97789 8.58257L9.69051 3.91677C10.0026 3.60468 10.1743 3.19896 10.1743 2.74642C10.1743 2.24707 9.987 1.77893 9.6593 1.45124C9.3316 1.13914 8.84785 0.951886 8.3485 0.951886C7.89597 0.951886 7.45904 1.12354 7.16255 1.43563L1.62289 6.89727C1.18596 7.3342 0.936281 7.91157 0.936281 8.53576C0.936281 9.20676 1.20156 9.83095 1.68531 10.3147C2.16905 10.7984 2.82445 11.0637 3.49545 11.0637C4.13524 11.0637 4.72822 10.8296 5.16515 10.3927L9.73732 5.88296L10.3927 6.55397L10.0338 6.91287L5.82055 11.0637C5.22757 11.6723 4.40052 12 3.51105 12Z"
                        fill="#0077CC"
                      />
                    </svg>
                  </button>
                </div>
              )}
          </div>

          <div className="flex items-center w-64 gap-3 pl-11 flew-row">
            {exposureText(employee.exposureStatus)}
            <p className="text-secondary">{exposureAgeText(employee.exposureLastCheckAge)}</p>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <div className="flex flex-col py-3 pl-8 space-y-3 text-sm">
          <div className="flex flex-row space-x-1 text-sm">
            <p className="font-semibold capitalize ">{`${employee.firstName} ${employee.lastName}`}</p>
            <small className="uppercase text-secondary">({employee.employeeExternalId})</small>
          </div>
          <div className="flex items-center gap-2 flew-row">
            <p
              className={`truncate px-2 py-1 uppercase rounded font-bold text-xs ${protectionStatusBadgeClass(
                employee.protectionStatus
              )}`}
            >
              {protectionStatusBadgeText(employee.protectionStatus)}
            </p>
            {textFromEvidenceType(employee.evidences[0]?.evidenceType)}
            {employee.evidences[0]?.evidencePagePaths[0].path &&
              !!['ADMIN', 'SUPERADMIN', 'MANAGER'].includes(user.role) && (
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    onClick={setModalEvidenceProofOpen}
                    className="px-1 py-1 font-bold text-blue-500 transition rounded-md focus:outline-none focus:text-blue-600 hover:text-blue-600"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.51105 12C2.57477 12 1.70091 11.6411 1.02991 10.9701C0.358908 10.3147 0 9.44083 0 8.52016C0 7.64629 0.343303 6.81925 0.96749 6.21066L6.50715 0.749025C6.9909 0.265279 7.64629 0 8.36411 0C9.11313 0 9.83095 0.280884 10.3303 0.780234C10.8296 1.27958 11.1261 1.9974 11.1261 2.74642C11.1261 3.44863 10.8609 4.10403 10.3615 4.58778L5.63329 9.25358C5.27438 9.61248 4.79064 9.79974 4.27568 9.79974C3.74512 9.79974 3.23017 9.58127 2.87126 9.22237C2.49675 8.84785 2.27828 8.3329 2.27828 7.81795C2.27828 7.31859 2.48114 6.81925 2.84005 6.47594L6.58518 2.77763L7.24057 3.44863L3.49545 7.14694C3.30819 7.31859 3.21456 7.56827 3.21456 7.81795C3.21456 8.08322 3.3238 8.36411 3.52666 8.55137C3.72952 8.75423 3.9948 8.86346 4.27568 8.86346C4.54096 8.86346 4.79064 8.76983 4.97789 8.58257L9.69051 3.91677C10.0026 3.60468 10.1743 3.19896 10.1743 2.74642C10.1743 2.24707 9.987 1.77893 9.6593 1.45124C9.3316 1.13914 8.84785 0.951886 8.3485 0.951886C7.89597 0.951886 7.45904 1.12354 7.16255 1.43563L1.62289 6.89727C1.18596 7.3342 0.936281 7.91157 0.936281 8.53576C0.936281 9.20676 1.20156 9.83095 1.68531 10.3147C2.16905 10.7984 2.82445 11.0637 3.49545 11.0637C4.13524 11.0637 4.72822 10.8296 5.16515 10.3927L9.73732 5.88296L10.3927 6.55397L10.0338 6.91287L5.82055 11.0637C5.22757 11.6723 4.40052 12 3.51105 12Z"
                        fill="#0077CC"
                      />
                    </svg>
                  </button>
                </div>
              )}
          </div>

          <div className="flex items-center gap-3 flew-row">
            {exposureText(employee.exposureStatus)}
            <p className="text-secondary">{exposureAgeText(employee.exposureLastCheckAge)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

Employee.propTypes = {
  facility: PropTypes.shape({
    userRole: PropTypes.string.isRequired,
  }),
  employee: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    employeeExternalId: PropTypes.string.isRequired,
    protectionStatus: PropTypes.string.isRequired,
    exposureStatus: PropTypes.string.isRequired,
    exposureLastCheckAge: PropTypes.string.isRequired,
    evidences: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  setModalEvidenceProofOpen: PropTypes.bool,
};

Employee.defaultProps = {
  facility: {},
  employee: {},
  setModalEvidenceProofOpen: PropTypes.bool,
};

export default Employee;

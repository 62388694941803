import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { InputField } from 'src/components';

const PackageForm = () => {
  const { t } = useTranslation(process.env.GATSBY_VERTICAL.toLowerCase());

  return (
    <div className="space-y-8">
      <span className="text-xl font-bold">
        {t(['forms.package.title', 'dashboard:forms.package.title'])}
      </span>
      <div className="space-y-4">
        <div className="sm:w-2/3">
          <div className="flex items-center p-6 space-x-4 border-2 rounded-lg shadow-md lg:space-x-8 bg-primary-light bg-opacity-10 border-primary-light">
            <div>
              <svg viewBox="0 0 20 20" className="w-5 h-5 fill-current text-primary-light">
                <path d="M17.7962 0.5L16.6943 1.61165C13.6568 4.65569 10.4161 8.11086 7.38176 11.1972L3.18869 7.74527L1.97952 6.75061L0 9.15919L1.19941 10.1538L6.50414 14.5224L7.60604 15.4293L8.60068 14.4249C11.9611 11.0573 15.6016 7.11912 18.8981 3.81546L20 2.7038L17.7962 0.5Z" />
              </svg>
            </div>
            <div className="flex flex-col space-y-4 text-sm">
              <span className="text-xl font-bold">
                {t([
                  'forms.package.items.verified.title',
                  'dashboard:forms.package.items.verified.title',
                ])}
              </span>
              <p>
                <Trans t={t} components={{ br: <br />, strong: <strong /> }}>
                  {t([
                    'forms.package.items.verified.description',
                    'dashboard:forms.package.items.verified.description',
                  ])}
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
      <InputField
        type="checkbox"
        name="acceptTerms"
        translate={false}
        label={
          <div>
            <span>
              {t(['forms.package.pricing.confirm', 'dashboard:forms.package.pricing.confirm'])}
            </span>
            <a href="/legal" target="_blank" className="pl-2 text-blue-500">
              {t([
                'forms.package.pricing.confirmLink',
                'dashboard:forms.package.pricing.confirmLink',
              ])}
            </a>
          </div>
        }
      />
    </div>
  );
};

export default PackageForm;

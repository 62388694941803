import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Spinner } from 'src/components';
import Contract from './Contract';

const History = ({ contracts, error, isValidating }) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className="flex flex-col lg:w-1/2">
      <div className="flex items-center h-20">
        <div className="text-xl font-bold">{t('contracts.history.header')}</div>
      </div>
      {(error || isValidating) && (
        <div className="p-4 border-2 rounded-lg lg:p-6">
          {error && t('common:msgs.error')}
          {isValidating && (
            <div className="flex justify-center text-primary-light">
              <Spinner />
            </div>
          )}
        </div>
      )}

      {!isValidating && contracts.length > 0 && (
        <div>
          {contracts.map((c) => (
            <Contract
              name={c.name}
              startDate={c.start}
              endDate={c.end}
              totalPrice={c.totalPrice}
              key={c.id}
              status={c.status}
            />
          ))}
        </div>
      )}
    </div>
  );
};

History.propTypes = {
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      isFree: PropTypes.bool.isRequired,
      totalPrice: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  error: PropTypes.string,
  isValidating: PropTypes.bool.isRequired,
};

History.defaultProps = {
  contracts: [],
  error: null,
};

export default History;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

const IndustryForm = ({ industries }) => {
  const { t } = useTranslation('dashboard');
  const [{ value }, , { setValue }] = useField('facilityIndustry');

  return (
    <div className="py-12 space-y-6">
      <div>
        <span className="text-xl font-bold">{t(`forms.industry.titles.introduction`)}</span>
        <p className="pt-1 text-sm text-gray-500">{t(`forms.industry.titles.description`)}</p>
      </div>

      <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3">
        {industries.map((industry) => (
          <button
            key={industry.name}
            type="button"
            onClick={() => setValue(industry)}
            className={`border-2 text-sm rounded-lg px-4 transition ${
              value.name === industry.name
                ? 'border-primary-light bg-primary-light bg-opacity-10 text-primary-light'
                : 'border-gray-200 text-secondary hover:border-gray-300 focus:border-gray-300 hover:bg-gray-300 focus:bg-gray-300 hover:bg-opacity-25 focus:bg-opacity-25'
            }`}
            style={{ height: '76px' }}
          >
            {industry.label}
          </button>
        ))}
      </div>
    </div>
  );
};

IndustryForm.propTypes = {
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      industryId: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default IndustryForm;

import React, { useEffect } from 'react';
import { useFormikContext, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { InputField } from 'src/components';

import countries from 'src/constants/countries.json';
import states from 'src/constants/states.json';

const contactSchema = Yup.object().shape({
  contactEmail: Yup.string()
    .email('forms.format')
    .max(250, 'forms.too_long')
    .required('forms.required'),
  billingUseFacilityAddress: Yup.boolean().default(true).required(),
  billingAddressee: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  billingAttention: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  billingAddresseePhone: Yup.string()
    .matches(
      /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/,
      'forms.format'
    )
    .max(250, 'forms.too_long')
    .required('forms.required'),
  billingAddress: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  billingAdditionalAddress: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long'),
  }),
  billingCity: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  billingPostalCode: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  billingCountry: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  billingState: Yup.string().when('billingUseFacilityAddress', {
    is: false,
    then: Yup.string().when('billingCountry', {
      is: (val) => val === 'US' || val === 'CA',
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
  }),
  shippingUseBillingAddress: Yup.boolean().default(true).required(),
  shippingAddressee: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingAttention: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingAddresseePhone: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string()
      .matches(
        /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[ ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,4})?$/,
        'forms.format'
      )
      .max(250, 'forms.too_long')
      .required('forms.required'),
  }),
  shippingAddress: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingAdditionalAddress: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long'),
  }),
  shippingCity: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingPostalCode: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingCountry: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  shippingState: Yup.string().when('shippingUseBillingAddress', {
    is: false,
    then: Yup.string().when('shippingCountry', {
      is: (val) => val === 'US' || val === 'CA',
      then: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    }),
  }),
});

const ContactForm = () => {
  const { t } = useTranslation(['dashboard', 'countries', 'states']);

  const { values } = useFormikContext();
  const [{ value }] = useField('facilityName');
  const [, , { setValue }] = useField('billingAddressee');

  useEffect(() => {
    setValue(value);
  }, []);

  return (
    <div>
      <div className="pt-16 space-y-6">
        <span className="text-xl font-bold">{t(`forms.contact.titles.billing`)}</span>
        <InputField
          name="billingAttention"
          label="forms.contact.attention"
          namespaces={['dashboard']}
        />
        <InputField
          name="billingAddressee"
          label="forms.contact.addressee"
          namespaces={['dashboard']}
        />
        <InputField name="contactEmail" label="forms.contact.email" namespaces={['dashboard']} />
        <InputField
          name="billingAddresseePhone"
          label="forms.contact.addresseePhone"
          namespaces={['dashboard']}
        />
        <InputField
          type="checkbox"
          name="billingUseFacilityAddress"
          label="forms.contact.same.billing"
          namespaces={['dashboard']}
        />
        {!values.billingUseFacilityAddress && (
          <>
            <InputField
              name="billingAddress"
              label="forms.address.address"
              namespaces={['dashboard']}
            />
            <InputField
              name="billingAdditionalAddress"
              label="forms.address.additionalAddress"
              namespaces={['dashboard']}
            />
            <InputField
              name="billingCity"
              label="forms.address.city"
              autoComplete="city"
              namespaces={['dashboard']}
            />
            <InputField
              name="billingPostalCode"
              label="forms.address.postal"
              autoComplete="zip"
              namespaces={['dashboard']}
            />
            <InputField
              type="select"
              name="billingCountry"
              label="forms.address.country"
              namespaces={['dashboard']}
              options={countries
                .map((country) => ({
                  ...country,
                  label: t(`countries:${country.label}`),
                }))
                .sort((a, b) => t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`)))}
            />
            {(values.billingCountry === 'CA' || values.billingCountry === 'US') &&
              states[values.billingCountry] && (
                <InputField
                  type="select"
                  name="billingState"
                  label="forms.address.state"
                  namespaces={['dashboard']}
                  options={states[values.billingCountry].map((state) => ({
                    ...state,
                    label: t(`states:${values.billingCountry}.${state.label}`),
                  }))}
                />
              )}
          </>
        )}
      </div>
      <div className="pt-16 space-y-6">
        <span className="text-xl font-bold">{t(`forms.contact.titles.shipping`)}</span>
        <InputField
          type="checkbox"
          name="shippingUseBillingAddress"
          label="forms.contact.same.shipping"
          namespaces={['dashboard']}
        />
        {!values.shippingUseBillingAddress && (
          <>
            <InputField
              name="shippingAttention"
              label="forms.contact.attention"
              namespaces={['dashboard']}
            />
            <InputField
              name="shippingAddressee"
              label="forms.contact.addressee"
              namespaces={['dashboard']}
            />
            <InputField
              name="shippingAddresseePhone"
              label="forms.contact.addresseePhone"
              namespaces={['dashboard']}
            />
            <InputField
              name="shippingAddress"
              label="forms.address.address"
              namespaces={['dashboard']}
            />
            <InputField
              name="shippingAdditionalAddress"
              label="forms.address.additionalAddress"
              namespaces={['dashboard']}
            />
            <InputField
              name="shippingCity"
              label="forms.address.city"
              autoComplete="city"
              namespaces={['dashboard']}
            />
            <InputField
              name="shippingPostalCode"
              label="forms.address.postal"
              autoComplete="zip"
              namespaces={['dashboard']}
            />
            <InputField
              type="select"
              name="shippingCountry"
              label="forms.address.country"
              namespaces={['dashboard']}
              options={countries
                .map((country) => ({
                  ...country,
                  label: t(`countries:${country.label}`),
                }))
                .sort((a, b) => t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`)))}
            />
            {(values.shippingCountry === 'CA' || values.shippingCountry === 'US') &&
              states[values.shippingCountry] && (
                <InputField
                  type="select"
                  name="shippingState"
                  label="forms.address.state"
                  namespaces={['dashboard']}
                  options={states[values.shippingCountry].map((state) => ({
                    ...state,
                    label: t(`states:${values.shippingCountry}.${state.label}`),
                  }))}
                />
              )}
          </>
        )}
      </div>
    </div>
  );
};

export { contactSchema };

export default ContactForm;

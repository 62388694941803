import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ct from 'city-timezones';

import { InputField } from 'src/components';

import CONFIG from 'src/config';
import countries from 'src/constants/countries.json';
import states from 'src/constants/states.json';

const FacilityAddress = ({ header, schema }) => {
  const {
    facilityName,
    facilityWebsiteUrl,
    facilityEmployees,
    facilityRooms,
    facilityAddress,
    facilityAdditionalAddress,
    facilityCity,
    facilityPostalCode,
    facilityCountry,
    facilityState,
    facilityTimezone,
  } = schema.fields;

  const { t } = useTranslation([CONFIG.VERTICAL.toLowerCase(), 'dashboard', 'countries', 'states']);
  const { values, setFieldValue } = useFormikContext();

  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    let tzs = ct.cityMapping.filter(
      (e) => (e.iso2 === values.facilityCountry || e.iso3 === values.facilityCountry) && e.timezone
    );

    // handle countries not supported by city-timezones (ie: Anguilla)
    if (values.facilityCountry === 'AI') {
      tzs = [{ timezone: 'America/Anguilla' }];
    }

    const uniqueTzs = [...new Set(tzs.map((i) => i.timezone))];
    setTimezones(uniqueTzs);
    setFieldValue('facilityTimezone', uniqueTzs[0], true);
  }, [values.facilityCountry]);

  return (
    <div className="space-y-6 sm:w-1/3 md:w-7/12">
      <span className="text-xl font-bold">
        {header || t('dashboard:forms.address.titles.information')}
      </span>
      {facilityName ? (
        <div className="">
          <InputField
            type="text"
            name="facilityName"
            label="forms.facility.name"
            namespaces={CONFIG.VERTICAL === 'DEKRA' ? 'dekra' : ['dashboard']}
          />
        </div>
      ) : null}
      {facilityWebsiteUrl && (
        <div className="">
          <InputField
            name="facilityWebsiteUrl"
            label="forms.address.website"
            namespaces={['dashboard']}
          />
        </div>
      )}
      {facilityEmployees && (
        <div className="md:w-1/2">
          <InputField
            type="number"
            name="facilityEmployees"
            label={t(['forms.facility.employeeCount', 'dashboard:forms.facility.employeeCount'])}
            namespaces={['dashboard']}
          />
        </div>
      )}
      {facilityRooms && (
        <div className="md:w-1/2">
          <InputField
            type="number"
            name="facilityRooms"
            label={t(['forms.facility.roomCount', 'dashboard:forms.facility.roomCount'])}
            namespaces={['dashboard']}
          />
        </div>
      )}
      {facilityAddress && (
        <div className="">
          <InputField
            name="facilityAddress"
            label="forms.address.address"
            namespaces={['dashboard']}
          />
        </div>
      )}
      {facilityAdditionalAddress && (
        <div className="">
          <InputField
            name="facilityAdditionalAddress"
            label="forms.address.additionalAddress"
            namespaces={['dashboard']}
          />
        </div>
      )}
      {facilityCity && (
        <div className="">
          <InputField
            name="facilityCity"
            label="forms.address.city"
            autoComplete="city"
            namespaces={['dashboard']}
          />
        </div>
      )}
      {facilityPostalCode && (
        <div className="md:w-1/2">
          <InputField
            id="label-facility-postal"
            name="facilityPostalCode"
            label="forms.address.postal"
            autoComplete="zip"
            namespaces={['dashboard']}
          />
        </div>
      )}
      {facilityCountry && (
        <div className="">
          <InputField
            type="select"
            name="facilityCountry"
            label="forms.address.country"
            namespaces={['dashboard']}
            options={countries
              .map((country) => ({
                ...country,
                label: t(`countries:${country.label}`),
              }))
              .sort((a, b) => t(`countries:${a.label}`).localeCompare(t(`countries:${b.label}`)))}
          />
        </div>
      )}
      {facilityState &&
        (values.facilityCountry === 'CA' || values.facilityCountry === 'US') &&
        states[values.facilityCountry] && (
          <div className="">
            <InputField
              type="select"
              name="facilityState"
              label="forms.address.state"
              namespaces={['dashboard']}
              options={states[values.facilityCountry].map((state) => ({
                ...state,
                label: t(`states:${values.facilityCountry}.${state.label}`),
              }))}
            />
          </div>
        )}
      {facilityTimezone && values.facilityCountry && timezones.length > 1 && (
        <div className="">
          <InputField
            type="select"
            name="facilityTimezone"
            label="forms.address.timezone"
            namespaces={['dashboard']}
            options={timezones.map((tz) => ({ label: tz, value: tz }))}
          />
        </div>
      )}
    </div>
  );
};

FacilityAddress.propTypes = {
  header: PropTypes.string,
  schema: PropTypes.shape({
    fields: PropTypes.shape({
      facilityName: PropTypes.objectOf(PropTypes.any),
      facilityWebsiteUrl: PropTypes.objectOf(PropTypes.any),
      facilityEmployees: PropTypes.objectOf(PropTypes.any),
      facilityRooms: PropTypes.objectOf(PropTypes.any),
      facilityAddress: PropTypes.objectOf(PropTypes.any),
      facilityAdditionalAddress: PropTypes.objectOf(PropTypes.any),
      facilityCity: PropTypes.objectOf(PropTypes.any),
      facilityPostalCode: PropTypes.objectOf(PropTypes.any),
      facilityCountry: PropTypes.objectOf(PropTypes.any),
      facilityState: PropTypes.objectOf(PropTypes.any),
      facilityTimezone: PropTypes.objectOf(PropTypes.any),
    }),
  }).isRequired,
};

FacilityAddress.defaultProps = {
  header: '',
};

export default FacilityAddress;

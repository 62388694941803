import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CONFIG from 'src/config';

import './report.scss';
import { Page } from 'src/components';

const { VERTICAL } = CONFIG;

const Report = ({ facility }) => {
  const configLinks = useSelector((state) => state.configLink)
  const { certificationLink, auditLink } = configLinks

  return (
    <Page title={`Detailed report ${facility.name}` || 'Detailed report'}>
      <Page.Main className="pt-16">
        {
          (!!certificationLink || !!auditLink) &&
          <iframe
            id="iframe"
            title="Bot"
            src={
              ['VULCAIN', 'GIFEN', 'DEKRA'].includes(VERTICAL)
                ? `${auditLink}?t=${encodeURIComponent(facility.dashboardId)}&framed=1`
                : `${certificationLink}/${facility.externalId}?framed=1`
            }
            className="w-full framed"
          />
        }
      </Page.Main>
    </Page>
  )
};

Report.propTypes = {
  facility: PropTypes.shape({
    name: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
    dashboardId: PropTypes.string
  }).isRequired,
};

export default Report;

import * as Yup from 'yup';

import debounce from 'lodash.debounce';
import { httpClient } from 'src/helpers';

export const facilityAddr1Schema = Yup.object().shape({
  facilityName: Yup.string()
    .max(250, 'forms.errors.facility.name')
    .required('forms.required')
    .test(
      'facility-name-backend-validation',
      'forms.errors.facility.taken',
      debounce(async (name) => {
        try {
          if (!name || name === '') return true;
          const { data } = await httpClient.get(`/facilities/${encodeURIComponent(name)}/exists`);
          return !data.exists;
        } catch (error) {
          return false;
        }
      }, 750)
    ),
  facilityWebsiteUrl: Yup.string()
    .url('forms.url-format')
    .required('forms.required')
    .max(250, 'forms.too_long'),
  facilityEmployees: Yup.number()
    .min(1, 'forms.min.employees')
    .max(3000000, 'forms.min.employees')
    .required('forms.required'),
});

export const facilityAddr1V1RoomSchema = Yup.object().shape({
  facilityName: Yup.string()
    .max(250, 'forms.errors.facility.name')
    .required('forms.required')
    .test(
      'facility-name-backend-validation',
      'forms.errors.facility.taken',
      debounce(async (name) => {
        try {
          if (!name || name === '') return true;
          const { data } = await httpClient.get(`/facilities/${encodeURIComponent(name)}/exists`);
          return !data.exists;
        } catch (error) {
          return false;
        }
      }, 750)
    ),
  facilityWebsiteUrl: Yup.string()
    .url('forms.url-format')
    .required('forms.required')
    .max(250, 'forms.too_long'),
  facilityRooms: Yup.number()
    .min(1, 'forms.min.employees')
    .max(3000000, 'forms.min.employees')
    .required('forms.required'),
});

export const facilityAddr1V2Schema = Yup.object().shape({
  facilityName: Yup.string()
    .max(250, 'forms.errors.facility.name')
    .required('forms.required')
    .test(
      'facility-name-backend-validation',
      'forms.errors.facility.taken',
      debounce(async (name) => {
        try {
          if (!name || name === '') return true;
          const { data } = await httpClient.get(`/facilities/${encodeURIComponent(name)}/exists`);
          return !data.exists;
        } catch (error) {
          return false;
        }
      }, 750)
    ),
  facilityEmployees: Yup.number()
    .min(1, 'forms.min.employees')
    .max(3000000, 'forms.min.employees')
    .required('forms.required'),
});

export const facilityAddr2Schema = Yup.object().shape({
  facilityAddress: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityAdditionalAddress: Yup.string().max(250, 'forms.too_long'),
  facilityCity: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityPostalCode: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityCountry: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityState: Yup.string().when('facilityCountry', {
    is: (val) => val === 'US' || val === 'CA',
    then: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  }),
  facilityTimezone: Yup.string().required('forms.required').max(250, 'forms.too_long'),
});

export const managerSchema = Yup.object().shape({
  manager: Yup.object().shape({
    firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    title: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    email: Yup.string()
      .email('forms.format')
      .required('forms.required')
      .when('newUser', {
        is: true,
        then: Yup.string()
          .email('forms.format')
          .max(250, 'forms.too_long')
          .required('forms.required')
          .test(
            'manager-email-backend-validation',
            'forms.emailUsed',
            debounce(async (email) => {
              try {
                if (!email || email === '') return true;
                const { data } = await httpClient.get(`/users?email=${email.toLowerCase()}`);
                return !data.length;
              } catch (error) {
                return false;
              }
            }, 750)
          ),
      }),
    phone: Yup.string()
      .max(250, 'forms.too_long')
      .test('phone-match-regex', 'forms.format', (phone) => {
        if (!phone || phone === '') return true;
        return phone.match(
          /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
        );
      })
      .nullable(),
  }),
});

export const packageSchema = Yup.object().shape({
  acceptTerms: Yup.boolean().required('forms.required').isTrue(),
});

export const industrySchema = Yup.object().shape({
  facilityIndustry: Yup.mixed().default(''),
});

import React from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import PropTypes from 'prop-types';

import CONFIG from 'src/config';
import { fetchOnce } from 'src/helpers';
import { History, Information } from './components';

const Contracts = ({ facility }) => {
  const user = useSelector((state) => state.user);

  const contractsUrl = `/contracts?${
    CONFIG.VERTICAL === 'GREENSHIELD' ? `clientId=${user.clientId}` : `facilityId=${facility.id}`
  }`;
  const { data: contracts, error: contractError, isValidating } = useSWR(contractsUrl, fetchOnce);

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-20">
      <History contracts={contracts?.contracts} error={contractError} isValidating={isValidating} />
      {['FTG', 'GREENSHIELD'].includes(CONFIG.VERTICAL) && <Information facility={facility} />}
    </div>
  );
};

Contracts.defaultProps = {
  facility: {
    id: '',
  },
};

Contracts.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default Contracts;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CONFIG from 'src/config';
import Informations from './Informations';
import Manager from './Manager';
import ModalManager from './ModalManager';

const verticalLower = CONFIG.VERTICAL.toLowerCase();

const PropertyInformations = ({ facility, mutate }) => {
  const { t } = useTranslation('facility');
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <>
      <div className="px-8 py-6 md:px-6 lg:pt-16 lg:px-12 xl:px-24">
        <Informations facility={facility} mutate={mutate} />
        {['FTG', 'HOSPITALITY', 'GREENSHIELD', 'DEKRA'].includes(CONFIG.VERTICAL) && (
          <div className="pt-12 lg:pt-16">
            <div className="flex items-center justify-between lg:justify-start lg:space-x-8">
              <h5 className="text-3xl font-bold">
                {t([
                  `${verticalLower}:facility.informations.titles.users`,
                  'informations.titles.users',
                ])}
              </h5>
              {!['GREENSHIELD', 'DEKRA'].includes(CONFIG.VERTICAL) && (
                <button
                  type="button"
                  onClick={() => setOpenEdit(true)}
                  className="px-6 py-2 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
                >
                  {t('informations.buttons.edit')}
                </button>
              )}
            </div>
            <div className="grid max-w-screen-xl grid-cols-1 gap-6 pt-6 xl:grid-cols-2 2xl:grid-cols-3">
              <Manager
                type={['GREENSHIELD', 'DEKRA'].includes(CONFIG.VERTICAL) ? 'Manager' : 'DRM'}
                firstName={facility.drmUser.firstName}
                lastName={facility.drmUser.lastName}
                email={facility.drmUser.email}
                roles={[facility.userRole]}
              />
              {CONFIG.VERTICAL === 'FTG' && (
                <>
                  {facility.gmUser && (
                    <Manager
                      type="General Manager"
                      firstName={facility.gmUser.firstName}
                      lastName={facility.gmUser.lastName}
                      email={facility.gmUser.email}
                      roles={facility.gmUser.roles || ['MANAGER']}
                    />
                  )}
                  {facility.prUser && (
                    <Manager
                      type="PR Lead"
                      firstName={facility.prUser.firstName}
                      lastName={facility.prUser.lastName}
                      email={facility.prUser.email}
                      roles={facility.prUser.roles || ['MANAGER']}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {openEdit && <ModalManager open={openEdit} requestClose={() => setOpenEdit(false)} />}
    </>
  );
};

PropertyInformations.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string,
      zipCode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      state: PropTypes.string,
    }).isRequired,
    rooms: PropTypes.number.isRequired,
    employees: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    drmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
    gmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    prUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default PropertyInformations;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { UsersLoader, ModalArchivedUser, ModalResendUser } from 'src/views/app/shared';
import { Page, Searcher, Pagination } from 'src/components';
import Ship from 'src/assets/images/ship.svg';
import { fetchOnce } from 'src/helpers';
import { User, ModalNewUser, ModalEditUser } from './components';

const Users = ({ ship }) => {
  const user = useSelector((state) => state.user);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const [modalNewUserOpen, setModalNewUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [archivedUser, setArchivedUser] = useState(null);
  const [resendUser, setResendUser] = useState(null);

  const { data, error, mutate } = useSWR(
    ship.id
      ? [
          `/subclients/${ship.id}/users?withTopics=true&page=${page}&${
            search !== '' ? `search=${encodeURIComponent(search)}` : ''
          }`,
        ]
      : null
  );
  const { data: dataTopics, error: errorTopics } = useSWR('/facilities/mt/topics', fetchOnce);

  if (error || errorTopics) {
    return (
      <Page title={`${ship.name} Users` || 'Facility Users'}>
        <Page.Main className="pt-16">
          <div>An error has occurred.</div>
        </Page.Main>
      </Page>
    );
  }

  return (
    <>
      <Page title="Users">
        <Page.Main className="container pt-16 pb-24">
          <div>
            <h5 className="pt-20 text-3xl font-bold">All users</h5>
            <div className="flex flex-col pt-6 space-y-4 lg:flex-row lg:space-y-0 lg:space-x-6">
              <div className="flex items-center">
                <button
                  type="button"
                  disabled={
                    errorTopics ||
                    !dataTopics ||
                    !['admin', 'superAdmin', 'manager'].includes(ship.userRole)
                  }
                  onClick={() => setModalNewUserOpen(true)}
                  className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none 2xl:whitespace-nowrap bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 whitespace-nowrap"
                >
                  Add SME
                </button>
              </div>

              <div className="flex items-center justify-between w-full space-x-4 lg:w-auto">
                <Searcher
                  search={search}
                  setSearch={setSearch}
                  setPage={setPage}
                  placeholder="Search a user..."
                />
                {search !== '' && data && (
                  <span className="text-sm text-secondary whitespace-nowrap">
                    {`${
                      data.users.length > 1
                        ? `${data.users.length} results`
                        : `${data.users.length} result`
                    }`}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="pt-12">
            {(!data || !dataTopics) && <UsersLoader withTopics={false} />}
            {data && data.users && data.users.length <= 0 && (
              <div className="flex items-center justify-center">
                <div>
                  <img src={Ship} alt="Ship" />
                  <span className="block text-sm font-bold text-center">
                    {search ? 'No user found' : 'No User yet'}
                  </span>
                </div>
              </div>
            )}
            {data && data.users && data.users.length > 0 && (
              <>
                <div className="hidden px-4 text-xs font-bold uppercase md:grid md:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-9 gap-x-2 text-secondary">
                  <span className="col-span-2">Name</span>
                  <span className="hidden text-center 2xl:block">Title</span>
                  <span className="hidden col-span-3 text-center opacity-0 xl:col-span-4 md:block">
                    Sections
                  </span>
                  <span className="hidden text-center xl:block">Status</span>
                </div>
                <div className="grid mt-6 border divide-y rounded-md">
                  {data.users
                    .sort((a, b) => a.email.localeCompare(b.email))
                    .map((u) => (
                      <User
                        key={u.email}
                        user={u}
                        topics={dataTopics}
                        openEdit={() => setEditUser({ ...u, id: u.id.toString() })}
                        openDelete={() => setArchivedUser({ ...u, id: u.id.toString() })}
                        openResend={() => setResendUser({ ...u, id: u.id.toString() })}
                        canEdit={!!['ADMIN', 'SUPERADMIN', 'MANAGER'].includes(user.role)}
                        canResend={!!u.externalChatbotManagerId}
                        settings={['admin', 'superAdmin', 'manager'].includes(ship.userRole)}
                      />
                    ))}
                </div>
                {data.pages > 1 && (
                  <Pagination
                    page={page}
                    setPage={setPage}
                    pages={data.pages}
                    result={data.result}
                  />
                )}
              </>
            )}
          </div>
        </Page.Main>
      </Page>
      {modalNewUserOpen && (
        <ModalNewUser
          open={modalNewUserOpen}
          requestClose={() => setModalNewUserOpen(false)}
          mutate={mutate}
          shipId={ship.id}
          shipUsers={data.users}
          topics={dataTopics}
        />
      )}
      {editUser && (
        <ModalEditUser
          open={editUser !== null}
          requestClose={() => setEditUser(null)}
          user={editUser}
          mutate={mutate}
          shipId={ship.id}
          topics={dataTopics}
        />
      )}
      {archivedUser && (
        <ModalArchivedUser
          open={archivedUser !== null}
          requestClose={() => setArchivedUser(null)}
          user={archivedUser}
          mutate={mutate}
          facilityId={ship.id}
          description="User will loose access to all journeys."
        />
      )}
      {resendUser && (
        <ModalResendUser
          open={resendUser !== null}
          requestClose={() => setResendUser(null)}
          user={resendUser}
          facilityId={ship.id}
          mutate={mutate}
        />
      )}
    </>
  );
};

Users.propTypes = {
  ship: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
  }).isRequired,
};

export default Users;

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import CONFIG from 'src/config';
import { InputField, Avatar } from 'src/components';
import { useField } from 'formik';

import initialValues from './initial-values';

const filterUsers = (option, input) => {
  if (!input || input === '') return true;
  return (
    option.data.firstName.toLowerCase().includes(input.toLowerCase()) ||
    option.data.lastName.toLowerCase().includes(input.toLowerCase()) ||
    option.data.email.toLocaleLowerCase().includes(input.toLocaleLowerCase())
  );
};

const formatOptionLabel = ({ firstName, lastName, email, me, roles }) => (
  <div className="flex items-center space-x-4">
    <Avatar initials={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`} role={roles[0]} />
    <div className="flex flex-col">
      <span className="text-base capitalize">
        {`${firstName} ${lastName} ${me ? '(You)' : ''}`}
      </span>
      <p className="text-sm truncate text-secondary" style={{ maxWidth: '220px' }}>
        {email}
      </p>
    </div>
  </div>
);

const { manager: initialUserValues } = { ...initialValues };

const UserPicker = ({ name, options }) => {
  const { t } = useTranslation(CONFIG.VERTICAL.toLowerCase());

  const [{ value: selectedUser }, , metaActions] = useField(name);
  const { setValue, setTouched } = metaActions;

  const selectedUserClass = (val) =>
    val ? 'text-blue-500 border-blue-500' : 'text-secondary border-transparent';

  return (
    <div className="relative p-6 space-y-6 rounded-md bg-secondary-grey-light">
      <div className="flex items-center space-x-4">
        <button
          type="button"
          onClick={() => {
            setValue({ ...initialUserValues, newUser: false });
            setTouched(false);
          }}
          className={`transition border-b-2 text-sm hover:text-blue-600 focus:text-blue-600 focus:outline-none ${selectedUserClass(
            !selectedUser.newUser
          )}`}
        >
          {t(['users.select_user', 'dashboard:users.select_user'])}
        </button>
        <button
          type="button"
          onClick={() => {
            setValue({ ...initialUserValues, newUser: true });
            setTouched(false);
          }}
          className={`transition border-b-2 text-sm hover:text-blue-600 focus:text-blue-600 focus:outline-none ${selectedUserClass(
            selectedUser.newUser
          )}`}
        >
          {t(['users.create_user', 'dashboard:users.create_user'])}
        </button>
      </div>
      <div>
        {!selectedUser.newUser ? (
          <div>
            <Select
              classNamePrefix="r-select"
              options={options
                .map((m) => ({ ...m, value: m.id }))
                .filter((item) => item.id !== selectedUser.id)}
              filterOption={filterUsers}
              formatOptionLabel={formatOptionLabel}
              placeholder=""
              closeMenuOnSelect
              onChange={(value) => setValue({ ...value, newUser: selectedUser.newUser })}
              value={selectedUser?.id ? selectedUser : ''}
            />
          </div>
        ) : (
          <div className="space-y-6">
            <div className="grid gap-6 md:grid-cols-2">
              <InputField
                name={`${name}.firstName`}
                label="forms.manager.firstName"
                namespaces={['dashboard']}
              />
              <InputField
                name={`${name}.lastName`}
                label="forms.manager.lastName"
                namespaces={['dashboard']}
              />
            </div>
            <InputField
              name={`${name}.email`}
              label="forms.manager.email"
              namespaces={['dashboard']}
            />
            <div className="flex w-1/2">
              <InputField
                name={`${name}.phone`}
                label="forms.manager.phone_optional"
                namespaces={['dashboard']}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

UserPicker.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default UserPicker;
